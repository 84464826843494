import { Box, Stack } from '@chakra-ui/react'
import React from 'react'
import { Icon } from '@chakra-ui/icons'
import { LuInbox } from 'react-icons/lu'

interface EntityEmptyProps {
  title?: string
  description?: string
}

export const EntityEmpty: React.FC<EntityEmptyProps> = ({ title, description }) => {
  return (
    <Stack spacing="10px" alignItems="center">
      <Icon as={LuInbox} boxSize="24px"/>
      <Stack lineHeight="1.2" spacing="8px" alignItems="center" textAlign="center" maxW="180px">
        {title && (
          <Box fontWeight={500}>{title}</Box>
        )}
        {description && (
          <Box fontSize="0.84rem" color="gray.400">{description}</Box>
        )}
      </Stack>
    </Stack>
  )
}
