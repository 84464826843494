import { Modal } from '../../uikit/Modal'
import React, { useEffect, useMemo, useState } from 'react'
import { Select } from '../../uikit/Select'
import { Button, Stack } from '@chakra-ui/react'
import {
  EOrderStatus,
  useManagersQuery,
  useOrderSetManagerMutation,
  useSetStatusMutation,
} from 'api/graphql'
import { useRefetchOrder } from '../../state/useOrder'
import { orderStatusLabel } from '../../constants/orderStatus'
import { TextArea } from '../../uikit/TextArea'

interface ChoiceManagerModalProps {
  isOpen: boolean
  orderId: string
  status: EOrderStatus | undefined
  comment: string
  onClose: () => void
}

export const SetStatusModal: React.FC<ChoiceManagerModalProps> = ({
  orderId,
  status,
  comment,
  isOpen,
  onClose,
}) => {
  const { refetchOrder, isRefetchLoading } = useRefetchOrder()

  const [setStatusMutation, { loading: isStatusLoading }] =
    useSetStatusMutation()
  const [valueSelect, setValueSelect] = useState<EOrderStatus | undefined>(
    status ?? undefined
  )
  const [commentValue, setCommentValue] = useState(comment ?? '')

  const isLoading = isRefetchLoading || isStatusLoading

  const options = useMemo(() => {
    return Object.entries(orderStatusLabel).map(([key, label]) => ({
      value: key as EOrderStatus,
      label: label,
    }))
  }, [orderStatusLabel])

  const valueOption = options?.find((option) => option.value === valueSelect)

  const handleClick = async () => {
    if (!valueSelect || !orderId) return
    await setStatusMutation({
      variables: {
        id: orderId,
        status: valueSelect,
        comment: commentValue,
      },
    })
    await refetchOrder()
    onClose()
  }

  useEffect(() => {
    setValueSelect(status)
  }, [status])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isHideClose={isLoading}
      header="Смена статуса"
      containerProps={{
        maxW: '450px',
      }}
    >
      <Stack spacing="18px">
        <Select
          isDisabled={isLoading}
          options={options}
          placeholder="Статус"
          onChange={(e) => setValueSelect(e?.value)}
          value={valueOption}
        />
        <TextArea
          isDisabled={isLoading}
          name="comment"
          placeholder="Общий комментарий (по желанию)"
          value={commentValue}
          onChange={(e) => setCommentValue(e.target.value)}
        />
        <Button
          isDisabled={!valueSelect}
          width="fit-content"
          isLoading={isLoading}
          onClick={handleClick}
          variant="primary"
        >
          Сменить
        </Button>
      </Stack>
    </Modal>
  )
}
