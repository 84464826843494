import { Box, Flex, HStack } from '@chakra-ui/react'
import React, { ReactNode } from 'react'

interface BadgeProps {
  colorScheme: string
  children: ReactNode
}

export const Badge: React.FC<BadgeProps> = ({ colorScheme = 'blue', children  }) => {
  return (
    <Flex alignItems="center">
      <HStack
        fontSize="0.8rem"
        textTransform="initial"
        borderRadius="6px"
        lineHeight="1.2"
        padding="4px 8px"
        borderWidth={1}
        style={{ textWrap: 'nowrap' }}
      >
        <Box backgroundColor={`${colorScheme}.500`} borderRadius="full" boxSize="8px"/>
        <Box>
          {children}
        </Box>
      </HStack>
    </Flex>
  )
}
