import { Box, Button, HStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import {
  useUserAcceptDealMutation,
  useUserSetNeedDealCorrectionMutation,
} from 'api/graphql'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { TextArea } from 'uikit/TextArea'
import { isEmptyString } from 'utils/isEmptyString'
import { StepWrapper } from '../../../elements/StepWrapper'
import moment from 'moment'

export const DealCoordinationStep = () => {
  const { orderId, orderData } = useOrderData()
  const [userSetNeedDealCorrectionMutation, { loading: isNeedDealLoading }] =
    useUserSetNeedDealCorrectionMutation()
  const [userAcceptDealMutation, { loading: isAcceptDealLoading }] =
    useUserAcceptDealMutation()
  const { refetchOrder } = useRefetchOrder()

  const [value, setValue] = useState('')
  const [isNeedCorrection, setIsNeedCorrection] = useState(false)

  const handleAccept = async () => {
    if (!orderId) return
    await userAcceptDealMutation({
      variables: {
        id: orderId,
      },
    })
    await refetchOrder()
  }

  const handleNeedCorrection = async () => {
    if (!value || !orderId) return
    await userSetNeedDealCorrectionMutation({
      variables: {
        id: orderId,
        comment: value,
      },
    })
    await refetchOrder()
  }

  return (
    <StepWrapper
      description={
        <Box>
          <Box>Все документы получены.</Box>
          <Box>
            Сделка запланирована на{' '}
            <Box as="span" px="3px" py="1px" fontWeight={600} backgroundColor="orange.100" borderRadius="4px">
              {moment(orderData?.dealDate).format('DD.MM.YYYY HH:mm')}
            </Box>{' '}
            по МСК.
          </Box>
          <Box>В запланированное время вам будет отправлен счет на оплату</Box>
        </Box>
      }
    >
      {!isNeedCorrection ? (
        <HStack spacing="16px">
          <Button
            isLoading={isAcceptDealLoading}
            onClick={handleAccept}
            variant="primary"
          >
            Согласовать
          </Button>
          <Button
            isDisabled={isAcceptDealLoading}
            onClick={() => setIsNeedCorrection(true)}
            variant="secondary"
          >
            Требуется другая дата
          </Button>
        </HStack>
      ) : (
        <>
          <TextArea
            autoFocus
            name="comment"
            value={value}
            placeholder="Желаемое время сделки"
            minHeight="100px"
            onChange={(e) => setValue(e.target.value)}
          />
          <HStack spacing="16px">
            <Button
              isDisabled={isEmptyString(value)}
              isLoading={isNeedDealLoading}
              onClick={handleNeedCorrection}
              variant="attention"
            >
              Отправить
            </Button>
            <Button
              isDisabled={isNeedDealLoading}
              onClick={() => setIsNeedCorrection(false)}
              variant="secondary"
            >
              Отменить
            </Button>
          </HStack>
        </>
      )}
    </StepWrapper>
  )
}
