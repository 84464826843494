import React, { ReactNode } from 'react'
import { Flex, FlexProps } from '@chakra-ui/react'

interface SectionProps extends FlexProps {
  children: ReactNode
}

export const Section: React.FC<SectionProps> = ({ children, ...props }) => {
  return (
    <Flex flexDirection="column" py="50px" {...props}>
      {children}
    </Flex>
  )
}
