import { Entity } from 'components/Entity/Entity'
import React, { useMemo } from 'react'
import { FormikValues } from 'formik'
import { Center, Spinner, useToast } from '@chakra-ui/react'
import {
  useCreateUserMutation,
  useSaveUserMutation,
  useUserQuery,
} from 'api/graphql'
import { UserForm } from 'shared/forms/UserForm'
import { useCurrentUser } from 'state/useCurrentUser'
import { RowInfoGroup } from 'components/RowInfo/RowInfoGroup'
import { RowInfo } from 'components/RowInfo/RowInfo'
import { userRoleLabel } from 'constants/userRole'

interface CreateOrderEntityProps {
  id?: string
  onBack: () => void
}

export const UserEntity: React.FC<CreateOrderEntityProps> = ({ id, onBack }) => {
  const toast = useToast()
  const { isAdmin } = useCurrentUser()
  const isNew = !id

  const { data: userData, loading: isFetchLoading  } = useUserQuery({
    skip: !id,
    fetchPolicy: 'network-only',
    variables: {
      id: id ?? '',
    },
  })
  const [createUserMutation, { loading: isCreateLoading }] = useCreateUserMutation()
  const [saveUserMutation, { loading: isSaveLoading }] = useSaveUserMutation()

  const isLoading = isFetchLoading

  const data = userData?.user

  const initialValues = useMemo(() => ({
    role: data?.role ?? '',
    name: data?.name ?? '',
    phone: data?.phone ?? '',
    email: data?.email ?? '',
    telegramUsername: data?.telegramUsername ?? '',
  }), [data])

  const handleForm = async (values: FormikValues) => {
    const variables = {
      role: values?.role,
      name: values?.name,
      phone: `${values?.phone?.replace(/\D/g, '')}`,
      email: values?.email,
      telegramUsername: values?.telegramUsername,
      password: values?.password,
    }
    if (isNew) {
      await createUserMutation({
        variables,
        onCompleted: (data) => {
          toast({
            title: `Пользователь успешно создан`,
            status: 'success',
            position: 'top-right',
            isClosable: true,
          })
          onBack()
        },
        onError: () => {
          toast({
            title: `Ошибка создания пользователя`,
            status: 'error',
            position: 'top-right',
            isClosable: true,
          })
        }
      })
      return
    }
    await saveUserMutation({
      variables: {
        id,
        ...variables
      },
      onCompleted: (data) => {
        toast({
          title: `Пользователь успешно обновлен`,
          status: 'success',
          position: 'top-right',
          isClosable: true,
        })
        onBack()
      },
      onError: () => {
        toast({
          title: `Ошибка обновления пользователя`,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        })
      }
    })
  }

  if (isLoading) {
    return (
      <Center height="40px" flex={1}>
        <Spinner boxSize="18px"/>
      </Center>
    )
  }

  return (
    <Entity
      header={isNew ? 'Новый пользователь' : 'Пользователь'}
      onBack={onBack}
    >
      {isAdmin ? (
        <UserForm
          initialValues={initialValues}
          isLoading={isCreateLoading || isSaveLoading}
          onSubmit={handleForm}
        />
      ) : (
        <RowInfoGroup>
          <RowInfo label="Контактное лицо" value={data?.name}/>
          <RowInfo label="Телефон" value={data?.phone}/>
          <RowInfo label="Электронная почта" value={data?.phone}/>
          {data?.role && (
            <RowInfo label="Роль" value={userRoleLabel[data.role]}/>
          )}
        </RowInfoGroup>
      )}
    </Entity>
  )
}
