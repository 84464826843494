import { Box, Flex, HStack, RadioProps, Text, useRadio, useRadioGroup } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import _ from 'lodash'
import { FormikValues } from 'formik'
import { FormLabel } from '../FormLabel'

interface ItemProps {
  size?: 'lg' | 'md'
  propsRadio: RadioProps
  children: string
}

const Item: React.FC<ItemProps> = ({ children, size = 'md', propsRadio }) => {
  const { getInputProps, getRadioProps } = useRadio(propsRadio)

  const input = getInputProps()
  const checkbox = getRadioProps()

  const fontSize = {
    lg: '1rem',
    md: '0.94rem'
  }

  const padding = {
    lg: '8px 18px',
    md: '4px 12px'
  }

  return (
    <Box as="label">
      <input {...input} />
      <HStack
        {...checkbox}
        cursor="pointer"
        borderRadius="full"
        transition="0.3s"
        spacing="5px"
        bg="gray.100"
        padding={padding[size]}
        backgroundColor="gray.200"
        _checked={{
          backgroundColor: 'blue.100',
        }}
        _focus={{
          boxShadow: 'none',
        }}
        _hover={{
          opacity: 0.9,
        }}
      >
        <Text fontSize={fontSize[size]} fontWeight={500}>
          {children}
        </Text>
      </HStack>
    </Box>
  )
}

export type TOptions = {
  label: string
  value: string
}

interface ButtonRadioProps {
  onChange: (e: any) => void
  value: any
  label?: string
  size?: 'lg' | 'md'
  options: TOptions[]
}

export const RadioButton: React.FC<ButtonRadioProps> = (
  { onChange, value, size, options, label }
) => {

  const { getRootProps, getRadioProps, setValue } = useRadioGroup({
    name: 'ButtonRadio',
    defaultValue: value,
    onChange,
  })

  useEffect(() => {
    setValue(value)
  }, [value])

  const group = getRootProps()

  return (
    <Box>
      {label && <FormLabel>{label}</FormLabel>}
      <HStack spacing="12px" flexWrap="wrap" maxW="400px" {...group}>
        {options.map((item) => {
          const radio = getRadioProps({ value: item.value })
          return (
            <Item key={item.value} size={size} propsRadio={radio}>
              {item.label}
            </Item>
          )
        })}
      </HStack>
    </Box>
  )
}

export const ButtonRadioForm: React.FC<FormikValues> = ({ field, form, ...props }) => {
  const handleChange = (e: string) => {
    form.setFieldValue(field.name, e)
  }

  return (
    <RadioButton
      {...field}
      isInvalid={_.get(form.errors, field.name) && _.get(form.touched, field.name)}
      value={field.value}
      onChange={handleChange}
      {...props}
    />
  )
}
