import { Box, Button, Divider, HStack, Stack } from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import {
  EDocumentType,
  useUserSelectSigningMethodMutation,
  useUserSetNeedContractCorrectionMutation,
} from 'api/graphql'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { getStringOrNull, isEmptyString } from 'utils/isEmptyString'
import { FileItem } from '../../../elements/FileItem'
import { TextArea } from 'uikit/TextArea'
import { InputGroupForm } from 'uikit/InputGroup'
import { Field, Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import { RadioButton } from 'uikit/RadioButton'
import { StepWrapper } from '../../../elements/StepWrapper'
import { Switch } from 'uikit/Switch'

enum EContractType {
  None = 'none',
  Edi = 'edi',
  Doc = 'doc',
}

export const ContactCoordinationStep = () => {
  const { orderId, orderData, documentsData } = useOrderData()
  const [
    userSetNeedContractCorrectionMutation,
    { loading: isNeedCorrectionLoading },
  ] = useUserSetNeedContractCorrectionMutation()
  const [userSelectSigningMethodMutation, { loading: isSelectSigningMethodLoading }] =
    useUserSelectSigningMethodMutation()
  const { refetchOrder } = useRefetchOrder()

  const [needPayerApproving, setNeedPayerApproving] = useState(false)

  const [isNeedCorrection, setNeedCorrection] = useState(false)
  const [correctionValue, setCorrectionValue] = useState('')
  const [contractTypeValue, setContractTypeValue] = useState<EContractType>(
    EContractType.None
  )

  const documents = useMemo(
    () =>
      documentsData?.filter(
        (document) => document.documentType === EDocumentType.Contract
      ),
    [documentsData]
  )

  const handleNeedCorrection = async () => {
    if (!orderId) return
    await userSetNeedContractCorrectionMutation({
      variables: {
        id: orderId,
        comment: correctionValue,
      },
    })
    await refetchOrder()
  }

  const handleEdiForm = async (values: FormikValues) => {
    if (!orderId) return
    await userSelectSigningMethodMutation({
      variables: {
        id: orderId,
        edi: true,
        ediName: values?.ediName,
        ediId: values?.ediId,
        needPayerApproving
      },
    })
    await refetchOrder()
  }

  const handleDocContact = async () => {
    if (!orderId) return
    await userSelectSigningMethodMutation({
      variables: {
        id: orderId,
        edi: false,
        needPayerApproving
      },
    })
    await refetchOrder()
  }

  return (
    <StepWrapper
      description={
        getStringOrNull(orderData?.comment) ?? 'Мы направили вам договор, ознакомьтесь с ним'
      }
    >
      <Stack spacing="10px">
        {documents?.map((document) => (
          <FileItem
            key={document.id}
            id={document.id}
            name={document?.fileName}
            orderId={orderId}
            cursor="pointer"
            backgroundColor="white"
            _hover={{
              backgroundColor: 'gray.200',
            }}
            _active={{
              backgroundColor: 'gray.300',
            }}
          />
        ))}
      </Stack>
      {isNeedCorrection ? (
        <>
          <Stack spacing="18px">
            <TextArea
              name="comment"
              value={correctionValue}
              label="Причина корректировки"
              placeholder="Напишите что не подошло"
              minHeight="100px"
              onChange={(e) => setCorrectionValue(e.target.value)}
            />
            <HStack spacing="16px">
              <Button
                isDisabled={isEmptyString(correctionValue)}
                isLoading={isNeedCorrectionLoading}
                onClick={handleNeedCorrection}
                variant="attention"
              >
                Отправить
              </Button>
              <Button
                isDisabled={isNeedCorrectionLoading}
                onClick={() => setNeedCorrection(false)}
                variant="secondary"
              >
                Отменить
              </Button>
            </HStack>
          </Stack>
        </>
      ) : (
        <Button
          width="fit-content"
          isDisabled={isSelectSigningMethodLoading}
          onClick={() => setNeedCorrection(true)}
          size="sm"
          variant="linkBlue"
        >
          Необходимо внести изменения
        </Button>
      )}
      {!isNeedCorrection && (
        <>
          <Divider borderColor="gray.300" />
          <Switch
            name="payerApproving"
            label="Требуется предварительное согласование плательщика с поставщиком"
            isChecked={needPayerApproving}
            onChange={(e) => setNeedPayerApproving(e.target.checked)}
          />
          <Divider borderColor="gray.300" />
          <Stack spacing="24px">
            <Stack spacing="18px">
              <Box>Выберите способ подписания</Box>
              <RadioButton
                size="lg"
                value={contractTypeValue}
                onChange={(e: EContractType) => setContractTypeValue(e)}
                options={[
                  { value: EContractType.Edi, label: 'ЭДО' },
                  { value: EContractType.Doc, label: 'Бумажный носитель' },
                ]}
              />
            </Stack>
            {contractTypeValue === EContractType.Edi && (
              <Stack spacing="18px">
                <Box>
                  Заполните данные и мы отправим документы на подписание
                </Box>
                <Formik
                  validationSchema={formSchema}
                  initialValues={{
                    ediName: '',
                    ediId: '',
                  }}
                  onSubmit={handleEdiForm}
                >
                  {(props) => (
                    <form onSubmit={props.handleSubmit}>
                      <Stack spacing="24px">
                        <Stack spacing="14px">
                          <Field
                            name="ediName"
                            label="Система ЭДО"
                            component={InputGroupForm}
                            isDisabled={isSelectSigningMethodLoading}
                          />
                          <Field
                            name="ediId"
                            label="Идентификатор ЭДО"
                            component={InputGroupForm}
                            isDisabled={isSelectSigningMethodLoading}
                          />
                        </Stack>
                        <Box>
                          <Button
                            isLoading={isSelectSigningMethodLoading}
                            type="submit"
                            variant="primary"
                          >
                            Отправить
                          </Button>
                        </Box>
                      </Stack>
                    </form>
                  )}
                </Formik>
              </Stack>
            )}
            {contractTypeValue === EContractType.Doc && (
              <Stack spacing="18px">
                <Box lineHeight="1.2">
                  {needPayerApproving ? (
                    'После согласования поставщика вы сможете загрузить подписанный документ'
                  ) : (
                    'На следующем шаге вы сможете загрузить подписанный документ'
                  )}
                </Box>
                <Button
                  width="fit-content"
                  isLoading={isSelectSigningMethodLoading}
                  onClick={handleDocContact}
                  variant="primary"
                >
                  Продолжить
                </Button>
              </Stack>
            )}
          </Stack>
        </>
      )}
    </StepWrapper>
  )
}

const formSchema = Yup.object().shape({
  ediName: Yup.string().required(''),
  ediId: Yup.string().required(''),
})
