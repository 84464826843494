import { Box } from '@chakra-ui/react'
import { Icon } from '@chakra-ui/icons'
import { LuUser2 } from 'react-icons/lu'
import React from 'react'
import { ItemUserFragment } from 'api/graphql'
import { EntityListRow } from 'components/Entity/EntityListRow'
import { Badge } from 'uikit/Badge'
import { userRoleColor, userRoleLabel } from 'constants/userRole'

interface UserRowProps {
  data: ItemUserFragment
}

export const UserRow: React.FC<UserRowProps> = ({ data }) => {
  return (
    <EntityListRow
      to={`${data.id}`}
      templateColumns="20px minmax(180px, 1fr) minmax(200px, auto) minmax(220px, auto)"
    >
      <Icon as={LuUser2} width="16px" />
      <Box fontSize="0.9rem">{data.name}</Box>
      <Badge colorScheme={userRoleColor[data.role]}>
        {userRoleLabel[data.role]}
      </Badge>
      <Box fontSize="0.9rem">{`+${data.phone}`}</Box>
    </EntityListRow>
  )
}
