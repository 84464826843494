import React from 'react'
import { useOrderData } from 'state/useOrder'
import { StepWrapper } from '../../../elements/StepWrapper'

export const PaymentToSupplierFailedStep = () => {
  const { orderData } = useOrderData()
  return (
    <StepWrapper
      description="Заявка требует корректировки"
      userComment={orderData?.comment}
    />
  )
}
