import React from 'react'
import { CompanyEntity } from 'shared/entities/CompanyEntity'
import { useNavigate, useParams } from 'react-router-dom'

export const Item = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const handleChange = (id: string) => {
    navigate(`/companies`)
  }

  return (
    <CompanyEntity id={id} onChange={handleChange} onBack={() => navigate('/companies')} />
  )
}
