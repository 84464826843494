import { Heading, HeadingProps } from '@chakra-ui/react'
import React from 'react'

interface HeadingPromoProps extends HeadingProps {
  children: string
}

export const HeadingPromo: React.FC<HeadingPromoProps> = ({
  children,
  ...props
}) => {
  return (
    <Heading
      lineHeight="1.1"
      size="xl"
      bgGradient="linear(to-l, #000000, #9769FF)"
      bgClip="text"
      width="fit-content"
      {...props}
    >
      {children}
    </Heading>
  )
}
