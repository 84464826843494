import React from 'react'
import { useMyCompaniesQuery } from 'api/graphql'
import { useNavigate } from 'react-router-dom'
import { CompanyEntity } from 'shared/entities/CompanyEntity'
import { isEmpty } from 'lodash'
import { CreateOrderEntity } from 'shared/entities/CreateOrderEntity'
import { Center, Spinner } from '@chakra-ui/react'

export const Create = () => {
  const navigate = useNavigate()
  const {
    data: myCompaniesData,
    loading: isLoading,
    called: isCalled,
    refetch,
  } = useMyCompaniesQuery()

  const handleCreateCompany = async () => {
    refetch()
  }

  const handleBack = () => {
    navigate(-1)
  }

  const handleChange = (orderId: string) => {
    navigate(`/orders/${orderId}`)
  }

  if (!isCalled || isLoading) {
    return (
      <Center flex={1}>
        <Spinner boxSize="18px" />
      </Center>
    )
  }

  return (
    <>
      {isEmpty(myCompaniesData?.myCompanies) ? (
        <CompanyEntity onChange={handleCreateCompany} onBack={handleBack} />
      ) : (
        <CreateOrderEntity onBack={handleBack} onChange={handleChange} />
      )}
    </>
  )
}
