import { Box, Flex, HStack, Stack } from '@chakra-ui/react'
import React, { forwardRef } from 'react'
import { ItemLabel } from '../elements/ItemLabel'
import { ItemIcon } from '../elements/ItemIcon'
import { truncate } from 'lodash'
import { LuUserCircle2 } from 'react-icons/lu'
import { useCurrentUser } from 'state/useCurrentUser'

export const Profile = forwardRef<HTMLDivElement>(({}, ref) => {
  const { currentUser, isControl, isAdmin, isManager } = useCurrentUser()

  return (
    <Flex ref={ref} role="group" borderRadius="8px" padding="10px 12px 4px">
      <HStack spacing="14px" flex={1} alignItems="flex-start">
        <ItemIcon icon={LuUserCircle2} pt="6px" />
        <Stack spacing="8px">
          <ItemLabel
            labelProps={{
              fontWeight: 600,
            }}
          >
            {truncate(currentUser?.name, { length: 32 })}
          </ItemLabel>
          {isControl ? (
            <Flex fontSize="0.75rem" opacity={0.5}>
              {isAdmin ? 'Администратор' : 'Менеджер'}
            </Flex>
          ) : (
            <Stack lineHeight="1.2" spacing="2px">
              <Flex fontSize="0.75rem" opacity={0.5}>
                {`+${truncate(currentUser?.phone, { length: 26 })}`}
              </Flex>
              <Flex fontSize="0.75rem" opacity={0.5}>
                {`${truncate(currentUser?.email, { length: 26 })}`}
              </Flex>
            </Stack>
          )}
        </Stack>
      </HStack>
    </Flex>
  )
})
