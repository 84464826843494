import { Button } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useSaveOrderMutation, useUserPublishOrderMutation } from 'api/graphql'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { TextArea } from 'uikit/TextArea'
import { StepWrapper } from '../../../elements/StepWrapper'

export const PublishStep = () => {
  const { orderId, orderData } = useOrderData()
  const [value, setValue] = useState('')
  const { refetchOrder } = useRefetchOrder()

  const [saveOrderMutation, { loading: isSaveLoading }] = useSaveOrderMutation()
  const [userPublishOrderMutation, { loading: isPublishLoading }] = useUserPublishOrderMutation()

  const isLoading = isSaveLoading || isPublishLoading

  const handlePublish = async () => {
    await saveOrderMutation({
      variables: {
        id: orderData?.id,
        comment: value
      },
    })
    await userPublishOrderMutation({
      variables: {
        id: orderId,
      },
    })
    await refetchOrder()
  }

  useEffect(() => {
    setValue(orderData?.comment || '')
  }, [orderData])

  return (
    <StepWrapper
      description="Вы создали заявку, но ее не опубликовали, пока ей никто не занимается. Если все готово, опубликуйте заявку, чтобы она стала доступной для исполнения."
    >
      <TextArea
        name="comment"
        value={value}
        label="Комментарий к заявке"
        placeholder="Дополните заявку комментарием, это по желанию"
        minHeight="100px"
        onChange={(e) => setValue(e.target.value)}
      />
      <Button
        width="fit-content"
        isLoading={isLoading}
        variant="primary"
        onClick={handlePublish}
      >
        Опубликовать заявку
      </Button>
    </StepWrapper>
  )
}
