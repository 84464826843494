import { useOrderData, useRefetchOrder } from 'state/useOrder'
import {
  Alert,
  Box,
  Center,
  Grid,
  HStack, IconButton,
  Stack, Textarea,
} from '@chakra-ui/react'
import {
  EDocumentType,
  EMessageType,
  EOrderStatus,
  EUserRole,
  OrderMessageFragment, useAddOrderTextMessageMutation,
} from 'api/graphql'
import React, { useState } from 'react'
import moment from 'moment'
import {
  orderStatusColor,
  orderStatusLabel,
} from 'constants/orderStatus'
import { documentLabel } from 'constants/documents'
import download from 'downloadjs'
import { Icon } from '@chakra-ui/icons'
import { LuDownload, LuFileText, LuSendHorizonal } from 'react-icons/lu'
import { truncate } from 'lodash'
import ResizeTextarea from 'react-textarea-autosize'
import { isEmptyString } from '../../../../utils/isEmptyString'

export const History = () => {
  const { messagesData } = useOrderData()

  return (
    <Stack spacing="28px">
      <Message/>
      <Stack spacing="18px">
        {messagesData?.map((message) => <Item key={message.id} data={message} />)}
      </Stack>
    </Stack>
  )
}

interface ItemProps {
  data: OrderMessageFragment
}

const Item: React.FC<ItemProps> = ({ data }) => {
  const { orderId } = useOrderData()
  const isStatusChanged = data.messageType === EMessageType.StatusChanged
  const isFileAttached = data.messageType === EMessageType.FileAttached
  const isTextMessage = data.messageType === EMessageType.TextMessage
  const fileTypeName = documentLabel[data?.documentType as EDocumentType] ?? 'Другое'

  return (
    <Stack padding="18px 20px" backgroundColor="gray.100" borderRadius="16px">
      <Stack spacing="5px">
        <HStack fontSize="0.88rem">
          <Box
            fontWeight={600}
            color={data.userRole === EUserRole.User ? 'blue.500' : 'purple.500'}
          >
            {data.userName}
          </Box>
          <Box opacity={0.4} fontSize="0.75rem">
            {moment(data.createdAt).format('DD.MM.YYYY HH:mm')}
          </Box>
        </HStack>
        <Box fontSize="0.94rem">
          {isStatusChanged && (
            <Stack spacing="10px">
              <Box>
                <Box as="span">Статус заявки изменен на:</Box>
                <Box
                  ml="8px"
                  mr="6px"
                  mb="1px"
                  display="inline-block"
                  backgroundColor={`${orderStatusColor[data.orderStatus ?? EOrderStatus.Created]}.500`}
                  borderRadius="full"
                  boxSize="8px"
                />
                <Box as="span" fontWeight={500}>
                  {orderStatusLabel[data.orderStatus ?? EOrderStatus.Created]}
                </Box>
              </Box>
              {data.comment && (
                <Alert
                  backgroundColor="white"
                  borderRadius="5px"
                  status="warning"
                  variant="left-accent"
                  padding="6px 8px"
                  fontSize="0.84rem"
                  borderInlineStartWidth="3px"
                  whiteSpace="pre-line"
                >
                  {data?.comment}
                </Alert>
              )}
            </Stack>
          )}
          {isFileAttached && (
            <Stack spacing="10px">
              <Box>
                <Box as="span">Загружен документ:</Box>
                <Box as="span" fontWeight={500} ml="4px">
                  {fileTypeName}
                </Box>
              </Box>
              <FileItem
                id={data?.fileAttachmentId}
                name={data?.fileName ?? fileTypeName}
                orderId={orderId}
              />
            </Stack>
          )}
          {isTextMessage && (
            <Box>{data?.message}</Box>
          )}
        </Box>
      </Stack>
    </Stack>
  )
}

interface FileContainerProps {
  id?: string
  orderId?: string | null | undefined
  name: string | null | undefined
  onRemove?: () => void
  onCheckboxClick?: (id: string) => void
}

export const FileItem: React.FC<FileContainerProps> = ({
  id,
  orderId,
  name,
  onRemove,
  onCheckboxClick,
}) => {
  const handleClick = () => {
    if (!id || !orderId) return
    download(`/api/files/order/${orderId}/documents/${id}`)
  }

  const handleCheckboxClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    if (!id || !onCheckboxClick) return
    onCheckboxClick(id)
  }

  return (
    <Grid
      role="group"
      onClick={handleClick}
      templateColumns={onRemove ? '17px 1fr 24px' : '20px 1fr'}
      borderRadius="8px"
      gap="4px"
      alignItems="center"
      transition="0.2s"
      cursor="pointer"
      padding="6px 8px"
      backgroundColor="white"
      _hover={{
        backgroundColor: 'gray.200',
      }}
      _active={{
        backgroundColor: 'gray.300',
      }}
    >
      <Center position="relative" boxSize="18px" onClick={handleCheckboxClick}>
        <Icon
          as={LuFileText}
          boxSize="13px"
          transition="0.2s"
          lineHeight="1.2"
        />
      </Center>
      <Box
        overflow="hidden"
        fontSize="0.84rem"
        color={name ? 'black' : 'gray.400'}
        css={{ textWrap: 'nowrap' }}
      >
        {name ? truncate(name, { length: 60 }) : 'Нет имени'}
      </Box>
    </Grid>
  )
}

const Message = () => {
  const { orderId } = useOrderData()
  const { refetchOrder } = useRefetchOrder()
  const [value, setValue] = useState('')
  const [addOrderTextMessageMutation] = useAddOrderTextMessageMutation()
  const [isLoading, setIsLoading] = useState(false)

  const isDisabled = isEmptyString(value)

  const handleAdd = async () => {
    try {
      if (isDisabled) return
      setIsLoading(true)
      await addOrderTextMessageMutation({
        variables: {
          orderId,
          message: value
        }
      })
      await refetchOrder()
      setValue('')
    } catch (e) {} finally {
      setIsLoading(false)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.ctrlKey && !e.metaKey && !e.altKey && !e.shiftKey) {
      e.preventDefault();
      handleAdd();
    } else if ((e.ctrlKey || e.metaKey || e.altKey) && e.key === 'Enter') {
      setValue((prevValue) => prevValue + '\n');
    }
  };

  return (
    <Stack
      spacing="14px"
      borderRadius="14px"
      padding="20px"
      borderWidth={1}
      borderColor="gray.200"
      borderStyle="dashed"
      position="relative"
    >
      <Textarea
        isDisabled={isLoading}
        borderWidth={0}
        variant="unstyled"
        minH="unset"
        overflow="hidden"
        w="100%"
        resize="none"
        minRows={1}
        as={ResizeTextarea}
        placeholder="Напишите сообщение..."
        padding={0}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <IconButton
        position="absolute"
        isRound
        isDisabled={isDisabled}
        isLoading={isLoading}
        aria-label="Send"
        size="sm"
        variant="ghost"
        right="10px"
        bottom="10px"
        icon={<Icon as={LuSendHorizonal}/>}
        onClick={handleAdd}
        _hover={{
          backgroundColor: 'purple.500',
          color: 'white'
        }}
        _active={{
          backgroundColor: 'purple.600',
          color: 'white'
        }}
      />
    </Stack>
  )
}
