import { Button, HStack, Stack } from '@chakra-ui/react'
import { useImperativeFilePicker } from 'use-file-picker'
import { DocumentType, useUpload } from 'api/api'
import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { FileItem } from '../../elements/FileItem'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { FileContent } from 'use-file-picker/types'

interface UploadButtonProps {
  documentType: DocumentType
  selectLabelButton?: string
  uploadLabelButton?: string
  isUploadDisabled?: boolean
  onFinish?: () => Promise<void> | void
  onSelected?: (files: FileContent<ArrayBuffer>[]) => void
}

export const UploadButton: React.FC<UploadButtonProps> = ({
  selectLabelButton = 'Выбрать файлы',
  uploadLabelButton = 'Загрузить',
  isUploadDisabled,
  documentType,
  onFinish,
  onSelected,
}) => {
  const { orderId } = useOrderData()
  const { refetchOrder } = useRefetchOrder()
  const { upload, uploadProgress } = useUpload()
  const [isLoading, setIsLoading] = useState(false)

  const { openFilePicker, filesContent, plainFiles, removeFileByIndex, clear } =
    useImperativeFilePicker({
      readAs: 'ArrayBuffer',
      maxFileSize: 100,
      multiple: true,
    })

  const isEmptyFiles = isEmpty(filesContent)

  const handleUpload = async () => {
    try {
      if (!orderId) return
      setIsLoading(true)
      if (filesContent.length > 0) {
        for await (const file of plainFiles) {
          await upload({
            file,
            orderId,
            documentType,
          })
        }
        await refetchOrder()
        if (onFinish) {
          await onFinish()
        }
        clear()
      }
    } catch (e) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    onSelected && onSelected(filesContent)
  }, [filesContent])

  return (
    <Stack spacing="20px">
      {!isEmptyFiles && (
        <Stack spacing="10px">
          {filesContent.map((file, index) => (
            <FileItem
              key={file.name}
              name={file.name}
              onRemove={() => removeFileByIndex(index)}
              backgroundColor="white"
            />
          ))}
        </Stack>
      )}
      <HStack spacing="14px">
        {!isEmptyFiles && (
          <Button
            isLoading={isLoading}
            isDisabled={isUploadDisabled}
            variant="primary"
            onClick={handleUpload}
            loadingText={
              uploadProgress > 0 ? `Загрузка: ${uploadProgress}%` : 'Загрузка'
            }
          >
            {uploadLabelButton}
          </Button>
        )}
        {!isLoading && (
          <Button variant="secondary" onClick={openFilePicker}>
            {isEmptyFiles ? selectLabelButton : 'Добавить еще'}
          </Button>
        )}
      </HStack>
    </Stack>
  )
}
