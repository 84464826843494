import { Route, Routes } from 'react-router-dom'
import { Orders } from './routes/Orders'
import { Users } from './routes/Users'
import { MainLayout } from '../../../../shared/layouts/MainLayout'

export const Control = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout/>}>
        <Route path="orders/*" element={<Orders/>} />
        <Route path="users/*" element={<Users/>} />
      </Route>
    </Routes>
  )
}
