import React from 'react'
import { useOrderData } from 'state/useOrder'
import { getStringOrNull } from 'utils/isEmptyString'
import { StepWrapper } from '../../../elements/StepWrapper'

export const PaymentToSupplerStep = () => {
  const { orderData } = useOrderData()
  return (
    <StepWrapper
      description={
        getStringOrNull(orderData?.comment) ??
        'Выполняем зачисление средств поставщику.\n' +
        'Срок выполнения заявки до 5 рабочих дней с даты получения денежных средств на счет. По готовности придет уведомление на почту.'
      }
    />
  )
}
