import { useCallback, useEffect } from 'react'
import { UsersQueryVariables, useUsersQuery } from '../graphql'

interface UsUsersProps {
  variables?: UsersQueryVariables
}

export const useUsers = ({ variables }: UsUsersProps = {}) => {
  const { data: usersData, loading, refetch, fetchMore  } = useUsersQuery({
    fetchPolicy: 'network-only',
    variables: {
      ...variables
    }
  })

  const { pageInfo } = usersData?.users || {}
  const hasNextPage = pageInfo?.hasNextPage || false

  const next = useCallback(() => {
    fetchMore({
      variables: {
        cursor: pageInfo?.endCursor,
        ...variables
      },
    })
  }, [pageInfo])

  const data = usersData?.users?.edges?.map((item) => item.node)

  useEffect(() => {
    refetch({
      ...variables
    })
  }, [variables])

  return {
    data,
    isLoading: loading,
    hasNextPage,
    next,
    refetch
  }
}

