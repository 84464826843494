import {
  InputGroup,
  FormControl,
  Box,
  InputLeftAddon,
  ResponsiveValue,
  Input,
} from '@chakra-ui/react'
import MaskedInput, { MaskedInputProps } from 'react-text-mask'
import { FormLabel } from '../FormLabel'
import React, { LegacyRef, ReactNode } from 'react'
import { FieldProps } from 'formik'
import _ from 'lodash'

interface InputMaskProps extends Omit<MaskedInputProps, 'size' | 'mask'> {
  name: string
  isHideError?: boolean
  isInvalid?: boolean
  leftAddon?: ReactNode
  error?: string | null
  value?: string
  label?: string
  isDisabled?: boolean
  mask?: Array<string | RegExp> | false
  placeholder?: string
  size?: ResponsiveValue<string | 'sm' | 'md' | 'lg' | 'xs'> | undefined
}

export const InputMask = React.forwardRef<HTMLInputElement, InputMaskProps>(
  function InputMask(
    {
      name,
      isHideError,
      isInvalid,
      leftAddon,
      mask,
      error,
      value,
      label,
      placeholder,
      isDisabled,
      size,
      ...props
    },
    ref
  ) {
    return (
      <FormControl isInvalid={isInvalid}>
        {label && <FormLabel name={name}>{label}</FormLabel>}
        <InputGroup size={size}>
          {leftAddon && <InputLeftAddon>{leftAddon}</InputLeftAddon>}
          <MaskedInput
            ref={ref as LegacyRef<MaskedInput> | undefined}
            mask={
              mask ?? ['+', '7', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/,]
            }
            id={name}
            name={name}
            value={value}
            render={(ref, propsMask) => (
              <Input
                name={name}
                ref={ref as React.Ref<HTMLInputElement>}
                isDisabled={isDisabled}
                placeholder={placeholder}
                errorBorderColor="red.400"
                backgroundColor="white"
                _focusVisible={{
                  zIndex: 1,
                  borderColor: 'gray.400',
                  boxShadow: '0 0 0 1px var(--chakra-colors-gray-400)',
                }}
                {...(leftAddon && {
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                })}
                {...(isInvalid && {
                  _focusVisible: {
                    zIndex: 1,
                    borderWidth: 1,
                    borderColor: 'red.300',
                    boxShadow: '0 0 0 1px var(--chakra-colors-red-300)',
                  },
                })}
                {...propsMask}
              />
            )}
            {...props}
          />
        </InputGroup>
        {error && isInvalid && !isHideError && (
          <Box fontSize="0.7rem" color="red.500" mt="4px">
            {error}
          </Box>
        )}
      </FormControl>
    )
  }
)

const hasValue = (value: any) => {
  return typeof value !== 'undefined'
}

export interface InputMaskFormProps extends Omit<InputMaskProps, 'form'>, FieldProps {}

export const InputMaskForm = React.forwardRef<HTMLInputElement, InputMaskFormProps>(
  function InputGroupForm({ field, form, ...props }, ref) {
    const error = _.get(form.errors, field.name)
    const isError = hasValue(_.get(form.errors, field.name))
    const isTouched = hasValue(_.get(form.touched, field.name))

    const isInvalid = isError && isTouched
    return (
      <InputMask
        ref={ref}
        isInvalid={isInvalid}
        error={typeof error === 'string' ? error : 'неизвестный формат ошибки'}
        {...field}
        {...props}
      />
    )
  }
)
