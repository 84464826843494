import { Box, Button, Stack } from '@chakra-ui/react'
import { Field, Formik, FormikValues } from 'formik'
import { SelectForm } from 'uikit/Select'
import { TextAreaForm } from 'uikit/TextArea'
import { SwitchForm } from 'uikit/Switch'
import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { useMyCompaniesQuery } from 'api/graphql'
import { FormikHelpers } from 'formik/dist/types'

interface OrderFormProps {
  initialValues: FormikValues
  isLoading: boolean
  isDirty?: boolean
  isHideComment?: boolean
  onSubmit: (values: FormikValues, { resetForm }: FormikHelpers<any>) => Promise<void>
}

export const OrderForm: React.FC<OrderFormProps> = ({ isLoading, isHideComment, isDirty, initialValues, onSubmit }) => {
  const { data: myCompaniesData, loading, error, refetch } = useMyCompaniesQuery()

  const companyOptions = useMemo(() => {
    return myCompaniesData?.myCompanies?.map((company) => ({
      label: company.name,
      value: company.id
    }))
  }, [myCompaniesData])

  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={formSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <Stack spacing="28px">
              <Stack spacing="18px">
                <Field
                  name="companyId"
                  label="Компания"
                  placeholder="Выберите компанию"
                  component={SelectForm}
                  isDisabled={isLoading}
                  options={companyOptions}
                />
                <Field
                  name="tnVed"
                  label="Список ТЕН ВЭД (или наименования товара)"
                  placeholder="Каждое наименование с новой строки"
                  component={TextAreaForm}
                  isDisabled={isLoading}
                  minHeight="300px"
                />
                <Stack spacing="6px">
                  <Box fontSize="0.9rem">
                    Выберите дополнительные услуги, требующиеся в рамках заявки:
                  </Box>
                  <Stack borderRadius="var(--chakra-radii-md)" borderWidth={1} padding="14px var(--chakra-space-4)">
                    <Field
                      name="logistics"
                      label="Логистика"
                      component={SwitchForm}
                      isDisabled={isLoading}
                    />
                    <Field
                      name="insurance"
                      label="Страхование груза"
                      component={SwitchForm}
                      isDisabled={isLoading}
                    />
                  </Stack>
                </Stack>
                {!isHideComment && (
                  <Field
                    name="comment"
                    label="Комментарий"
                    component={TextAreaForm}
                    isDisabled={isLoading}
                    minHeight="100px"
                  />
                )}
              </Stack>
              <Box>
                {(isDirty ? props.dirty : true) && (
                  <Button isLoading={isLoading} type="submit" variant="primary">
                    Сохранить
                  </Button>
                )}
              </Box>
            </Stack>
          </form>
        )}
      </Formik>
    </>
  )
}

const formSchema = Yup.object().shape({
  companyId: Yup.string().required('Выберите компанию'),
  tnVed: Yup.string().required('Заполните поле'),
})
