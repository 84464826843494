import { EUserRole } from 'api/graphql'

export const userRoleLabel = {
  [EUserRole.Admin]: 'Администратор',
  [EUserRole.Manager]: 'Менеджер',
  [EUserRole.User]: 'Пользователь',
}

export const userRoleColor = {
  [EUserRole.Admin]: 'green',
  [EUserRole.Manager]: 'purple',
  [EUserRole.User]: 'blue',
}
