import { useLocation } from 'react-router-dom'
import React, { useLayoutEffect, ReactNode } from 'react'

interface ScrollToTopProps {
  children: ReactNode;
}

export const ScrollToTop: React.FC<ScrollToTopProps> = ({ children }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return <>{children}</>;
}
