export enum EResponseStatus {
  AccessDenied = 'AccessDenied',
  EditForbidden = 'EditForbidden',
  EmailInUse = 'EmailInUse',
  IncorrectPassword = 'IncorrectPassword',
  IncorrectRequestData = 'IncorrectRequestData',
  IncorrectStatus = 'IncorrectStatus',
  ItemNotFound = 'ItemNotFound',
  PhoneInUse = 'PhoneInUse',
  RegistrationCodeExpired = 'RegistrationCodeExpired',
  RegistrationCodeInvalid = 'RegistrationCodeInvalid',
  Success = 'Success',
  SystemError = 'SystemError',
  Unauthorized = 'Unauthorized'
}

type ResponseStatusMessageType = {
  [key in EResponseStatus]: string
}

export const responseStatusMessage: ResponseStatusMessageType = {
  [EResponseStatus.AccessDenied]: 'Доступ запрещен',
  [EResponseStatus.EditForbidden]: 'Редактирование запрещено',
  [EResponseStatus.EmailInUse]: 'Этот email уже используется',
  [EResponseStatus.IncorrectPassword]: 'Неверный пароль',
  [EResponseStatus.IncorrectRequestData]: 'Некорректные данные запроса',
  [EResponseStatus.IncorrectStatus]: 'Некорректный статус',
  [EResponseStatus.ItemNotFound]: 'Элемент не найден',
  [EResponseStatus.PhoneInUse]: 'Этот телефон уже используется',
  [EResponseStatus.RegistrationCodeExpired]: 'Код регистрации истек',
  [EResponseStatus.RegistrationCodeInvalid]: 'Неверный код регистрации',
  [EResponseStatus.Success]: 'Успех',
  [EResponseStatus.SystemError]: 'Системная ошибка',
  [EResponseStatus.Unauthorized]: 'Неавторизованный доступ',
}
