import Bg1 from './images/bg-1.svg'
import Bg2 from './images/bg-2.svg'
import Bg3 from './images/bg-3.svg'
import { Box, Flex, Grid, Heading, Stack, Text } from '@chakra-ui/react'
import { HeadingPromo } from '../../components/HeadingPromo'
import React, { ReactNode } from 'react'
import { Section } from '../../components/Section'
import { Container } from '../../components/Container'

export const WhatWeDo = () => {
  return (
    <Section>
      <Container>
        <Stack spacing="40px">
          <Flex justifyContent="center">
            <Stack maxW="800px" textAlign="center" alignItems="center">
              <HeadingPromo>Что мы делаем</HeadingPromo>
              <Text fontSize="xl" fontWeight={300}>
                Оформляем все необходимые коммерческие документы, формируем платежные
                документы, проводим платежи иностранным поставщикам
              </Text>
            </Stack>
          </Flex>
          <Grid templateColumns={['1fr', '1fr', '1fr 1fr 1fr', '1fr 1fr 1fr']} gap="24px">
            <Item
              title={<>Безопасные <br/>и надежные переводы</>}
              description="Мы применяем только проверенные и безопасные финансовые инструменты, гарантируя защиту ваших транзакций."
              bg={Bg1}
            />
            <Item
              title={<>Консультации <br/>и поддержка</>}
              description="Наши эксперты предложат консультации по всем вопросам международных переводов и обеспечат поддержку на каждом этапе."
              bg={Bg2}
            />
            <Item
              title={<>Гибкость <br/>и скорость</>}
              description="Мы гарантируем гибкие условия переводов и быструю обработку Ваших финансовых операций."
              bg={Bg3}
            />
          </Grid>
        </Stack>
      </Container>
    </Section>
  )
}

interface ItemProps {
  title: ReactNode
  description: string
  bg: string
}

const Item: React.FC<ItemProps> = ({ title, description, bg }) => {
  return (
    <Stack
      position="relative"
      borderRadius="18px"
      padding={['24px', '24px', '32px']}
      spacing="16px"
      background="linear-gradient(180deg, #DDDFF6 0%, #F5F5FF 100%)"
    >
      <Heading fontSize={['1.2rem', '1.3rem', '1.4rem', '1.4rem']} maxW="400px">{title}</Heading>
      <Text fontSize={['md', 'md', 'lg']} fontWeight={300} maxW="300px">{description}</Text>
      <Box
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
        width="100%"
        height="100%"
        backgroundImage={`url(${bg})`}
        backgroundPosition="bottom right"
        backgroundRepeat="no-repeat"
        backgroundSize="contain"
        opacity={0.5}
      />
    </Stack>
  )
}
