import { Alert } from '@chakra-ui/react'
import React from 'react'
import { useOrderData } from 'state/useOrder'
import { isEmptyString } from 'utils/isEmptyString'
import { StepWrapper } from '../../../elements/StepWrapper'

export const NeedContractCorrectionStep = () => {
  const { orderData } = useOrderData()
  return (
    <StepWrapper
      description="В ближайшее время мы свяжемся для уточнения деталей"
    >
      {!isEmptyString(orderData?.comment) && (
        <Alert
          backgroundColor="white"
          borderRadius="5px"
          status="warning"
          variant="left-accent"
        >
          {orderData?.comment}
        </Alert>
      )}
    </StepWrapper>
  )
}
