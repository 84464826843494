import { Box, Button, Divider, HStack, Stack } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { StepWrapper } from '../../../elements/StepWrapper'
import { FileItem } from '../../../elements/FileItem'
import { EDocumentType, useManagerInvoicePaymentConfirmedMutation } from 'api/graphql'
import { TextAreaForm } from 'uikit/TextArea'
import moment from 'moment'
import { Field, Formik, FormikValues } from 'formik'
import { InputMaskForm } from 'uikit/InputMask'
import { InputGroupForm } from 'uikit/InputGroup'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import { FormLabel } from '../../../../../../uikit/FormLabel'

export const PaymentVerificationStep = () => {
  const { orderId, documentsData } = useOrderData()
  const { refetchOrder } = useRefetchOrder()

  const [managerInvoicePaymentConfirmedMutation, { loading: isLoading }] = useManagerInvoicePaymentConfirmedMutation()

  const paidInvoiceDocuments = useMemo(
    () =>
      documentsData?.filter(
        (document) => document.documentType === EDocumentType.PaidInvoice
      ),
    [documentsData]
  )

  const offerAgreementSignedAppendixDocuments = useMemo(
    () =>
      documentsData?.filter(
        (document) => document.documentType === EDocumentType.OfferAgreementSignedAppendix
      ),
    [documentsData]
  )

  const handleForm = async (values: FormikValues) => {
    if (!orderId) return
    await managerInvoicePaymentConfirmedMutation({
      variables: {
        id: orderId,
        comment: values?.comment,
        receiptAmount: Number(values?.receiptAmount),
        paymentDate: moment(
          `${values.date} ${values.time}`,
          'DD.MM.YYYY HH:mm'
        ).utcOffset('+03:00'),
      },
    })
    await refetchOrder()
  }

  return (
    <Stack spacing="14px">
      <StepWrapper description="Проверьте оплаченные счета">
        {!isEmpty(paidInvoiceDocuments) && (
          <>
            <Divider borderColor="gray.300" />
            <Box>
              <FormLabel>
                Документы, подтверждающие оплату
              </FormLabel>
              <Stack spacing="10px">
                {paidInvoiceDocuments?.map((document) => (
                  <FileItem
                    key={document.id}
                    id={document.id}
                    name={document?.fileName}
                    orderId={orderId}
                    cursor="pointer"
                    backgroundColor="white"
                    _hover={{
                      backgroundColor: 'gray.200',
                    }}
                    _active={{
                      backgroundColor: 'gray.300',
                    }}
                  />
                ))}
              </Stack>
            </Box>
          </>
        )}
        {!isEmpty(offerAgreementSignedAppendixDocuments) && (
          <>
            <Divider borderColor="gray.300" />
            <Box>
              <FormLabel>
                Подписанное Поручение (Приложение к Договору - оферте)
              </FormLabel>
              <Stack spacing="10px">
                {offerAgreementSignedAppendixDocuments?.map((document) => (
                  <FileItem
                    key={document.id}
                    id={document.id}
                    name={document?.fileName}
                    orderId={orderId}
                    cursor="pointer"
                    backgroundColor="white"
                    _hover={{
                      backgroundColor: 'gray.200',
                    }}
                    _active={{
                      backgroundColor: 'gray.300',
                    }}
                  />
                ))}
              </Stack>
            </Box>
          </>
        )}
      </StepWrapper>
      <StepWrapper>
        <Formik
          validationSchema={formSchema}
          initialValues={{
            comment: '',
            receiptAmount: '',
            date: moment().format('DD.MM.YYYY'),
            time: moment().format('HH:mm'),
          }}
          onSubmit={handleForm}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Stack spacing="24px">
                <Stack spacing="18px">
                  <HStack spacing="14px" alignItems="flex-start">
                    <Field
                      name="date"
                      label="Дата поступления оплаты"
                      mask={[
                        /\d/,
                        /\d/,
                        '.',
                        /\d/,
                        /\d/,
                        '.',
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      component={InputMaskForm}
                      isDisabled={isLoading}
                    />
                    <Field
                      name="time"
                      label="Время"
                      mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                      component={InputMaskForm}
                      isDisabled={isLoading}
                    />
                  </HStack>
                  <Field
                    name="receiptAmount"
                    label="Сумма поступивших средств"
                    component={InputGroupForm}
                    isDisabled={isLoading}
                  />
                  <Field
                    name="comment"
                    label="Комментарий"
                    placeholder="По желанию"
                    component={TextAreaForm}
                    isDisabled={isLoading}
                  />
                </Stack>
                <HStack spacing="16px">
                  <Button
                    isDisabled={!props.dirty}
                    isLoading={isLoading}
                    type="submit"
                    variant="primary"
                  >
                    Отправить
                  </Button>
                </HStack>
              </Stack>
            </form>
          )}
        </Formik>
      </StepWrapper>
    </Stack>
  )
}

const formSchema = Yup.object().shape({
  date: Yup.string()
    .required('Заполните дату')
    .test('isValidDate', 'Некорректная дата', (value) =>
      moment(value, 'DD.MM.YYYY', true).isValid()
    ),
  time: Yup.string()
    .required('Заполните время')
    .test('isValidTime', 'Некорректное время', (value) =>
      moment(value, 'HH:mm', true).isValid()
    ),
  receiptAmount: Yup.string()
    .matches(/^\d+$/, 'Сумма должна быть числом без точек, запятых и пробелов')
    .required('Сумма обязательна')
    .test('is-positive', 'Сумма должна быть больше нуля', value => parseInt(value, 10) > 0),
})
