import {
  useOrderCompanyLazyQuery,
  useOrderDocumentsLazyQuery,
  useOrderDocumentsQuery,
  useOrderLazyQuery,
  useOrderMessagesLazyQuery,
  useOrderMessagesQuery,
  useOrderQuery,
} from 'api/graphql'
import { useAtomValue, useSetAtom } from 'jotai'
import { orderIdAtom } from './atoms'
import { useCallback, useEffect, useMemo } from 'react'
import { useWebSocket } from '../shared/hooks/useWebSocket'

export const useRefetchOrder = () => {
  const orderId = useAtomValue(orderIdAtom)

  const [refetchOrderData, { loading: isOrderLoading }] = useOrderLazyQuery()
  const [refetchOrderCompany, { loading: isOrderCompanyLoading }] =
    useOrderCompanyLazyQuery()
  const [refetchOrderDocumentsData, { loading: isOrderDocumentsLoading }] =
    useOrderDocumentsLazyQuery()
  const [refetchOrderMessages, { loading: isOrderMessagesLoading }] =
    useOrderMessagesLazyQuery()

  const isLoading =
    isOrderLoading ||
    isOrderCompanyLoading ||
    isOrderDocumentsLoading ||
    isOrderMessagesLoading

  const refetchOrder = useCallback(async () => {
    await refetchOrderData({
      variables: { id: orderId ?? '' },
      fetchPolicy: 'cache-and-network',
    })
    await refetchOrderCompany({
      variables: { id: orderId ?? '' },
      fetchPolicy: 'cache-and-network',
    })
    await refetchOrderDocumentsData({
      variables: { id: orderId ?? '' },
      fetchPolicy: 'cache-and-network',
    })
    await refetchOrderMessages({
      variables: { id: orderId ?? '' },
      fetchPolicy: 'cache-and-network',
    })
  }, [
    refetchOrderData,
    refetchOrderDocumentsData,
    refetchOrderCompany,
    refetchOrderMessages,
  ])

  return {
    refetchOrder,
    isRefetchLoading: isLoading,
  }
}

export const useOrderData = (id?: string) => {
  const orderId = useAtomValue(orderIdAtom)
  const setOrderId = useSetAtom(orderIdAtom)

  const effectiveId = id ?? orderId ?? ''

  const {
    data: orderData,
    called: isOrderCalled,
    loading: isOrderLoading,
  } = useOrderQuery({
    variables: { id: effectiveId },
    fetchPolicy: 'cache-and-network',
  })

  const {
    data: documentsData,
    called: isDocumentCalled,
    loading: isDocumentLoading,
  } = useOrderDocumentsQuery({
    variables: { id: effectiveId },
    fetchPolicy: 'cache-and-network',
  })

  const {
    data: messagesData,
    called: isMessagesCalled,
    loading: isMessagesLoading,
  } = useOrderMessagesQuery({
    variables: { id: effectiveId },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (id && id !== orderId) {
      setOrderId(id)
    }
  }, [id, orderId, setOrderId])

  const isCalled = useMemo(
    () => isOrderCalled || isDocumentCalled || isMessagesCalled,
    [isOrderCalled, isDocumentCalled, isMessagesCalled]
  )
  const isLoading = useMemo(
    () => isOrderLoading || isDocumentLoading || isMessagesLoading,
    [isOrderLoading, isDocumentLoading, isMessagesLoading]
  )

  return {
    isCalled,
    isLoading,
    orderData: orderData?.order ?? null,
    documentsData: documentsData?.orderDocuments ?? null,
    messagesData: messagesData?.orderMessages ?? null,
    orderId,
  }
}

export const useWebSocketOrderData = (orderId: string | undefined) => {
  const { refetchOrder } = useRefetchOrder()

  useWebSocket({
    onMessage: (event) => {
      const data = JSON.parse(event.data)
      console.log(data)
      setTimeout(() => {
        console.log('refetch')
        refetchOrder()
      }, 500)
    },
  })
}
