import React from 'react'
import { DocumentModal } from 'components/DocumentModal'

interface DocPrivacyPolicyModalProps {
  isOpen: boolean
  onClose: () => void
}

export const DocPrivacyPolicyModal: React.FC<DocPrivacyPolicyModalProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <DocumentModal isOpen={isOpen} onClose={onClose}>
      <p>
        <strong>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ </strong>
      </p>
      <p>
        <strong>
          ИСПОЛЬЗУЯ ПРЕДОСТАВЛЯЕМЫЕ В РАМКАХ НАСТОЯЩЕГО САЙТА УСЛУГИ, Я
          БЕЗУСЛОВНО ПОДТВЕРЖДАЮ, ЧТО ОЗНАКОМЛЕН, СОГЛАСЕН И ПРИНИМАЮ УСЛОВИЯ
          НАСТОЯЩЕЙ ПОЛИТИКИ
        </strong>
      </p>
      <ol>
        <li>
          <strong>ОБЩИЕ ПОЛОЖЕНИЯ</strong>
        </li>
      </ol>
      <p>
        1.1. Политика конфиденциальности (далее – «<strong>Политика»</strong>)
        разработана с целью заявления о положениях, в соответствии с которыми
        ООО «Альфред» (далее – «<strong>Правообладатель», «Оператор») </strong>
        обрабатывает персональные данные пользователей Сайта{' '}
        <a href="https://alfred.market/">https://alfred.market/</a> (далее – «
        <strong>Сайт»</strong>). Владельцем исключительных имущественных прав на
        Сайт является Правообладатель.
      </p>
      <p>
        1.2. Порядок использования Сайта определен в Оферте Сайта, размешенной
        по ссылке: <a href="https://alfred.market/">https://alfred.market/</a>{' '}
        (далее – «<strong>Оферта»</strong>). Пользователь обязуется ознакомиться
        с Офертой и соблюдать положения указанного документа, нарушение которого
        может повлечь нарушение прав и законных интересов Правообладателя и
        (или) третьих лиц.
      </p>
      <p>
        1.3. Обработка персональных данных Правообладателем осуществляется в
        соответствии с требованиями Федерального закона Российской Федерации от
        27.07.2006 № 152-ФЗ «О персональных данных» и иными нормативными
        правовыми актами Российской Федерации, регулирующими правоотношения в
        области обработки персональных данных.
      </p>
      <p>
        1.4. В случае, если субъект персональных данных возражает против
        обработки персональных данных Правообладателем в соответствии с
        настоящей Политикой, субъект персональных данных вправе отказаться от
        использования Сайта и (или) направить соответствующее обращение в адрес
        Правообладателя в Личном кабинете или по адресу электронной почты:{' '}
        <a href="mailto:mail@alfred.market">mail@alfred.market</a>. В таком
        случае предоставление функционала Сайта Правообладателем, включая
        регистрацию и (или) использование Личного кабинета Пользователя,
        осуществляться не будет.
      </p>
      <p>1.5. Термины, определения и понятия, используемые в Политике:</p>
      <ul>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <strong>«Сайт»</strong> – сайт, расположенный в сети Интернет
                  по адресу:{' '}
                  <a href="https://alfred.market/">https://alfred.market/</a>,
                  являющийся цифровой площадкой, с помощью которой Пользователь
                  может заключать Сделки на условиях Оферты;
                </li>
                <li>
                  <strong>«Компоненты»</strong> – части Сайта, предоставляющие
                  Пользователю отдельные сервисы и (или) информацию;
                </li>
                <li>
                  <strong>«Пользователь»</strong> – любое лицо, получающее
                  доступ к Сайту и (или) любой информации, размещенной на Сайте
                  (субъект персональных данных);
                </li>
                <li>
                  <strong>«Персональные данные»</strong> - любая информация,
                  относящаяся к определенному или определяемому на основании
                  такой информации физическому лицу (субъекту персональных
                  данных), в том числе его фамилия, имя, отчество, адрес
                  электронной почты, телефонный номер, дата рождения, возраст,
                  иные данные, необходимые для целей обработки персональных
                  данных (при условии их предоставления Пользователем);
                </li>
                <li>
                  <strong>«Услуги» </strong>- услуги, размещаемые на Сайте, в
                  целях получения которых Пользователь может заключать Сделки на
                  условиях Оферты;
                </li>
                <li>
                  <strong>«Блокирование персональных данных»</strong> –
                  временное прекращение обработки персональных данных (за
                  исключением случаев, если обработка необходима для уточнения
                  персональных данных);
                </li>
                <li>
                  <strong>«Защита персональных данных»</strong> – комплекс
                  мероприятий технического, организационного и
                  организационно-технического характера, направленных на защиту
                  сведений, относящихся к определенному или определяемому на
                  основании такой информации субъекту Персональных данных;
                </li>
                <li>
                  <strong>«Оператор»</strong> – Правообладатель, самостоятельно
                  или совместно с другими лицами организующий и (или)
                  осуществляющий обработку персональных данных, а также
                  определяющий цели обработки персональных данных, состав
                  персональных данных, подлежащих обработке, действия
                  (операции), совершаемые с персональными данными;
                </li>
                <li>
                  <strong>«Конфиденциальность персональных данных»</strong> –
                  обязательное для выполнения Оператором или иным лицом,
                  получившим доступ к персональным данным, требование не
                  допускать раскрытия персональных данных третьим лицам и их
                  распространение для целей, отличных от установленных в
                  настоящей Политике, без согласия субъекта персональных данных
                  или наличия иного законного основания обработки персональных
                  данных;
                </li>
                <li>
                  <strong>«Обезличивание персональных данных»</strong> –
                  действия, в результате которых становится невозможным без
                  использования дополнительной информации определить
                  принадлежность персональных данных конкретному субъекту
                  персональных данных;
                </li>
                <li>
                  <strong>«Обработка персональных данных»</strong> – любое
                  действие (операция) или совокупность действий (операций),
                  совершаемых с использованием средств автоматизации или без
                  использования таких средств с персональными данными, включая
                  сбор, запись, систематизацию, накопление, хранение, уточнение
                  (обновление, изменение), извлечение, использование, передачу
                  (распространение, предоставление, доступ), обезличивание,
                  блокирование, удаление, уничтожение персональных данных, если
                  иное прямо не указывается в Оферте и (или) в настоящей
                  Политике;
                </li>
                <li>
                  <strong>
                    «Ответственный за обработку персональных данных»
                  </strong>{' '}
                  – работник Правообладателя, осуществляющий обеспечение
                  безопасности, защиты и соблюдения требований применимого
                  законодательства при обротке персональных данных;
                </li>
                <li>
                  <strong>«Предоставление персональных данных»</strong> –
                  действия, направленные на раскрытие персональных данных
                  государственным органам, осуществляющим проверку персональных
                  данных;
                </li>
                <li>
                  <strong>«Уничтожение персональных данных»</strong> – действия,
                  в результате которых становится невозможным восстановить
                  содержание персональных данных в информационной системе
                  персональных данных и (или) в результате которых уничтожаются
                  материальные носители персональных данных;
                </li>
                <li>
                  <strong>«Общедоступные персональные данные»</strong> —
                  персональные данные, доступ к которым предоставлен
                  неограниченному кругу лиц с согласия субъекта или на которые в
                  соответствии с федеральными законами не распространяется
                  требование соблюдения конфиденциальности.
                </li>
                <li>
                  Иные термины, используемые в Политике, значение которых не
                  определено в настоящей Политике, имеют значение, указанное в
                  Оферте, а также в Федеральном законе Российской Федерации от
                  27.07.2006 № 152-ФЗ «О персональных данных» и иных нормативных
                  правовых актах Российской Федерации, регулирующих
                  правоотношения в сфере обработки персональных данных.
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p>
        1.6. Политика касается как автоматизированной, так и
        неавтоматизированной обработки персональных данных.
      </p>
      <p>
        1.7. Политика подлежит пересмотру и, при необходимости, актуализации в
        случае изменения функционала Сайта.{' '}
      </p>
      <p>
        1.8. Пользователь соглашается принимать и отслеживать изменения,
        вносимые в настоящую Политику с даты внесения таких изменений.
      </p>
      <ol>
        <li>
          <strong>ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
        </li>
      </ol>
      <p>
        2.1. Персональные данные собираются и обрабатываются на основе
        справедливости, целесообразности и законности.
      </p>
      <p>
        2.2. Персональные хранятся для заранее определенных целей и не
        используются иным образом, несовместимым с целями, указанными в
        настоящей Политике.
      </p>
      <p>
        2.3. Персональные данные являются адекватными, относящимися к целям
        обработки персональных данных и не чрезмерными для целей их обработки.
      </p>
      <p>
        2.4. Не допускается обработка персональных данных, несовместимая с
        целями сбора таких данных.
      </p>
      <p>
        2.5. Не допускается объединение баз данных, содержащих персональные
        данные, обработка которых осуществляется в целях, несовместимых между
        собой.
      </p>
      <p>
        2.6. Допускается обработка исключительно тех персональных данных,
        которые отвечают целям их обработки.
      </p>
      <p>
        2.7. Содержание и объем обрабатываемых персональных данных должны
        соответствовать заявленным целям обработки.
      </p>
      <p>
        2.8. Не допускается обработка персональных данных, избыточных по
        отношению к заявленным целям их обработки.
      </p>
      <p>
        2.9. При обработке персональных данных обеспечиваются точность
        персональных данных, их достаточность, актуальность по отношению к целям
        обработки персональных данных. Неполные или неточные данные должны быть
        удалены или уточнены.
      </p>
      <p>
        2.10. Хранение персональных данных осуществляется в форме, позволяющей
        определить субъекта персональных данных, не дольше, чем этого требуют
        цели обработки персональных данных, если срок хранения персональных
        данных не установлен законом, договором, стороной которого,
        выгодоприобретателем или поручителем, по которому является субъект
        персональных данных.
      </p>
      <p>
        2.11. По достижении целей обработки или в случае утраты необходимости в
        достижении этих целей, по законному требованию субъекта персональных
        данных или уполномоченных органов судебной и исполнительной власти
        персональных данных, персональные данные должны быть уничтожены или
        обезличены, если иное не предусмотрено применимым законодательством.
      </p>
      <p>
        2.12. Для целей защиты персональных данных Правообладатель использует
        локализованные технологии.
      </p>
      <ol>
        <li>
          <strong>ВОЗМОЖНЫЕ ВИДЫ ПОЛУЧАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
        </li>
      </ol>
      <p>
        3.1. Сайт может получать доступ к следующей информации, если применимо
        (в некоторых случаях Пользователь может предоставлять указанные ниже
        данные):
      </p>
      <ul>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  Фамилия, имя, отчество Пользователя (для целей получения
                  Услуг, заключения Сделок в соответствии с условиями Оферты);
                </li>
                <li>Гражданство;</li>
                <li>
                  Данные документа, удостоверяющего личность (в том числе,
                  паспортные данные, если применимо);
                </li>
                <li>
                  Дата рождения, место рождения (если применимо, с целью
                  определения возраста Пользователя);
                </li>
                <li>
                  Адрес электронной почты (для привязки Личного кабинета
                  Пользователя и входа в Личный кабинет);
                </li>
                <li>Адрес Пользователя (для целей доставки документов);</li>
                <li>
                  Мобильный телефон Пользователя (для целей подписания
                  документов простой электронной подписью, связи с Пользователем
                  по вопросам оказания Услуг, восстановления доступа
                  Пользователя в Личный кабинет);
                </li>
                <li>
                  Запись электронной переписки на Сайте, включая Личный кабинет
                  Пользователя (если применимо, для целей подтверждения
                  договоренностей и иной информации, касательно Услуг);
                </li>
                <li>
                  Реквизиты электронных средств платежа (номер, срок действия,
                  код CVV/CVC) (для целей оплаты Услуг);
                </li>
                <li>
                  Данные свидетельства о постановке на учет в налоговом органе
                  физического лица по месту жительства на территории Российской
                  Федерации (ИНН) (если применимо);
                </li>
                <li>
                  Иная разумно необходимая информация, необходимая для целей
                  оформления и оказания Услуг.
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ol>
        <li>
          <strong>ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
        </li>
      </ol>
      <p>
        4.1. Правообладатель обрабатывает персональные данные для целей
        предоставления Пользователю возможности использования Сайта, а также в
        целях:
      </p>
      <ul>
        <li>
          <ul>
            <li>продажи и сопровождения оказания Услуг посредством Сайта;</li>
            <li>
              оказания консультационных, информационно-технологических и
              посреднических услуг на условиях Оферты, включая заключение
              договоров с агентами в целях оказания Услуг;
            </li>
            <li>продвижения и оказания Услуг;</li>
            <li>размещения информации об Услугах на Сайте;</li>
            <li>
              размещения предложений по условиям оказания Услуг, поставки (если
              применимо), оказания информационно-технологических услуг;
            </li>
            <li>получения статистических данных;</li>
            <li>
              предоставления сведений уведомительного или маркетингового
              характера, в том числе, о новых Услугах, проводимых акциях,
              мероприятиях (по которым имеется предварительное согласие
              Пользователя на их получение);
            </li>
            <li>обработки запросов и (или) жалоб Пользователей;</li>
            <li>
              регистрация и использование Личного кабинета для совершения
              заказов на Сайте, а также для получения информационных и рекламных
              рассылок;
            </li>
            <li>
              использование личного кабинета зарегистрированным Пользователем на
              Сайте, путем заполнения информации о себе;
            </li>
            <li>
              оформление Заказов (в том числе оплаты и доставки заказов),
              отслеживания статуса Заказа, написания отзывов об Услугах,
              составления обращений по вопросам работы Сайта через специальную
              форму в Личном кабинете зарегистрированного Пользователя;
            </li>
            <li>
              в целях исполнения требований законодательства Российской
              Федерации.
            </li>
          </ul>
        </li>
      </ul>
      <ol>
        <li>
          <strong>ПОРЯДОК И УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
        </li>
      </ol>
      <p>
        5.1. Правообладатель обрабатывает персональные данные как с
        использованием средств автоматизации, так и без использования таких
        средств (в случае обращения Пользователя к Правообладателю в нештатных
        ситуациях).
      </p>
      <p>
        5.2. Правообладатель не предоставляет и не раскрывает персональные
        данные третьим лицам без согласия субъекта данных, за исключением
        случаев, предусмотренных законом.
      </p>
      <p>
        5.3. Согласие субъекта персональных данных на обработку его персональных
        данных может быть дано субъектом персональных данных или его
        представителем в любой форме, позволяющей подтвердить факт его
        получения, в частности, путем согласия с положениями настоящей Политики
        при начале пользования Сайтом.
      </p>
      <p>
        5.4. Правообладатель принимает организационные и технические меры по
        защите персональных данных.
      </p>
      <p>
        5.5. Условием прекращения обработки персональных данных может являться
        достижение целей обработки персональных данных и (или) отзыв согласия
        субъекта персональных данных на их обработку.
      </p>
      <p>
        5.6. Персональные данные, в том числе, но не ограничиваясь, переписки,
        использующиеся в рамках Сайта, не хранятся у Правообладателя и не
        передаются третьим лицам за исключением уполномоченных агентов
        Правообладателя.
      </p>
      <ol>
        <li>
          <strong>ПРОДОЛЖИТЕЛЬНОСТЬ ХРАНЕНИЯ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
        </li>
      </ol>
      <p>
        6.1. Персональные данные хранятся в пределах сроков, определенных
        применимым законодательством.
      </p>
      <p>
        6.2. Данные, выбранные или введенные Пользователем, хранятся на Сайте, а
        также могут храниться посредством локализованных в соответствии с
        законодательством облачных Сайтов на серверах у Правообладателя.
      </p>
      <ol>
        <li>
          <strong>ВЗАИМОДЕЙСТВИЕ С СУБЪЕКТАМИ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
        </li>
      </ol>
      <p>7.1. Субъекту персональных данных предоставляется возможность: </p>
      <ul>
        <li>
          знать об обработке Правообладателем его персональных данных, знать
          основные цели их обработки;
        </li>
        <li>
          получить через разумный промежуток времени и без чрезмерной задержки
          или чрезмерных расходов подтверждение обработки Правообладателем его
          персональных данных, а также получить персональные данные в доступной
          для понимания форме, если у Правообладателя нет правовых препятствий
          для предоставления такой информации;
        </li>
        <li>
          добиваться исправления неточных персональных данных и добиваться
          уничтожения персональных данных, если их обработка не отвечает
          основаниям обработки персональных данных;
        </li>
        <li>
          прибегать к средствам правовой защиты в случае невыполнения просьбы о
          подтверждении обработки, изменении или уничтожении персональных
          данных;
        </li>
        <li>отозвать согласие на обработку персональных данных Оператором.</li>
      </ul>
      <p>
        7.2. Субъект персональных данных может реализовать указанные в п. 7.1.
        Политики возможности путем личного обращения или путем направления
        запроса по следующему адресу электронной почты:{' '}
        <a href="mailto:mail@alfred.market">mail@alfred.market</a>
      </p>
      <p>7.3. Запрос Пользователя (или его представителя) должен содержать:</p>
      <ul>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  реквизиты документа, удостоверяющего личность Пользователя;
                </li>
                <li>
                  сведения, подтверждающие факт обработки Правообладателем
                  персональных данных Пользователя;
                </li>
                <li>запрос пользователя;</li>
                <li>подпись Пользователя или его представителя.</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ol>
        <li>
          <strong>ПРАВА СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
        </li>
      </ol>
      <p>
        8.1. Субъект персональных данных имеет право на получение информации,
        касающейся обработки его персональных данных, в том числе содержащей:
      </p>
      <ul>
        <li>
          <ul>
            <li>
              <ul>
                <li>подтверждение факта обработки персональных данных;</li>
                <li>
                  правовые основания и цели обработки персональных данных;
                </li>
                <li>
                  применяемые Правообладателем способы обработки персональных
                  данных;
                </li>
                <li>
                  наименование и место нахождения Правообладателя, сведения о
                  лицах (за исключением работников), которые имеют доступ к
                  персональным данным или которым могут быть раскрыты
                  персональные данные на основании договора или на основании
                  законодательных требований;
                </li>
                <li>
                  обрабатываемые персональные данные, относящиеся к
                  соответствующему субъекту персональных данных, источник их
                  получения, если иной порядок представления таких данных не
                  предусмотрен соответствующим законом;
                </li>
                <li>
                  сроки обработки персональных данных, в том числе сроки их
                  хранения;
                </li>
                <li>
                  порядок осуществления субъектом персональных данных прав,
                  предусмотренных соответствующим законом;
                </li>
                <li>
                  информацию об осуществленной или о предполагаемой
                  трансграничной передаче персональных данных;
                </li>
                <li>
                  наименование или фамилию, имя, отчество и адрес лица,
                  осуществляющего обработку персональных данных по поручению
                  Правообладателя, если обработка поручена или будет поручена
                  такому лицу;
                </li>
                <li>
                  иные сведения, предусмотренные Федеральным законом «О
                  персональных данных» или другими применимыми законами.
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p>
        8.2. Субъект персональных данных вправе требовать от Правообладателя,
        путем направления письменного требования, уточнения его персональных
        данных, их блокирования или уничтожения в случае, если персональные
        данные являются неполными, устаревшими, неточными, незаконно полученными
        или не являются необходимыми для заявленной цели обработки, а также
        принимать предусмотренные соответствующими законами меры по защите своих
        прав.
      </p>
      <p>
        8.3. Если Пользователь считает, что Правообладатель осуществляет
        обработку его персональных данных с нарушением требований применимого
        законодательства в сфере персональных данных или иным образом нарушает
        его права и свободы, Пользователь вправе направить претензию
        Правообладателю и, в случае бездействия Правообладателя в разумные
        сроки, обжаловать действия или бездействие Правообладателя в
        соответствующий орган по защите прав субъектов персональных данных или в
        судебном порядке.
      </p>
      <ol>
        <li>
          <strong>КОНФИДЕНЦИАЛЬНОСТЬ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
        </li>
      </ol>
      <p>
        9.1. Информация, относящаяся к персональным данным, ставшая известной
        Правообладателю, является конфиденциальной информацией и охраняется
        законом.
      </p>
      <p>
        9.2. Работники Правообладателя и иные лица, получившие доступ к
        обрабатываемым персональным данным, подписали обязательство о
        неразглашении конфиденциальной информации, а также предупреждены о
        возможной дисциплинарной, административной, гражданско-правовой и
        уголовной ответственности в случае нарушения норм и требований
        действующего законодательства в области обработки персональных данных.
      </p>
      <p>
        9.3. Договоры Правообладателя с контрагентами содержат условия
        конфиденциальности передаваемых и получаемых персональных данных, в том
        числе с лицами, привлекаемыми для обработки персональных данных.
      </p>
      <ol>
        <li>
          <strong>
            МЕРЫ ПО ОБЕСПЕЧЕНИЮ БЕЗОПАСНОСТИ ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ
          </strong>
        </li>
      </ol>
      <p>
        10.1. Для обеспечения безопасности обрабатываемых персональных данных
        Правообладатель принимает необходимые правовые, организационные,
        технические меры защиты.
      </p>
      <p>
        10.2. Система защиты персональных данных Правообладателя базируется на
        принципах:
      </p>
      <ul>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  централизованность – система защиты данных должна
                  централизованно управляться;
                </li>
                <li>
                  своевременность – меры обеспечения безопасности персональных
                  данных, применяемые в рамках системы защиты, должны быть
                  своевременными;
                </li>
                <li>
                  целенаправленность – меры обеспечения безопасности
                  персональных данных, применяемые в рамках системы защиты,
                  должны иметь четкие цели, на достижение которых они
                  направлены;
                </li>
                <li>
                  комплексность – система защиты должна включать комплекс мер,
                  направленных на обеспечение безопасности персональных данных,
                  дополняющих и поддерживающих друг друга;
                </li>
                <li>
                  превентивность – меры обеспечения безопасности персональных
                  данных, применяемые в рамках системы защиты, должны носить
                  предупреждающий характер;
                </li>
                <li>
                  надежность – система защиты персональных данных должна
                  обеспечивать достаточные гарантии Правообладателя в том, что
                  обрабатываемые персональных данных защищены надлежащим
                  образом.
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p>
        10.3. В целях осуществления внутреннего контроля соответствия обработки
        персональных данных установленным Правообладателем требованиям
        организовано проведение периодических проверок условий обработки
        персональных данных.
      </p>
      <p>
        10.4. Правообладатель при защите персональных данных Пользователей
        принимает все необходимые организационно-распорядительные, юридические и
        технические меры, в том числе:
      </p>
      <ul>
        <li>
          <ul>
            <li>
              <ul>
                <li>Антивирусная защита;</li>
                <li>Анализ защищённости;</li>
                <li>Обнаружение и предотвращение вторжений;</li>
                <li>Управления доступом;</li>
                <li>Регистрация и учет;</li>
                <li>Обеспечение целостности;</li>
                <li>
                  Организация нормативно-методических локальных актов,
                  регулирующих защиту персональных данных Пользователей.
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p>
        10.5. Процедура оформления доступа к персональным данным Пользователя
        включает в себя:
      </p>
      <ul>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  ознакомление сотрудников Оператора под роспись с настоящим
                  Положением. При наличии иных нормативных актов (приказы,
                  распоряжения, инструкции и т.п.), регулирующих обработку и
                  защиту персональных данных Пользователя, с данными актами
                  также производится ознакомление под роспись.
                </li>
                <li>
                  истребование с сотрудника (за исключением Генерального
                  директора) письменного обязательства о соблюдении
                  конфиденциальности персональных данных Пользователей и
                  соблюдении правил их обработки в соответствии с внутренними
                  локальными актами Правообладателя, регулирующими вопросы
                  обеспечения безопасности конфиденциальной информации.
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p>
        10.6. Сотрудник Оператора, имеющий доступ к персональным данным
        Пользователей в связи с исполнением трудовых обязанностей:
      </p>
      <ul>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  Обеспечивает хранение информации, содержащей персональные
                  данные Пользователя, исключающее доступ к ним третьих лиц.
                </li>
                <li>
                  В отсутствие сотрудника на его рабочем месте не должно быть
                  документов, содержащих персональные данные Пользователей.
                </li>
                <li>
                  При уходе в отпуск, во время служебной командировки и в иных
                  случаях длительного отсутствия сотрудника на своем рабочем
                  месте, он обязан передать документы и иные носители,
                  содержащие персональные данные Пользователей лицу, на которое
                  локальным актом Правообладателя (приказом, распоряжением)
                  будет возложено исполнение его трудовых обязанностей.
                </li>
                <li>
                  В случае если такое лицо не назначено, то документы и иные
                  носители, содержащие персональные данные Пользователей,
                  передаются другому сотруднику, имеющему доступ к персональным
                  данным Пользователей по указанию Генерального директора
                  Правообладателя.
                </li>
                <li>
                  При увольнении сотрудника, имеющего доступ к персональным
                  данным Пользователей, документы и иные носители, содержащие
                  персональные данные Пользователей, передаются другому
                  сотруднику, имеющему доступ к персональным данным
                  Пользователей по указанию Генерального директора.
                </li>
                <li>
                  В целях выполнения порученного задания и на основании
                  служебной записки с положительной резолюцией Генерального
                  директора, доступ к персональным данным Пользователя может
                  быть предоставлен иному сотруднику. Допуск к персональным
                  данным Пользователя других сотрудников Правообладателя, не
                  имеющих надлежащим образом оформленного доступа, запрещается.
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p>10.7. Ответственный за обработку персональных данных обеспечивает:</p>
      <ul>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  Ознакомление сотрудников под роспись с настоящей Политикой.
                </li>
                <li>
                  Истребование с сотрудников письменного обязательства о
                  соблюдении конфиденциальности персональных данных
                  Пользователей и соблюдении правил их обработки.
                </li>
                <li>
                  Общий контроль за соблюдением сотрудниками Правообладателя мер
                  по защите персональных данных Пользователя.
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p>
        10.8. Защита персональных данных Пользователей, хранящихся в электронных
        базах данных Оператора, от несанкционированного доступа, искажения и
        уничтожения информации, а также от иных неправомерных действий,
        обеспечивается системным администратором.
      </p>
      <ol>
        <li>
          <strong>
            МЕСТОНАХОЖДЕНИЕ БАЗ ДАННЫХ ИНФОРМАЦИИ, СОДЕРЖАЩИХ ПЕРСОНАЛЬНЫХ
            ДАННЫХ
          </strong>
        </li>
      </ol>
      <p>
        11.1. Базы данных информации, содержащие персональные данные российских
        граждан, размещаются на территории Российской Федерации.
      </p>
      <ol>
        <li>
          <strong>ТРАНСГРАНИЧНАЯ ПЕРЕДАЧА ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
        </li>
      </ol>
      <p>
        12.1. Трансграничная передача персональных данных каким-либо юридическим
        или физическим лицам может быть осуществлена в целях, указанных в
        Разделе 4 Политики, при условии соблюдения норм применимого
        законодательства и настоящей Политики.
      </p>
      <ol>
        <li>
          <strong>
            ОТВЕТСТВЕННОСТЬ ЗА НАРУШЕНИЕ НОРМ, РЕГУЛИРУЮЩИХ ОБРАБОТКУ И ЗАЩИТУ
            ПЕРСОНАЛЬНЫХ ДАННЫХ
          </strong>
        </li>
      </ol>
      <p>
        13.1. Работники Правообладателя, виновные в нарушении норм, регулирующих
        получение, обработку и защиту персональных данных несут дисциплинарную,
        административную, гражданско-правовую или уголовную ответственность в
        соответствии с действующем законодательством Российской Федерации и
        внутренними локальными актами Правообладателя.
      </p>
      <p>
        <strong>14. ДОПОЛНИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</strong>
      </p>
      <p>
        14.1. Настоящая Политика вступает в силу с момента ее утверждения
        Генеральным директором Правообладателя и действует бессрочно, до замены
        ее новой Политикой.
      </p>
      <p>
        14.2. Правообладатель вправе в одностороннем порядке изменять условия
        Политики.
      </p>
      <p>
        14.3. Ввиду того, что текст Политики находится в свободном доступе в
        сети «Интернет», субъекту персональных данных (Пользователю) необходимо
        самостоятельно следить за изменениями, вносимыми в Политику.
      </p>
      <p>
        14.4. Настоящая Политика распространяется на персональные данные,
        полученные как до, так и после ввода в действие настоящей Политики.
      </p>
      <p>
        14.5. Настоящая Политика, а также все изменения к ней утверждаются
        Правообладателем и вступают в силу со дня ее опубликования на Сайте как
        указано в настоящей Политике.
      </p>
      <p>
        14.6. С момента вступления в силу настоящей редакции Политики,
        предыдущую ее редакцию считать недействительной.
      </p>
      <p>
        <strong>Редакция от 10.10.2024</strong>
      </p>
    </DocumentModal>
  )
}
