import { Flex } from '@chakra-ui/react'
import { EntityHeader } from 'components/Entity/EntityHeader'
import { InfiniteList } from 'uikit/InfiniteList'
import { useOrders } from 'api/order/order.services'
import { OrderControlRow } from 'shared/rows/OrderControlRow'

export const List = () => {
  const { data, next, isLoading, hasNextPage } = useOrders()

  return (
    <Flex flexDirection="column" flex={1}>
      <EntityHeader>
        Заявки
      </EntityHeader>
      <InfiniteList
        data={data}
        next={next}
        isLoading={isLoading}
        hasMore={hasNextPage}
      >
        {(item) => <OrderControlRow key={item.id} data={item} />}
      </InfiniteList>
    </Flex>
  )
}
