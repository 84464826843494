import { EDocumentType, EOrderStatus } from 'api/graphql'
import React from 'react'
import { InvoiceStep } from './steps/InvoiceStep'
import { isEmpty } from 'lodash'
import { PublishStep } from './steps/PublishStep'
import { useOrderData } from 'state/useOrder'
import { WaitingStep } from './steps/WaitingStep'
import { ApprovalProposalStep } from './steps/ApprovalProposalStep'
import { ContactCoordinationStep } from './steps/ContactCoordinationStep'
import { EdiContactSigningStep } from './steps/EdiContactSigningStep'
import { DocumentsRequestedUploadedStep } from './steps/DocumentsRequestedUploadedStep'
import { DealCoordinationStep } from './steps/DealCoordinationStep'
import { InvoicePaymentStep } from './steps/InvoicePaymentStep'
import { DeclinedStep } from './steps/DeclinedStep'
import { NeedContractCorrectionStep } from './steps/NeedContractCorrectionStep'
import { PaymentToSupplerStep } from './steps/PaymentToSupplerStep'
import { PaymentToSupplerConfirmedStep } from './steps/PaymentToSupplerConfirmedStep'
import { FinishedStep } from './steps/FinishedStep'
import { PaymentToSupplierFailedStep } from './steps/PaymentToSupplierFailedStep'
import { PayerCoordinationStep } from './steps/PayerCoordinationStep'
import { RateCoordinationStep } from './steps/RateCoordinationStep'

export const StepUser = () => {
  const { orderData, documentsData } = useOrderData()

  const status = orderData?.status

  if (status === EOrderStatus.Declined) {
    return <DeclinedStep/>
  }

  if (status === EOrderStatus.NeedContractCorrection) {
    return <NeedContractCorrectionStep/>
  }

  if (status === EOrderStatus.PaymentToSupplierFailed) {
    return <PaymentToSupplierFailedStep />
  }

  if (status === EOrderStatus.Created) {
    if (isEmpty(documentsData)) {
      return <InvoiceStep />
    } else {
      const invoice = documentsData?.find(
        (doc) => doc.documentType === EDocumentType.Invoice
      )
      return invoice ? <PublishStep /> : <InvoiceStep />
    }
  }
  if (status === EOrderStatus.ProposalApproval) {
    return <ApprovalProposalStep />
  }
  if (status === EOrderStatus.ContractCoordination) {
    return <ContactCoordinationStep />
  }
  if (status === EOrderStatus.PayerCoordination) {
    return <PayerCoordinationStep />
  }
  if (status === EOrderStatus.ContractSigning) {
    return <EdiContactSigningStep />
  }
  if (status === EOrderStatus.DocumentsRequested) {
    return <DocumentsRequestedUploadedStep />
  }
  if (status === EOrderStatus.DealCoordination) {
    return <DealCoordinationStep />
  }
  if (status === EOrderStatus.RateCoordination) {
    return <RateCoordinationStep />
  }
  if (status === EOrderStatus.InvoicePayment) {
    return <InvoicePaymentStep />
  }
  if (status === EOrderStatus.PaymentToSupplier) {
    return <PaymentToSupplerStep />
  }
  if (status === EOrderStatus.PaymentToSupplierConfirmed) {
    return <PaymentToSupplerConfirmedStep/>
  }
  if (status === EOrderStatus.Finished) {
    return <FinishedStep/>
  }

  return <WaitingStep />
}
