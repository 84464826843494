import { Button, HStack, Stack } from '@chakra-ui/react'
import React from 'react'
import { useManagerSetDealDateMutation } from 'api/graphql'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { StepWrapper } from '../../../elements/StepWrapper'
import moment from 'moment/moment'
import { Field, Formik, FormikValues } from 'formik'
import { InputMaskForm } from 'uikit/InputMask'
import * as Yup from 'yup'

export const NeedDealCorrectionStep = () => {
  const { orderId, orderData } = useOrderData()
  const [managerSetDealDateMutation, { loading: isSetDateLoading }] =
    useManagerSetDealDateMutation()
  const { refetchOrder } = useRefetchOrder()

  const handleForm = async (values: FormikValues) => {
    if (!orderId) return
    await managerSetDealDateMutation({
      variables: {
        id: orderId,
        comment: '',
        dealDate: moment(
          `${values.date} ${values.time}`,
          'DD.MM.YYYY HH:mm'
        ).utcOffset('+03:00'),
      },
    })
    await refetchOrder()
  }

  return (
    <StepWrapper
      description="Время сделки не устроило, предложите другую дату"
      userComment={orderData?.comment}
    >
      <Formik
        validationSchema={formSchema}
        initialValues={{
          date: moment(orderData?.dealDate).format('DD.MM.YYYY'),
          time: moment(orderData?.dealDate).format('HH:mm'),
        }}
        onSubmit={handleForm}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <Stack spacing="24px">
              <HStack spacing="14px" alignItems="flex-start">
                <Field
                  name="date"
                  label="Дата"
                  mask={[
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  component={InputMaskForm}
                  isDisabled={isSetDateLoading}
                />
                <Field
                  name="time"
                  label="Время"
                  mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                  component={InputMaskForm}
                  isDisabled={isSetDateLoading}
                />
              </HStack>
              <HStack spacing="16px">
                <Button
                  isDisabled={!props.dirty}
                  isLoading={isSetDateLoading}
                  type="submit"
                  variant="primary"
                >
                  Отправить
                </Button>
              </HStack>
            </Stack>
          </form>
        )}
      </Formik>
    </StepWrapper>
  )
}

const formSchema = Yup.object().shape({
  date: Yup.string()
    .required('Заполните дату')
    .test('isValidDate', 'Некорректная дата', (value) =>
      moment(value, 'DD.MM.YYYY', true).isValid()
    ),
  time: Yup.string()
    .required('Заполните время')
    .test('isValidTime', 'Некорректное время', (value) =>
      moment(value, 'HH:mm', true).isValid()
    ),
})
