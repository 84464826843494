import React from 'react'
import { useNavigate } from 'react-router-dom'
import { UserEntity } from 'shared/entities/UserEntity'

export const Create = () => {
  const navigate = useNavigate()

  return (
    <>
      <UserEntity onBack={() => navigate(-1)}/>
    </>
  )
}
