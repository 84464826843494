import React from 'react'
import * as Chakra from '@chakra-ui/react'
import { BoxProps } from '@chakra-ui/react'
import { FieldProps, FormikValues } from 'formik'

interface CheckboxProps extends Chakra.CheckboxProps {
  labelProps?: BoxProps
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(
  { labelProps, children, ...props },
  ref
) {
  return (
    <Chakra.Checkbox ref={ref} colorScheme="purple" {...props}>
      <Chakra.Box ml="1px" {...labelProps}>{children}</Chakra.Box>
    </Chakra.Checkbox>
  )
})

export interface CheckboxFormProps extends Omit<Chakra.CheckboxProps, 'form'>, FieldProps {
  label: string
  labelProps: BoxProps
}

export const CheckboxForm = React.forwardRef<HTMLInputElement, CheckboxFormProps>(
  function CheckboxForm({ field, form, label, labelProps, onChange, ...props }, ref) {
    const handleCheckbox = (e: FormikValues) => {
      onChange && onChange(e.target.checked)
      form.setFieldValue(field.name, e.target.checked)
    }
    return (
      <Chakra.FormControl>
        <Checkbox
          ref={ref}
          isChecked={field.value || false}
          onChange={handleCheckbox}
          labelProps={labelProps}
          {...props}
        >
          {label}
        </Checkbox>
      </Chakra.FormControl>
    )
  }
)
