import React, { ReactNode } from 'react'
import { Box, Flex } from '@chakra-ui/react'

interface EntityHeaderProps {
  rightElement?: ReactNode
  children: ReactNode
}

export const EntityHeader: React.FC<EntityHeaderProps> = ({ rightElement, children }) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      height="54px"
      px="20px"
      borderBottomWidth={1}
      zIndex={2}
      borderColor="gray.100"
      position="sticky"
      backgroundColor="rgba(255, 255, 255, 0.8)"
      backdropFilter="blur(10px) saturate(180%)"
      top={0}
    >
      <Box fontWeight="600">{children}</Box>
      {rightElement && <Flex>{rightElement}</Flex>}
    </Flex>
  )
}
