import { Flex, Grid, GridProps } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { isEmptyString } from '../../utils/isEmptyString'

interface RowInfoProps extends GridProps {
  label: string
  value: ReactNode | null | undefined
}

export const RowInfo: React.FC<RowInfoProps> = ({ label, value,  ...props }) => {
  const isEmpty = typeof value === 'string' ? isEmptyString(value) : !value
  return (
    <Grid
      templateColumns={['1fr', '1fr 1fr']}
      gap={['8px', '20px']}
      padding="14px 8px"
      minH="46px"
      lineHeight="1.2"
      borderBottomWidth={1}
      borderColor="gray.200"
      fontSize="0.96rem"
      _last={{
        borderWidth: 0
      }}
      {...props}
    >
      <Flex
        alignItems="center"
        fontSize={['0.9rem', '1rem']}
        color="gray.600"
      >
        {label}
      </Flex>
      <Flex
        alignItems="center"
        {...isEmpty && {
          opacity: 0.5
        }}
      >
        {isEmpty ? 'Нет данных' : value}
      </Flex>
    </Grid>
  )
}
