import { Button, HStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useManagerOfferPayerMutation } from 'api/graphql'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { TextArea } from 'uikit/TextArea'
import { StepWrapper } from '../../../elements/StepWrapper'
import { isEmptyString } from 'utils/isEmptyString'
import { UploadButton } from '../../Upload/UploadButton'

export const PayerApprovingStep = () => {
  const { orderId, orderData } = useOrderData()
  const [managerOfferPayerMutation, { loading }] = useManagerOfferPayerMutation()
  const [value, setValue] = useState('')
  const [isComment, setIsComment] = useState(false)
  const { refetchOrder } = useRefetchOrder()

  const handleFinish = async () => {
    if (!orderId) return
    await managerOfferPayerMutation({
      variables: {
        id: orderId,
        comment: value
      }
    })
    await refetchOrder()
  }

  return (
    <StepWrapper
      description="Добавьте данные плательщика для согласования с поставщиком"
      userComment={orderData?.comment}
    >
      {isComment ? (
        <TextArea
          name="comment"
          placeholder="Напишите данные плательщика"
          minHeight="100px"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      ) : (
        <Button
          size="sm"
          width="fit-content"
          variant="linkBlue"
          onClick={() => setIsComment(true)}
        >
          Добавить текстовые данные плательщика
        </Button>
      )}
      <UploadButton
        documentType="PayerDetails"
        selectLabelButton="Выбрать документ"
        uploadLabelButton="Загрузить и отправить"
        onFinish={handleFinish}
      />

    </StepWrapper>
  )
}
