import * as Chakra from '@chakra-ui/react'
import { Box, BoxProps, CloseButtonProps, ModalProps } from '@chakra-ui/react'
import React from 'react'

export interface IModal extends ModalProps {
  isOpen: boolean
  isHideClose?: boolean
  header?: React.ReactNode
  footer?: React.ReactNode
  children: React.ReactNode
  onClose: () => void
  containerProps?: BoxProps
  bodyProps?: BoxProps
  closeButtonProps?: CloseButtonProps
}

export const Modal: React.FC<IModal> = ({
  header,
  footer,
  isOpen,
  isHideClose,
  onClose,
  children,
  containerProps,
  bodyProps,
  closeButtonProps,
  ...props
}) => {
  return (
    <Chakra.Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={undefined}
      {...(isHideClose && {
        closeOnEsc: false,
        closeOnOverlayClick: false,
      })}
      motionPreset="slideInBottom"
      {...props}
    >
      <Chakra.ModalOverlay />
      <Chakra.ModalContent
        pb="30px"
        maxW="600px"
        borderRadius={['0', '28px']}
        {...containerProps}
      >
        {header ? (
          <Chakra.ModalHeader padding={['24px 20px 16px', '24px 30px 16px']} alignItems="center">
            {header}
          </Chakra.ModalHeader>
        ) : <Box h="60px"/>}
        {!isHideClose && (
          <Chakra.ModalCloseButton
            top="14px"
            right="14px"
            tabIndex={-1}
            borderRadius="full"
            {...closeButtonProps}
          />
        )}
        <Chakra.ModalBody px={['20px', '30px']} py={0} {...bodyProps}>
          {isOpen && children}
        </Chakra.ModalBody>

        {footer && <Chakra.ModalFooter pb={0}>{footer}</Chakra.ModalFooter>}
      </Chakra.ModalContent>
    </Chakra.Modal>
  )
}

