import React, {
  forwardRef,
  MutableRefObject,
  ReactElement,
  RefAttributes,
  useCallback,
  useMemo,
} from 'react'
import {
  components,
  GroupBase,
  Props,
  Select as ChakraSelect,
  SelectInstance,
} from 'chakra-react-select'
import { Box, BoxProps, Center, FormControl, FormLabelProps, HStack } from '@chakra-ui/react'
import { FormikValues } from 'formik'
import _ from 'lodash'
import { FormLabel } from '../FormLabel'

export type SelectExtended = {
  label?: string
  isInvalid?: boolean
  isHideError?: boolean
  isDisabled?: boolean
  customControlStyle?: BoxProps
  customPlaceholderStyle?: BoxProps
  customValueContainerStyle?: BoxProps
  labelProps?: FormLabelProps
  error?: string
}

type SelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Props<Option, IsMulti, Group> & SelectExtended

export type SelectComponent = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: SelectProps<Option, IsMulti, Group> &
    RefAttributes<SelectInstance<Option, IsMulti, Group>>
) => ReactElement

// eslint-disable-next-line react/display-name
export const Select = forwardRef(
  <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
    {
      customControlStyle,
      customPlaceholderStyle,
      customValueContainerStyle,
      labelProps,
      ...props
    }: SelectProps<Option, IsMulti, Group>,
    ref:
      | ((instance: SelectInstance<Option, IsMulti, Group> | null) => void)
      | MutableRefObject<SelectInstance<Option, IsMulti, Group> | null>
      | null
  ) => {
    const { Option, SingleValue, MultiValue } = components
    const IconOption = (props: any) => (
      <Option {...props}>
        <HStack spacing="8px" alignItems="center">
          {props.data.icon && <Center>{props.data.icon}</Center>}
          <Box>{props.data.label}</Box>
        </HStack>
      </Option>
    )

    const IconSingleValue = (props: any) => (
      <SingleValue {...props}>
        <HStack spacing="10px" alignItems="center">
          {props.data.icon && <Center>{props.data.icon}</Center>}
          <Box>{props.data.label}</Box>
        </HStack>
      </SingleValue>
    )

    const IconMultiValue = (props: any) => (
      <MultiValue {...props}>
        <HStack spacing="10px" alignItems="center">
          {props.data.icon && <Center>{props.data.icon}</Center>}
          <Box>{props.data.label}</Box>
        </HStack>
      </MultiValue>
    )

    const GroupHeading = (props: any) => (
      <HStack
        spacing="10px"
        alignItems="center"
        px="10px"
        pt="10px"
        pb="10px"
        bg="gray.50"
      >
        <Box fontWeight="600" fontSize="0.9rem" flex={1}>
          {props.data.label}
        </Box>
        {props.data.icon && <Center>{props.data.icon}</Center>}
      </HStack>
    )

    const customStyle = {
      placeholder: (provided: object, state: object) => {
        return {
          ...provided,
          ...customPlaceholderStyle,
        }
      },
      valueContainer: (provided: object, state: object) => {
        return {
          ...provided,
          ...customValueContainerStyle,
        }
      },
      control: (provided: object, state: object) => {
        return {
          ...provided,
          backgroundColor: '#ffffff',
          /*_focusVisible: {
            zIndex: 1,
            borderColor: 'gray.400',
            boxShadow: '0 0 0 1px var(--chakra-colors-gray-400)',
          },*/
          ...customControlStyle,
        }
      },
    }

    return (
      <>
        <FormControl isInvalid={props.isInvalid}>
          {props?.label && (
            <FormLabel isDisabled={props.isDisabled} {...labelProps}>{props.label}</FormLabel>
          )}
          <ChakraSelect
            ref={ref}
            useBasicStyles={true}
            isDisabled={props.isDisabled}
            chakraStyles={customStyle}
            errorBorderColor="red.300"
            focusBorderColor="gray.400"
            isInvalid={props.isInvalid}
            styles={{
              multiValue: (base) => ({
                ...base,
                borderRadius: '6px',
              }),
              multiValueRemove: (base) => ({
                ...base,
                borderRadius: '6px',
              }),
            }}
            components={{
              Option: IconOption,
              SingleValue: IconSingleValue,
              GroupHeading: GroupHeading,
              MultiValue: IconMultiValue,
            }}
            noOptionsMessage={() => 'Пусто'}
            {...props}
          />
          {props?.error && props?.isInvalid && !props?.isHideError && (
            <Box fontSize="0.7rem" color="red.500" mt="4px">
              {props.error}
            </Box>
          )}
        </FormControl>
      </>
    )
  }
) as SelectComponent

export const SelectForm = React.forwardRef<HTMLInputElement, FormikValues>(
  function SelectForm({ field, form, onChange, ...props }, ref) {
    const isMulti = props?.isMulti
    const options = props.options

    const handleChange = useCallback(
      (option: any) => {
        onChange && onChange()

        if (option) {
          form.setFieldValue(
            field.name,
            isMulti ? option.map((item: any) => item.value) : option.value
          )
        } else {
          form.setFieldValue(field.name, '')
        }
      },
      [form, field, isMulti]
    )

    const value = useMemo(() => {
      if (options) {
        return isMulti
          ? options.filter((option: any) => field.value?.indexOf(option.value) >= 0)
          : options?.find((option: any) => option.value === field.value) ?? ''
      } else {
        return isMulti ? [] : ''
      }
    }, [isMulti, options, field])

    return (
      <Select
        ref={ref}
        name={field.name}
        isInvalid={_.has(form.errors, field.name) && _.has(form.touched, field.name)}
        error={_.get(form.errors, field.name)}
        {...field}
        {...props}
        value={value}
        onChange={handleChange}
      />
    )
  }
)
