import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

export const Button = defineStyleConfig({
  sizes: {
    lg: defineStyle({
      fontSize: '1rem'
    })
  },
  variants: {
    secondary: defineStyle(() => ({
      boxShadow: 'rgba(0, 0, 0, 0.02) 0px 4px 4px -1px, rgba(0, 0, 0, 0.06) 0px 1px 1px 0px',
      borderWidth: '1px',
      backgroundColor: 'white',
      _hover: {
        backgroundColor: 'gray.100'
      },
      _active: {
        backgroundColor: 'gray.200',
        boxShadow: 'none'
      }
    })),
    primary: defineStyle((props) => ({
      boxShadow: 'rgba(0, 0, 0, 0.02) 0px 4px 4px -1px, rgba(0, 0, 0, 0.06) 0px 1px 1px 0px',
      backgroundColor: `purple.500`,
      color: 'white',
      _hover: {
        backgroundColor: 'purple.600',
        _disabled: {
          backgroundColor: 'purple.600',
          opacity: 0.7,
        },
      },
      _active: {
        backgroundColor: 'purple.700',
        boxShadow: 'none'
      },
      _disabled: {
        opacity: 0.7,
      },
    })),
    attention: defineStyle((props) => ({
      boxShadow: 'rgba(0, 0, 0, 0.02) 0px 4px 4px -1px, rgba(0, 0, 0, 0.06) 0px 1px 1px 0px',
      backgroundColor: `red.500`,
      color: 'white',
      _hover: {
        backgroundColor: 'red.600',
        _disabled: {
          backgroundColor: 'red.600',
          opacity: 0.7,
        },
      },
      _active: {
        backgroundColor: 'red.700',
        boxShadow: 'none'
      },
      _disabled: {
        opacity: 0.7,
      },
    })),
    'with-shadow': {
      bg: 'red.400',
      boxShadow: '0 0 2px 2px #efdfde',
    },
    linkBlue: defineStyle((props) => ({
      color: 'blue.400',
      fontWeight: 'normal',
      px: 0,
      py: 0,
      height: 'auto',
      width: 'fit-content',
      _hover: {
        color: 'blue.500'
      },
    })),
    bart: defineStyle((props) => ({
      bg: props.colorMode === 'dark' ? 'blackBart.800' : 'blackBart.900',
      borderRadius: 'full',
      color: 'white',
      _hover: {
        bg: props.colorMode === 'dark' ? 'blackBart.700' : 'blackBart.800',
        _disabled: {
          bg: props.colorMode === 'dark' ? 'blackBart.800' : 'blackBart.900',
          opacity: 0.8,
        },
      },
      _active: {
        bg: props.colorMode === 'dark' ? 'blackBart.900' : 'blackBart.900',
      },
      _disabled: {
        opacity: 0.7,
      },
    })),
    bartGray: defineStyle((props) => ({
      bg: 'hsla(0,0%,95%,.88)',
      backdropFilter: 'blur(24px) saturate(8)',
      borderRadius: 'full',
      color: 'black',
      _hover: {
        bg: 'hsla(0,0%,90%,0.88)',
        color: 'black',
        _disabled: {
          bg: 'hsla(0,0%,95%,.88)',
          opacity: 0.8,
        },
      },
      _active: {
        bg: 'hsla(0,0%,86%,.88)',
      },
      _disabled: {
        opacity: 0.7,
      },
    })),
    bartWhite: defineStyle((props) => ({
      bg: 'white',
      borderRadius: 'full',
      color: 'black',
      _hover: {
        bg: 'hsla(0,0%,90%,0.88)',
        color: 'black',
        _disabled: {
          bg: 'hsla(0,0%,95%,.88)',
          opacity: 0.8,
        },
      },
      _active: {
        bg: 'hsla(0,0%,86%,.88)',
      },
      _disabled: {
        opacity: 0.7,
      },
    })),
    bartWhiteBorder: defineStyle((props) => ({
      bg: 'white',
      boxShadow: '0 2px 4px rgba(0,0,0,.08),0 0 1px rgba(0,0,0,.3)',
      borderRadius: 'full',
      color: 'black',
      _hover: {
        bg: 'hsla(0,0%,96%,0.88)',
        color: 'black',
        _disabled: {
          bg: 'hsla(0,0%,98%,.88)',
          opacity: 0.8,
        },
      },
      _active: {
        bg: 'hsla(0,0%,86%,.88)',
        boxShadow: '0 0 1px rgba(0,0,0,.3)',
      },
      _disabled: {
        opacity: 0.7,
      },
    })),
  },
})
