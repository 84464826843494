import { Box, Flex, Grid, Heading, HStack, Image, Stack } from '@chakra-ui/react'
import React, { FC, ReactNode } from 'react'
import Logo from '../../shared/images/logo.svg'
import { Link } from 'react-router-dom'
import { docLinks } from '../../constants/links'
import { Icon } from '@chakra-ui/icons'
import { LuFile } from 'react-icons/lu'

export const Documents = () => {
  return (
    <Box backgroundColor="white" minHeight="100vh">
      <Flex
        flexDirection="column"
        width="100%"
        maxW="1240px"
        margin="0 auto"
        padding="0 20px"
      >
        <Stack spacing="30px" py="30px">
          <Flex borderBottomWidth={1} paddingBottom="34px">
            <Link to="/">
              <Image maxW="180px" src={Logo} alt="Logo" />
            </Link>
          </Flex>
          <Heading size="lg">Документы</Heading>
          <Stack spacing="14px">
            <Item url={docLinks.privacyPolicy}>Политика конфиденциальности</Item>
            <Item url={docLinks.offer}>Оферта на оказание услуг</Item>
            <Item url={docLinks.cookies}>
              Политика использования файлов cookie
            </Item>
            <Item url={docLinks.personalData}>Обработка моих персональных данных</Item>
          </Stack>
        </Stack>
      </Flex>
    </Box>
  )
}

interface ItemProps {
  url: string
  children: ReactNode
}

const Item: FC<ItemProps> = ({ url, children }) => {
  return (
    <HStack
      spacing="14px"
      backgroundColor="#F8F9FF"
      borderRadius="12px"
      onClick={() => window.open(url)}
      width="100%"
      height="60px"
      alignItems="center"
      cursor="pointer"
      padding="4px 18px"
      transition="0.2s"
      _hover={{
        backgroundColor: '#edeef8'
      }}
    >
      <Icon as={LuFile} boxSize="18px"/>
      <Box>
        {children}
      </Box>
    </HStack>
  )
}
