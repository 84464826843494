import { useOrderData } from 'state/useOrder'
import { EOrderStatus, useCompanyQuery, useSaveOrderMutation, useUserQuery } from 'api/graphql'
import { OrderForm } from 'shared/forms/OrderForm'
import React, { useMemo, useState } from 'react'
import { FormikValues } from 'formik'
import {
  Box, Button,
  Stack, useClipboard,
  useToast,
} from '@chakra-ui/react'
import { useCurrentUser } from 'state/useCurrentUser'
import { TextArea } from 'uikit/TextArea'
import { FormikHelpers } from 'formik/dist/types'
import { RowInfo } from 'components/RowInfo/RowInfo'
import { RowInfoGroup } from 'components/RowInfo/RowInfoGroup'
import moment from 'moment'
import { ChoiceManagerModal } from '../../../modals/ChoiceManagerModal'

export const Details = () => {
  const toast = useToast()
  const { isControl, isAdmin } = useCurrentUser()
  const { orderData, orderId } = useOrderData()
  const [isChoiceManagerOpen, setIsChoiceManagerOpen] = useState(false)

  const companyId = orderData?.companyId
  const userId = orderData?.userId

  const { data: orderCompanyData } = useCompanyQuery({
    variables: {
      id: companyId,
    },
    skip: !companyId,
    fetchPolicy: 'cache-and-network',
  })

  const { data: orderUserData } = useUserQuery({
    variables: {
      id: userId,
    },
    skip: !userId || !isControl,
    fetchPolicy: 'cache-and-network',
  })

  const [saveOrderMutation, { loading: isLoading }] = useSaveOrderMutation()

  const initialValues = useMemo(
    () => ({
      companyId: orderCompanyData?.company?.id,
      tnVed: orderData?.tnVed,
      comment: orderData?.comment,
      logistics: orderData?.logistics,
      insurance: orderData?.insurance,
    }),
    [orderData, orderCompanyData]
  )

  const company = useMemo(() => {
    if (!orderData || !orderCompanyData?.company?.id) return ''

    const {
      name,
      inn,
      kpp,
      legalAddress,
      actualAddress,
      ceo,
      bic,
      bank,
      correspondentAccount,
      checkingAccount,
    } = orderCompanyData.company

    return `Название: ${name}\nИНН: ${inn}\nКПП: ${kpp}\nЮридический адрес: ${legalAddress}\nФактический адрес: ${actualAddress}\nГенеральный директор: ${ceo}\nБИК: ${bic}\nБанк: ${bank}\nКорреспондентский счёт: ${correspondentAccount}\nРасчётный счёт: ${checkingAccount}`
  }, [orderData, orderCompanyData])

  const { onCopy: onCompanyCopy, hasCopied: hasCompanyCopied } = useClipboard(company)
  const { onCopy: onTnVedCopy, hasCopied: hasTnVedCopied } = useClipboard(orderData?.tnVed ?? '')

  const handleForm = async (values: FormikValues, { resetForm }: FormikHelpers<any>) => {
    await saveOrderMutation({
      variables: {
        id: orderData?.id,
        ...values,
      },
      onCompleted: (data) => {
        toast({
          title: `Заявка успешно обоновлена`,
          status: 'success',
          position: 'top-right',
          isClosable: true,
        })
        resetForm()
      },
      onError: () => {
        toast({
          title: `Ошибка обновления заявки`,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        })
      },
    })
  }

  if (orderData?.status === EOrderStatus.Created && !isControl) {
    return (
      <Stack spacing="24px">
        <Box>
          Заявка доступна для редактирования до ее публикации. Если вы заметили ошибку,
          исправьте ее и нажмите "Сохранить"
        </Box>
        <OrderForm
          initialValues={initialValues}
          isLoading={isLoading}
          isDirty
          isHideComment
          onSubmit={handleForm}
        />
      </Stack>
    )
  }

  return (
    <>
      <Stack spacing="28px">
        <RowInfoGroup header="Основные">
          {orderData?.receiptDate && (
            <RowInfo label="Средства поступили" value={moment(orderData?.receiptDate).format('DD.MM.YYYY HH:mm')} />
          )}
          {orderData?.receiptAmount && (
            <RowInfo label="Сумма поступивших средств" value={orderData?.receiptAmount} />
          )}
          {orderData?.amount && (
            <RowInfo label="Сумма сделки" value={orderData?.amount} />
          )}
          {orderData?.dealDate && (
            <RowInfo label="Дата сделки" value={moment(orderData?.dealDate).format('DD.MM.YYYY HH:mm')} />
          )}
          {orderData?.paperContractSigned && (
            <RowInfo label="Подписание" value="Бумажный носитель"/>
          )}
          {orderData?.edi && (
            <>
              <RowInfo label="Система ЭДО" value={orderData?.ediName} />
              <RowInfo label="Идентификатор ЭДО" value={orderData.ediId} />
            </>
          )}
          {orderData?.managerUser?.name && (
            <RowInfo
              label="Менеджер"
              value={
                <Stack>
                  <Box>{orderData.managerUser.name}</Box>
                  {isAdmin && (
                    <Button
                      width="fit-content"
                      size="sm"
                      variant="linkBlue"
                      aria-label="Settings"
                      onClick={() => setIsChoiceManagerOpen(true)}
                    >
                      Сменить
                    </Button>
                  )}
                </Stack>
              }
            />
          )}
          <RowInfo
            label="Услуги"
            value={[
              orderData?.logistics && 'Логистика',
              orderData?.insurance && 'Страхование',
            ]
              .filter(Boolean)
              .join(', ')}
          />
        </RowInfoGroup>
        <RowInfoGroup
          header="Реквизиты пользователя"
          rightElement={
            <Button size="sm" onClick={onCompanyCopy} variant="linkBlue">
              {hasCompanyCopied ? 'Скопировано!' : 'Копировать'}
            </Button>
          }
        >
          <RowInfo label="Название" value={orderCompanyData?.company.name} />
          <RowInfo label="ИНН" value={orderCompanyData?.company.inn} />
          <RowInfo label="КПП" value={orderCompanyData?.company.kpp} />
          <RowInfo
            label="Юридический адрес"
            value={orderCompanyData?.company.legalAddress}
          />
          <RowInfo
            label="Фактический адрес"
            value={orderCompanyData?.company.actualAddress}
          />
          <RowInfo label="Генеральный директор" value={orderCompanyData?.company.ceo} />
          <RowInfo label="БИК" value={orderCompanyData?.company.bic} />
          <RowInfo label="Банк" value={orderCompanyData?.company.bank} />
          <RowInfo
            label="Корреспондентский счёт"
            value={orderCompanyData?.company.correspondentAccount}
          />
          <RowInfo
            label="Расчётный счёт"
            value={orderCompanyData?.company.checkingAccount}
          />
        </RowInfoGroup>
        {isControl && orderUserData?.user && (
          <RowInfoGroup header="Уполномоченный представитель пользователя">
            <RowInfo label="Имя" value={orderUserData?.user.name} />
            <RowInfo label="Электронная почта" value={orderUserData?.user.email} />
            <RowInfo label="Телефон" value={orderUserData?.user.phone} />
          </RowInfoGroup>
        )}
        <RowInfoGroup
          header="Список ТЕН ВЭД"
          rightElement={
            <Button size="sm" onClick={onTnVedCopy} variant="linkBlue">
              {hasTnVedCopied ? 'Скопировано!' : 'Копировать'}
            </Button>
          }
        >
          <Box py="8px">
            <TextArea
              name="tnVed"
              minHeight="300px"
              isReadOnly
              value={orderData?.tnVed ?? ''}
            />
          </Box>
        </RowInfoGroup>
      </Stack>
      {orderId && isAdmin && (
        <ChoiceManagerModal
          managerId={orderData?.managerUser?.id}
          orderId={orderId}
          isOpen={isChoiceManagerOpen}
          onClose={() => setIsChoiceManagerOpen(false)}
        />
      )}
    </>
  )
}
