import { createMultiStyleConfigHelpers, defineStyle, defineStyleConfig } from '@chakra-ui/react'
import { inputAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const lg = defineStyle({
  fontSize: '1rem',
})

const sizes = {
  lg: definePartsStyle({ field: lg, addon: lg }),
}

export const Input = defineMultiStyleConfig({ sizes })
