import { Button, Stack } from '@chakra-ui/react'
import React from 'react'
import { useManagerEdiContractUploadedMutation } from 'api/graphql'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { RowInfo } from 'components/RowInfo/RowInfo'
import { StepWrapper } from '../../../elements/StepWrapper'

export const EdiContactWaitingStep = () => {
  const { orderId, orderData } = useOrderData()
  const [managerEdiContractUploadedMutation, { loading: isLoading }] =
    useManagerEdiContractUploadedMutation()
  const { refetchOrder } = useRefetchOrder()

  const handleClick = async () => {
    if (!orderId) return
    await managerEdiContractUploadedMutation({
      variables: {
        id: orderId,
      },
    })
    await refetchOrder()
  }

  return (
    <StepWrapper description="Пользователь выбрал подписание через ЭДО. Загрузить контракт в систему и подтвердите">
      {orderData?.edi && (
        <Stack
          spacing={0}
          borderRadius="14px"
          padding="8px 16px"
          backgroundColor="white"
        >
          <RowInfo label="Система ЭДО" value={orderData?.ediName} />
          <RowInfo label="Идентификатор ЭДО" value={orderData.ediId} />
        </Stack>
      )}
      <Button
        width="fit-content"
        isLoading={isLoading}
        variant="primary"
        onClick={handleClick}
      >
        Подтвердить загрузку
      </Button>
    </StepWrapper>
  )
}
