import { Button, Flex } from '@chakra-ui/react'
import { EntityHeader } from 'components/Entity/EntityHeader'
import { useNavigate } from 'react-router-dom'
import { useMyCompaniesQuery } from 'api/graphql'
import { Icon } from '@chakra-ui/icons'
import { LuPlus } from 'react-icons/lu'
import { StaticList } from 'uikit/StaticList'
import { CompanyRow } from 'shared/rows/CompanyRow'
import { EntityEmpty } from 'components/Entity/EntityEmpty'

export const List = () => {
  const navigate = useNavigate()
  const { data, loading: isLoading } = useMyCompaniesQuery({
    fetchPolicy: 'cache-and-network'
  })

  return (
    <Flex flexDirection="column" flex={1}>
      <EntityHeader
        rightElement={
          <Button leftIcon={<Icon as={LuPlus}/>} variant="secondary" onClick={() => navigate('create')} size="sm">
            Новая компания
          </Button>
        }
      >
        Мои компании
      </EntityHeader>
      <StaticList
        data={data?.myCompanies}
        isLoading={isLoading}
        noDataRender={
          <EntityEmpty
            title="Ничего нет"
            description="Вы пока не создали ни одной компании"
          />
        }
      >
        {(item) => <CompanyRow key={item.id} data={item} />}
      </StaticList>
    </Flex>
  )
}
