import { useState, useEffect } from 'react'
import { DaDataResponse } from '../types'

interface UseDaDataResult {
  data: DaDataResponse | null
  loading: boolean
  error: Error | null
}

const useDaData = (query: string): UseDaDataResult => {
  const [data, setData] = useState<DaDataResponse | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    if (!query.trim()) {
      setData(null)
      setLoading(false)
      setError(null)
      return
    }

    const fetchData = async () => {
      setLoading(true)
      const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party'
      const token = 'b785ce9025ec3847fa0e5e6b133efd3096d3989d'

      const options = {
        method: 'POST',
        mode: 'cors' as RequestMode,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({ query }),
      }

      try {
        const response = await fetch(url, options)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result: DaDataResponse = await response.json()
        setData(result)
      } catch (error) {
        setError(error as Error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [query])

  return { data, loading, error }
}

export default useDaData
