import React from 'react'
import { Box, Flex, HStack } from '@chakra-ui/react'
import { orderStatusColor, orderStatusLabel } from 'constants/orderStatus'
import { useOrderData } from 'state/useOrder'

export const Status: React.FC = () => {
  const { orderData } = useOrderData()

  const status = orderData?.status

  if (!status) {
    return null
  }

  return (
    <Flex alignItems="center">
      <HStack
        spacing="12px"
        textTransform="initial"
        borderRadius="8px"
        lineHeight="1.2"
        padding="8px 12px"
        //height="40px"
        fontSize="0.9rem"
        borderWidth={1}
      >
        <Box backgroundColor={`${orderStatusColor[status]}.500`} borderRadius="full" boxSize="10px"/>
        <Box>
          {orderStatusLabel[status]}
        </Box>
      </HStack>
    </Flex>
  )
}
