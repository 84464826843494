import { Box, Button, HStack, Stack, useToast } from '@chakra-ui/react'
import { useImperativeFilePicker } from 'use-file-picker'
import { DocumentType, useUpload } from 'api/api'
import React from 'react'
import { isEmpty } from 'lodash'
import { FileItem } from '../../elements/FileItem'
import { useOrderData, useRefetchOrder } from 'state/useOrder'

interface UploadZoneProps {
  documentType: DocumentType
  onFinish?: () => void
}

export const UploadZone: React.FC<UploadZoneProps> = ({
  documentType,
  onFinish,
}) => {
  const { orderId } = useOrderData()
  const { refetchOrder } = useRefetchOrder()
  const toast = useToast()
  const { upload, uploadProgress, isLoading } = useUpload()

  const { openFilePicker, filesContent, plainFiles, removeFileByIndex, clear } =
    useImperativeFilePicker({
      readAs: 'ArrayBuffer',
      maxFileSize: 100,
      multiple: true,
    })

  const isEmptyFiles = isEmpty(filesContent)

  const handleUpload = async () => {
    try {
      if (!orderId) return
      if (filesContent.length > 0) {
        for await (const file of plainFiles) {
          await upload({
            file,
            orderId,
            documentType,
          })
        }
        clear()
        toast({
          title: `Файлы успешно загружены`,
          status: 'success',
          position: 'top-right',
          isClosable: true,
        })
        refetchOrder()
        onFinish && onFinish()
      }
    } catch (e) {
      toast({
        title: `Ошибка загрузки файла`,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      })
    }
  }

  return (
    <Stack
      spacing="14px"
      borderRadius="14px"
      padding="20px"
      backgroundColor={isEmptyFiles ? 'white' : 'gray.100'}
      borderWidth={1}
      borderColor={isEmptyFiles ? 'gray.200' : 'transparent'}
      borderStyle="dashed"
    >
      <Box>
        Загрузите любые документы, которые могут быть полезны для заказа
      </Box>
      {!isEmptyFiles && (
        <Stack spacing="10px">
          {filesContent.map((file, index) => (
            <FileItem
              key={file.name}
              name={file.name}
              onRemove={() => removeFileByIndex(index)}
              backgroundColor="white"
            />
          ))}
        </Stack>
      )}
      <HStack spacing="14px">
        {!isEmptyFiles && (
          <Button
            size="sm"
            isLoading={isLoading}
            variant="primary"
            onClick={handleUpload}
            loadingText={`Загрузка: ${uploadProgress}%`}
          >
            Загрузить
          </Button>
        )}
        {!isLoading && (
          <Button size="sm" variant="secondary" onClick={openFilePicker}>
            {isEmptyFiles ? 'Выбрать файлы' : 'Добавить еще'}
          </Button>
        )}
      </HStack>
    </Stack>
  )
}
