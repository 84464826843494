import React, { useMemo } from 'react'
import { UploadButton } from '../../Upload/UploadButton'
import { StepWrapper } from '../../../elements/StepWrapper'
import { Box, Divider, Stack } from '@chakra-ui/react'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { EDocumentType, useUserRequestedDocumentsUploadedMutation } from 'api/graphql'
import { isEmpty } from 'lodash'
import { FormLabel } from 'uikit/FormLabel'
import { FileItem } from '../../../elements/FileItem'

export const DocumentsRequestedUploadedStep = () => {
  const { orderId, orderData, documentsData } = useOrderData()
  const [userRequestedDocumentsUploadedMutation] =
    useUserRequestedDocumentsUploadedMutation()
  const { refetchOrder } = useRefetchOrder()

  const draftDocuments = useMemo(
    () =>
      documentsData?.filter(
        (document) => document.documentType === EDocumentType.Draft
      ),
    [documentsData]
  )

  const handleFinish = async () => {
    if (!orderId) return
    await userRequestedDocumentsUploadedMutation({
      variables: {
        id: orderId,
      },
    })
    await refetchOrder()
  }

  return (
    <StepWrapper description="Загрузка документов по заявке">
      <Box style={{ whiteSpace: 'pre-line' }}>{orderData?.comment}</Box>
      {!isEmpty(draftDocuments) && (
        <>
          <Divider borderColor="gray.300" />
          <Box>
            <FormLabel>
              Драфт для заполнения
            </FormLabel>
            <Stack spacing="10px">
              {draftDocuments?.map((document) => (
                <FileItem
                  key={document.id}
                  id={document.id}
                  name={document?.fileName}
                  orderId={orderId}
                  cursor="pointer"
                  backgroundColor="white"
                  _hover={{
                    backgroundColor: 'gray.200',
                  }}
                  _active={{
                    backgroundColor: 'gray.300',
                  }}
                />
              ))}
            </Stack>
          </Box>
        </>
      )}
      <Divider borderColor="gray.300" />
      <UploadButton
        documentType="DealDocuments"
        selectLabelButton="Выбрать документы"
        onFinish={handleFinish}
      />
    </StepWrapper>
  )
}
