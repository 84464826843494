import { Button, Flex } from '@chakra-ui/react'
import { EntityHeader } from 'components/Entity/EntityHeader'
import { useNavigate } from 'react-router-dom'
import { Icon } from '@chakra-ui/icons'
import { LuPlus } from 'react-icons/lu'
import { useUserOrdersQuery } from 'api/graphql'
import { OrderUserRow } from 'shared/rows/OrderUserRow'
import { StaticList } from 'uikit/StaticList'
import { EntityEmpty } from 'components/Entity/EntityEmpty'
import { useMemo } from 'react'
import { orderBy } from 'lodash'

export const List = () => {
  const navigate = useNavigate()
  const { data, loading: isLoading } = useUserOrdersQuery({
    fetchPolicy: 'cache-and-network',
  })

  const ordersData = useMemo(
    () => orderBy(data?.userOrders, ['updatedAt'], ['desc']),
    [data]
  )

  return (
    <Flex flexDirection="column" flex={1}>
      <EntityHeader
        rightElement={
          <Button
            leftIcon={<Icon as={LuPlus} />}
            variant="secondary"
            onClick={() => navigate('create')}
            size="sm"
          >
            Новая заявка
          </Button>
        }
      >
        Заявки
      </EntityHeader>
      <StaticList
        data={ordersData}
        isLoading={isLoading}
        noDataRender={
          <EntityEmpty
            title="Ничего нет"
            description="Вы пока не создали ни одной заявки"
          />
        }
      >
        {(item) => <OrderUserRow key={item.id} data={item} />}
      </StaticList>
    </Flex>
  )
}
