import { Entity } from 'components/Entity/Entity'
import React, { useMemo } from 'react'
import { FormikValues } from 'formik'
import { useToast } from '@chakra-ui/react'
import { useCreateOrderMutation, useMyCompaniesQuery } from 'api/graphql'
import { OrderForm } from '../../forms/OrderForm'

interface CreateOrderEntityProps {
  onChange: (orderId: string) => void
  onBack: () => void
}

export const CreateOrderEntity: React.FC<CreateOrderEntityProps> = ({ onBack, onChange }) => {
  const toast = useToast()
  const { data: myCompaniesData, loading, error, refetch } = useMyCompaniesQuery()
  const [createOrderMutation, { loading: isLoading }] = useCreateOrderMutation()

  const myCompanies = myCompaniesData?.myCompanies

  const initialValues = useMemo(() => ({
    companyId: myCompanies?.length === 1 ? myCompanies[0].id : '',
    tnVed: '',
    comment: '',
    logistics: false,
    insurance: false
  }), [myCompanies])

  const handleForm = async (values: FormikValues) => {
    await createOrderMutation({
      variables: {
        ...values
      },
      onCompleted: (data) => {
        toast({
          title: `Заявка успешно создана`,
          status: 'success',
          position: 'top-right',
          isClosable: true,
        })
        onChange(data.saveOrder.id)
      },
      onError: () => {
        toast({
          title: `Ошибка создания заявки`,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        })
      }
    })
  }

  return (
    <Entity
      header="Новая заявка"
      onBack={onBack}
    >
      <OrderForm
        initialValues={initialValues}
        isLoading={isLoading}
        onSubmit={handleForm}
      />
    </Entity>
  )
}
