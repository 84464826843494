import useReactWebSocket, { Options } from 'react-use-websocket'
import { useCurrentUser } from '../../state/useCurrentUser'

export const useWebSocket = ({ onError, onMessage, ...options }: Options) => {
  const SOCKET_URL = `wss://${window.location.host}/api/notification`

  const { isAuthenticated } = useCurrentUser()

  const { ...result } = useReactWebSocket(
    SOCKET_URL,
    {
      shouldReconnect: () => true,
      onError: (e) => {
        onError && onError(e)
        console.error('onError WSNotification', e, 'token')
      },
      onMessage: (event) => {
        onMessage && onMessage(event)
      },
      ...options,
    },
    isAuthenticated
  )

  return { ...result }
}
