import { Box, Button, HStack, Stack } from '@chakra-ui/react'
import { Field, Formik, FormikValues } from 'formik'
import { SelectForm } from 'uikit/Select'
import React from 'react'
import { InputGroupForm } from 'uikit/InputGroup'
import * as Yup from 'yup'
import { EUserRole } from 'api/graphql'

interface UserFormProps {
  initialValues: FormikValues
  isLoading: boolean
  onSubmit: (values: FormikValues) => Promise<void>
}

export const UserForm: React.FC<UserFormProps> = ({
  initialValues,
  isLoading,
  onSubmit,
}) => {
  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={formSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <Stack spacing="28px">
              <Stack spacing="18px">
                <Field
                  name="role"
                  label="Роль"
                  placeholder="Выберите роль"
                  component={SelectForm}
                  isDisabled={isLoading}
                  options={[
                    {
                      label: 'Пользователь',
                      value: EUserRole.User,
                    },
                    {
                      label: 'Менеджер',
                      value: EUserRole.Manager,
                    },
                    {
                      label: 'Админ',
                      value: EUserRole.Admin,
                    }
                  ]}
                />
                <Field
                  name="name"
                  label="Контактное лицо"
                  placeholder="Иванов Иван Иванович"
                  component={InputGroupForm}
                  isDisabled={isLoading}
                />
                <Field
                  name="phone"
                  label="Телефон"
                  placeholder="0000000000"
                  leftAddon="+"
                  component={InputGroupForm}
                  isDisabled={isLoading}
                />
                <Field
                  name="email"
                  placeholder="name@example.ru"
                  label="Электронная почта"
                  component={InputGroupForm}
                  isDisabled={isLoading}
                />
                <Field
                  name="telegramUsername"
                  placeholder="Без собачки"
                  label="Телеграм"
                  component={InputGroupForm}
                  isDisabled={isLoading}
                />
                <Field
                  type="password"
                  name="password"
                  label="Пароль"
                  component={InputGroupForm}
                  isDisabled={isLoading}
                />
              </Stack>
              <Box>
                <Button isLoading={isLoading} type="submit" variant="primary">
                  Сохранить
                </Button>
              </Box>
            </Stack>
          </form>
        )}
      </Formik>
    </>
  )
}

const formSchema = Yup.object().shape({
  role: Yup.string().required('Введите роль'),
  name: Yup.string().required('Введите имя'),
  phone: Yup.string().required('Введите телефон'),
  email: Yup.string().test('email', 'Введите правильную почту', (e) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(e))
  ),
})
