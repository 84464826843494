import { CloudContainer } from '../../../elements/CloudContainer'
import { useCurrentUser } from 'state/useCurrentUser'

export const WaitingStep = () => {
  const { isControl } = useCurrentUser()

  return (
    <CloudContainer>
      {isControl ? (
        'Ждем ответа от пользователя'
      ) : (
        'Заявка находится в обработке у менеджера, ожидайте'
      )}
    </CloudContainer>
  )
}
