import { Box, useToast } from '@chakra-ui/react'
import { CompanyDaData } from 'components/CompanyDaData'
import { FormikValues } from 'formik'
import { Entity } from 'components/Entity/Entity'
import React, { useEffect, useMemo, useState } from 'react'
import { useCreateCompanyMutation, useMyCompaniesQuery, useSaveCompanyMutation } from 'api/graphql'
import { CompanyForm } from '../../forms/CompanyForm'

interface CompanyEntityProps {
  id?: string
  onChange: (companyId: string) => void
  onBack: () => void
}

export const CompanyEntity: React.FC<CompanyEntityProps> = ({ id, onChange, onBack }) => {
  const toast = useToast()
  const isNew = !id
  const [isUseDaData, setIsUseDaData] = useState(isNew)
  const [initialValues, setInitialValues] = useState<FormikValues>(defaultValues)

  const { data: myCompaniesData, loading, error, refetch } = useMyCompaniesQuery()
  const [createCompanyMutation, { loading: isCreateLoading }] = useCreateCompanyMutation()
  const [saveCompanyMutation, { loading: isSaveLoading }] = useSaveCompanyMutation()
  const isLoading = isCreateLoading || isSaveLoading

  const data = useMemo(() => {
    if (!id) return null
    return myCompaniesData?.myCompanies?.find((company) => company.id === id) ?? null
  }, [myCompaniesData, id])

  const handleCompanyDaData = (data: any) => {
    setInitialValues(data)
    setIsUseDaData(false)
  }

  const handleForm = async (values: FormikValues) => {
    if (isNew) {
      await createCompanyMutation({
        variables: {
          ...values
        },
        onCompleted: (data) => {
          toast({
            title: `Компания успешно создана`,
            status: 'success',
            position: 'top-right',
            isClosable: true,
          })
          onChange(data.saveCompany.id)
        },
        onError: () => {
          toast({
            title: `Ошибка создания компании`,
            status: 'error',
            position: 'top-right',
            isClosable: true,
          })
        }
      })
      return
    }
    await saveCompanyMutation({
      variables: {
        ...values,
        id
      },
      onCompleted: () => {
        toast({
          title: `Компания успешно обновлена`,
          status: 'success',
          position: 'top-right',
          isClosable: true,
        })
        onChange(id)
      },
      onError: () => {
        toast({
          title: `Ошибка обновления компании`,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        })
      }
    })
  }

  useEffect(() => {
    if (data) {
      setInitialValues(data)
    }
  }, [data])

  return (
    <Entity
      header={isNew ? 'Новая компания' : 'Компания'}
      description={
        isUseDaData ? (
          <>
            Введите ИНН компании, чтобы заполнить поля автоматически из справочника
            <Box as="span" layerStyle="link" onClick={() => setIsUseDaData(false)}>
              {' '}
              заполнить вручную
            </Box>
          </>
        ) : (
          <>
            Заполните данные компании для автозаполнения в заявках.
            <Box as="span" layerStyle="link" onClick={() => setIsUseDaData(true)}>
              {' '}
              Искать компанию в справочнике
            </Box>
          </>
        )
      }
      onBack={onBack}
    >
      {isUseDaData ? (
        <CompanyDaData onChange={handleCompanyDaData} />
      ) : (
        <CompanyForm isLoading={isLoading} initialValues={initialValues} onSubmit={handleForm}/>
      )}
    </Entity>
  )
}

const defaultValues = {
  name: '',
  inn: '',
  kpp: '',
  legalAddress: '',
  actualAddress: '',
  ceo: '',
  bic: '',
  bank: '',
  correspondentAccount: '',
  checkingAccount: ''
}
