import { OrderEntity } from 'shared/entities/OrderEntity'
import { useNavigate, useParams } from 'react-router-dom'

export const Item = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  return (
    <OrderEntity id={id} onBack={() => navigate('/orders')}/>
  )
}
