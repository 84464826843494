import { Box, Button, Divider, Stack } from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { StepWrapper } from '../../../elements/StepWrapper'
import {
  EDocumentType,
  useManagerPaymentToSupplierConfirmedMutation,
} from 'api/graphql'
import { TextArea } from 'uikit/TextArea'
import { UploadButton } from '../../Upload/UploadButton'
import { isEmpty } from 'lodash'
import { FormLabel } from 'uikit/FormLabel'
import { FileItem } from '../../../elements/FileItem'

export const PaymentToSupplerStep = () => {
  const { orderId, documentsData } = useOrderData()
  const [value, setValue] = useState('')
  const [isComment, setIsComment] = useState(false)
  const { refetchOrder } = useRefetchOrder()

  const [managerPaymentToSupplierConfirmedMutation, { loading: isLoading }] =
    useManagerPaymentToSupplierConfirmedMutation()

  const actDocuments = useMemo(
    () =>
      documentsData?.filter(
        (document) => document.documentType === EDocumentType.Act
      ),
    [documentsData]
  )

  const swiftDocuments = useMemo(
    () =>
      documentsData?.filter(
        (document) => document.documentType === EDocumentType.Swift
      ),
    [documentsData]
  )

  const handleClick = async () => {
    if (!orderId) return
    await managerPaymentToSupplierConfirmedMutation({
      variables: {
        id: orderId,
        comment: value,
      },
    })
    await refetchOrder()
  }

  return (
    <StepWrapper description="Загрузка SWIFT документа и Акта">
      {isComment ? (
        <TextArea
          name="comment"
          placeholder="Напишите комментарий по желанию"
          minHeight="100px"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      ) : (
        <Button
          size="sm"
          width="fit-content"
          variant="linkBlue"
          onClick={() => setIsComment(true)}
        >
          Добавить комментарий
        </Button>
      )}
      <Divider borderColor="gray.300" />
      {!isEmpty(swiftDocuments) && (
        <Box>
          <FormLabel>
            SWIFT документы
          </FormLabel>
          <Stack spacing="10px">
            {swiftDocuments?.map((document) => (
              <FileItem
                key={document.id}
                id={document.id}
                name={document?.fileName}
                orderId={orderId}
                cursor="pointer"
                backgroundColor="white"
                _hover={{
                  backgroundColor: 'gray.200',
                }}
                _active={{
                  backgroundColor: 'gray.300',
                }}
              />
            ))}
          </Stack>
        </Box>
      )}
      {isEmpty(swiftDocuments) && (
        <UploadButton
          documentType="Swift"
          selectLabelButton="Выбрать SWIFT"
          uploadLabelButton="Загрузить"
        />
      )}
      {!isEmpty(swiftDocuments) && isEmpty(actDocuments) && (
        <>
          <Divider borderColor="gray.300" />
          <Box>
            <FormLabel>
              Акты на подпись
            </FormLabel>
            <UploadButton
              documentType="Act"
              selectLabelButton="Выбрать акт для подписи"
              uploadLabelButton="Загрузить"
            />
          </Box>
        </>
      )}
      {!isEmpty(actDocuments) && (
        <>
          <Divider borderColor="gray.300" />
          <Box>
            <FormLabel>
              Акты на подпись
            </FormLabel>
            <Stack spacing="10px">
              {actDocuments?.map((document) => (
                <FileItem
                  key={document.id}
                  id={document.id}
                  name={document?.fileName}
                  orderId={orderId}
                  cursor="pointer"
                  backgroundColor="white"
                  _hover={{
                    backgroundColor: 'gray.200',
                  }}
                  _active={{
                    backgroundColor: 'gray.300',
                  }}
                />
              ))}
            </Stack>
          </Box>
        </>
      )}
      {!isEmpty(swiftDocuments) && !isEmpty(actDocuments) && (
        <>
          <Divider borderColor="gray.300" />
          <Button
            isLoading={isLoading}
            width="fit-content"
            variant="primary"
            onClick={handleClick}
          >
            Подтвердить оплату поставщику
          </Button>
        </>
      )}
    </StepWrapper>
  )
}
