import { Flex, HStack, StackProps } from '@chakra-ui/react'
import React, { forwardRef, ReactNode } from 'react'

interface ItemProps extends StackProps {
  leftAddon?: ReactNode
  containerProps?: StackProps
  children: ReactNode
}

export const Item = forwardRef<HTMLDivElement, ItemProps>(
  ({ leftAddon, containerProps, children, ...props }, ref) => {
    return (
      <Flex ref={ref} role="group" {...menuItemStyle} {...props}>
        <HStack spacing="14px" flex={1} {...containerProps}>
          {leftAddon && <>{leftAddon}</>}
          {children}
        </HStack>
      </Flex>
    )
  }
)

export const menuItemStyle = {
  padding: '9px 10px',
  borderRadius: '8px',
  transition: '0.2s',
  cursor: 'pointer',
  _hover: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  },
  _active: {
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
  }
}
