import { Flex, FlexProps } from '@chakra-ui/react'
import React, { ReactNode } from 'react'

interface ContainerProps extends FlexProps {
  children: ReactNode
}

export const Container: React.FC<ContainerProps> = ({ children, ...props }) => {
  return (
    <Flex
      flexDirection="column"
      width="100%"
      maxW="1240px"
      margin="0 auto"
      padding="0 20px"
      {...props}
    >
      {children}
    </Flex>
  )
}
