import { BoxProps, Flex, HStack } from '@chakra-ui/react'
import React, { ReactNode } from 'react'

interface Props {
  labelProps?: BoxProps
  renderRight?: ReactNode
  children: ReactNode
}

export const ItemLabel: React.FC<Props> = ({ labelProps, renderRight, children }) => {
  return (
    <HStack alignItems="center" userSelect="none" flex={1}>
      <Flex fontSize="0.90rem" lineHeight="1.2" fontWeight={500} {...labelProps}>
        {children}
      </Flex>
      {renderRight && <>{renderRight}</>}
    </HStack>
  )
}
