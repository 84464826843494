import { Section } from '../../components/Section'
import { Container } from '../../components/Container'
import { Box, Center, Flex, Grid, Stack } from '@chakra-ui/react'
import { HeadingPromo } from '../../components/HeadingPromo'
import React, { useState } from 'react'
import { MotionBox } from '../../components/MotionBox'
import { Icon } from '@chakra-ui/icons'
import { HiPlus, HiMinus } from 'react-icons/hi2'

export const Faq = () => {
  return (
    <Section>
      <Container>
        <Stack spacing="40px" alignItems="center">
          <Flex justifyContent="center" textAlign="center">
            <HeadingPromo>Частые вопросы</HeadingPromo>
          </Flex>
          <Stack spacing="18px" maxW="920px">
            <Item
              question="Как осуществить перевод денежных средств контрагенту в Китай?"
              answer="Постановка на учет контракта осуществляется бесплатно в день обращения при отсутствии замечаний к документам."
            />
            <Item
              question="Как подготовить закрывающий акт в соответствии с требованиями законодательства?"
              answer="Постановка на учет контракта осуществляется бесплатно в день обращения при отсутствии замечаний к документам."
            />
            <Item
              question="Как быстро поставить на учет валютный контракт и сколько это стоит?"
              answer="Постановка на учет контракта осуществляется бесплатно в день обращения при отсутствии замечаний к документам."
            />
            <Item
              question="У нас есть инвойс от китайского поставщика. Сможете ли Вы осуществить валютные операции?"
              answer="Постановка на учет контракта осуществляется бесплатно в день обращения при отсутствии замечаний к документам."
            />
            <Item
              question="Что необходимо для начала работы на Вашем сервисе?"
              answer="Постановка на учет контракта осуществляется бесплатно в день обращения при отсутствии замечаний к документам."
            />
          </Stack>
        </Stack>
      </Container>
    </Section>
  )
}

interface ItemProps {
  question: string
  answer: string
}

const Item: React.FC<ItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Box
      backgroundColor="#F8F9FF"
      borderRadius="18px"
      padding="24px 30px"
    >
      <Grid role="group" gap="24px" templateColumns="1fr 20px">
        <Stack>
          <Box
            fontSize={['1.1rem', '1.2rem', '1.3rem']}
            fontWeight={600}
            transition="0.3s"
            lineHeight="1.2"
            cursor="pointer"
            _groupHover={{
              color: 'purple.500'
            }}
            onClick={() => setIsOpen(!isOpen)}
          >
            {question}
          </Box>
          <MotionBox inVisible={isOpen}>
            <Box fontSize={['1rem', '1rem', '1.1rem', '1.1rem']} fontWeight={300}>
              {answer}
            </Box>
          </MotionBox>
        </Stack>
        <Center
          flex="none"
          borderRadius="full"
          borderWidth={1}
          boxSize="32px"
          backgroundColor="white"
          cursor="pointer"
          transition="0.3s"
          onClick={() => setIsOpen(!isOpen)}
          _groupHover={{
            backgroundColor: '#F8F9FF'
          }}
        >
          {isOpen ? <Icon as={HiMinus}/> : <Icon as={HiPlus}/>}
        </Center>
      </Grid>
    </Box>
  )
}
