import { Button, Stack } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { EDocumentType, useManagerFinishOrderMutation } from 'api/graphql'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { StepWrapper } from '../../../elements/StepWrapper'
import { FileItem } from '../../../elements/FileItem'

export const AktVerificationStep = () => {
  const { orderId, documentsData } = useOrderData()
  const { refetchOrder } = useRefetchOrder()

  const [managerFinishOrderMutation, { loading: isLoading }] = useManagerFinishOrderMutation()

  const documents = useMemo(
    () =>
      documentsData?.filter(
        (document) => document.documentType === EDocumentType.SignedAct
      ),
    [documentsData]
  )

  const handleClick = async () => {
    if (!orderId) return
    await managerFinishOrderMutation({
      variables: {
        id: orderId,
      },
    })
    await refetchOrder()
  }

  return (
    <StepWrapper
      description="Проверьте акт"
    >
      <Stack spacing="10px">
        {documents?.map((document) => (
          <FileItem
            key={document.id}
            id={document.id}
            name={document?.fileName}
            orderId={orderId}
            cursor="pointer"
            backgroundColor="white"
            _hover={{
              backgroundColor: 'gray.200',
            }}
            _active={{
              backgroundColor: 'gray.300',
            }}
          />
        ))}
      </Stack>
      <Button
        width="fit-content"
        isLoading={isLoading}
        variant="primary"
        onClick={handleClick}
      >
        Завершить заявку
      </Button>
    </StepWrapper>
  )
}
