import { Modal } from '../../uikit/Modal'
import React, { useEffect, useMemo, useState } from 'react'
import { Select } from '../../uikit/Select'
import { Button, Stack } from '@chakra-ui/react'
import { useManagersQuery, useOrderSetManagerMutation } from 'api/graphql'
import { useRefetchOrder } from '../../state/useOrder'

interface ChoiceManagerModalProps {
  isOpen: boolean
  orderId: string
  managerId: string
  onClose: () => void
}

export const ChoiceManagerModal: React.FC<ChoiceManagerModalProps> = ({ orderId, managerId, isOpen, onClose }) => {
  const { data: usersData, loading: isManagersLoading } = useManagersQuery({
    skip: !isOpen,
    fetchPolicy: 'network-only'
  })

  const { refetchOrder } = useRefetchOrder()

  const [orderSetManagerMutation, { loading: isLoading }] = useOrderSetManagerMutation()
  const [value, setValue] = useState<string | null>(managerId)

  const options = useMemo(() => {
    return usersData?.users?.edges?.map(({ node }) => ({
      value: node.id,
      label: node.name,
    }))
  }, [usersData])

  const valueOption = options?.find((option) => option.value === value)

  const handleClick = async () => {
    if (!value || !orderId) return
    await orderSetManagerMutation({
      variables: {
        id: orderId,
        managerUserId: value
      }
    })
    await refetchOrder()
    onClose()
  }

  useEffect(() => {
    setValue(managerId)
  }, [managerId])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isHideClose={isLoading}
      header="Выбор менеджера"
      containerProps={{
        maxW: '380px',
      }}
    >
      <Stack spacing="18px">
        <Select
          isLoading={isManagersLoading}
          options={options}
          placeholder="Менеджер"
          onChange={(e) => setValue(e?.value)}
          value={valueOption}
        />
        <Button
          isDisabled={!value}
          width="fit-content"
          isLoading={isLoading}
          onClick={handleClick}
          variant="primary"
        >
          Назначить
        </Button>
      </Stack>
    </Modal>
  )
}
