import { Button, HStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useManagerOfferRateMutation } from 'api/graphql'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { TextArea } from 'uikit/TextArea'
import { StepWrapper } from '../../../elements/StepWrapper'
import { isEmptyString } from 'utils/isEmptyString'

export const RateApprovingStep = () => {
  const { orderId, orderData } = useOrderData()
  const [managerOfferRateMutation, { loading }] = useManagerOfferRateMutation()
  const [value, setValue] = useState('')
  const { refetchOrder } = useRefetchOrder()

  const handleClick = async () => {
    if (!value || !orderId) return
    await managerOfferRateMutation({
      variables: {
        id: orderId,
        comment: value
      }
    })
    await refetchOrder()
  }

  return (
    <StepWrapper
      description="Напишите текущий курс, по которому выполняется расчет суммы в счете"
      userComment={orderData?.comment}
    >
      <TextArea
        name="comment"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <HStack spacing="20px">
        <Button
          isDisabled={isEmptyString(value)}
          width="fit-content"
          isLoading={loading}
          onClick={handleClick}
          variant="primary"
        >
          Отправить
        </Button>
      </HStack>
    </StepWrapper>
  )
}
