import React from 'react'
import { DocumentModal } from 'components/DocumentModal'

interface DocRequisitesModalProps {
  isOpen: boolean
  onClose: () => void
}

export const DocRequisitesModal: React.FC<DocRequisitesModalProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <DocumentModal isOpen={isOpen} onClose={onClose}>
      <p>
        <strong>ООО «Альфред»</strong>
      </p>
      <table>
        <tr>
          <td>
            <p>
              <strong>Полное наименование</strong>
            </p>
          </td>
          <td>
            <p>Общество с ограниченной ответственностью </p>
            <p>«Альфред»</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <strong>Сокращенное наименование</strong>
            </p>
          </td>
          <td>
            <p>ООО «Альфред»</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <strong>Адрес юридического лица</strong>
            </p>
          </td>
          <td>
            <p>123242,</p>
            <p>г. Москва,</p>
            <p>вн.тер.г. муниципальный округ</p>
            <p>Пресненский,</p>
            <p>ул. Большая Грузинская,</p>
            <p>д. 20,</p>
            <p>помещ. 3А/П</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <strong>ОГРН</strong>
            </p>
          </td>
          <td>
            <p>1247700663760</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <strong>ИНН</strong>
            </p>
          </td>
          <td>
            <p>9703192670</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <strong>КПП</strong>
            </p>
          </td>
          <td>
            <p>770301001</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <strong>Генеральный директор</strong>
            </p>
          </td>
          <td>
            <p>Сергеева Анна Владимировна</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <strong>Основной вид деятельности</strong>
            </p>
          </td>
          <td>
            <p>Деятельность, связанная с</p>
            <p>использованием вычислительной техники и</p>
            <p>информационных технологий, прочая (62.09)</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <strong>Адрес электронной почты</strong>
            </p>
          </td>
          <td>
            <p>mail@alfred.market</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <strong>Банковские реквизиты:</strong>
            </p>
          </td>
          <td>
            <p>Филиал "Корпоративный" ПАО "Совкомбанк"</p>
            <p>БИК 044525360</p>
            <p>ИНН 4401116480</p>
            <p>Расчетный счет, </p>
            <p>№ 40702810512010667595</p>
          </td>
        </tr>
      </table>
    </DocumentModal>
  )
}
