import React, { useMemo, useState } from 'react'
import {
  EDocumentType,
  OrderDocumentFragment,
  useRemoveDocumentMutation,
} from 'api/graphql'
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Grid,
  GridProps,
  HStack,
  IconButton,
  Stack,
} from '@chakra-ui/react'
import { isEmpty, orderBy, truncate } from 'lodash'
import { UploadZone } from '../components/Upload/UploadZone'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import download from 'downloadjs'
import { useCurrentUser } from 'state/useCurrentUser'
import { Icon } from '@chakra-ui/icons'
import { LuDownload, LuFileText } from 'react-icons/lu'
import moment from 'moment/moment'
import { documentLabel } from 'constants/documents'

export const Documents = () => {
  const { documentsData, orderId } = useOrderData()
  const { refetchOrder } = useRefetchOrder()
  const [removeDocumentMutation] = useRemoveDocumentMutation()
  const { isControl } = useCurrentUser()

  const documents = useMemo(
    () => orderBy(documentsData, ['createdAt'], ['desc']),
    [documentsData]
  )
  const [selected, setSelected] = useState<string[]>([])
  const isEmptySelected = isEmpty(selected)
  const [removeLoading, setRemoveLoading] = useState(false)

  const filesSelected = useMemo(
    () => selected.map((id) => documentsData?.find((item) => item.id === id)),
    [selected, documentsData]
  )

  const handleDownloadAll = () => {
    if (!documentsData) return
    for (const item of documentsData) {
      download(`/api/files/order/${orderId}/documents/${item.id}`)
    }
  }

  const handleDownloadSelected = () => {
    if (!filesSelected) return
    for (const item of filesSelected) {
      download(`/api/files/order/${orderId}/documents/${item?.id}`)
    }
  }

  const handleRemoveSelected = async () => {
    try {
      if (!filesSelected) return
      setRemoveLoading(true)
      for await (const item of filesSelected) {
        await removeDocumentMutation({
          variables: {
            orderId,
            documentId: item?.id,
          },
        })
      }
      await refetchOrder()
      setSelected([])
    } catch (e) {
    } finally {
      setRemoveLoading(false)
    }
  }

  const handleCheckboxClick = (id: string) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id))
    } else {
      setSelected([...selected, id])
    }
  }

  if (!documents) return null

  return (
    <Stack spacing="28px">
      <UploadZone documentType="Other" />
      <Stack spacing="14px">
        <Flex fontSize="14px">
          {isEmptySelected ? (
            `Всего файлов: ${documentsData?.length}`
          ) : (
            <HStack spacing="14px">
              <Box>Выбрано {selected.length}</Box>
              <Button
                size="sm"
                width="fit-content"
                variant="linkBlue"
                onClick={handleDownloadSelected}
              >
                Скачать
              </Button>
              <Button
                size="sm"
                width="fit-content"
                variant="linkBlue"
                onClick={() => setSelected([])}
              >
                Сбросить
              </Button>
              {isControl && (
                <Button
                  size="sm"
                  width="fit-content"
                  color="red.500"
                  variant="link"
                  fontWeight="normal"
                  isLoading={removeLoading}
                  onClick={handleRemoveSelected}
                >
                  Удалить
                </Button>
              )}
            </HStack>
          )}
        </Flex>
        <Stack spacing="16px">
          {documents?.map((document) => (
            <FileItem
              key={document.id}
              isActive={selected.includes(document.id)}
              orderId={orderId}
              data={document}
              onCheckboxClick={handleCheckboxClick}
            />
          ))}
        </Stack>
        {documentsData && documentsData?.length > 1 && (
          <Button onClick={handleDownloadAll}>Скачать все</Button>
        )}
      </Stack>
    </Stack>
  )
}

interface FileItemProps extends GridProps {
  data: OrderDocumentFragment
  orderId?: string | null | undefined
  isActive?: boolean
  onCheckboxClick?: (id: string) => void
}

export const FileItem: React.FC<FileItemProps> = ({
  orderId,
  data,
  isActive,
  onCheckboxClick,
  ...props
}) => {
  const handleDownload = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    if (!data.id || !orderId) return
    download(`/api/files/order/${orderId}/documents/${data.id}`)
  }

  const handleCheckboxClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    if (!data.id || !onCheckboxClick) return
    onCheckboxClick(data.id)
  }

  return (
    <Grid
      role="group"
      onClick={handleCheckboxClick}
      templateColumns="17px 1fr 24px"
      borderRadius="8px"
      gap="12px"
      backgroundColor="gray.100"
      alignItems="flex-start"
      padding="10px 16px 10px 10px"
      transition="0.2s"
      cursor="pointer"
      userSelect="none"
      _hover={{
        backgroundColor: 'gray.200',
      }}
      {...props}
    >
      <Center position="relative" boxSize="24px" onClick={handleCheckboxClick}>
        <Checkbox
          isChecked={isActive}
          position="absolute"
          backgroundColor="white"
          transition="0.2s"
          opacity={isActive ? 1 : 0}
          borderColor="gray.400"
          borderRadius="4px"
          colorScheme="purple"
          _groupHover={{
            opacity: 1,
          }}
        />
        <Icon
          as={LuFileText}
          boxSize="17px"
          transition="0.2s"
          opacity={isActive ? 0 : 1}
          _groupHover={{
            opacity: 0,
          }}
        />
      </Center>
      <Stack spacing="6px" lineHeight="1.2">
        <Box
          overflow="hidden"
          fontSize="1rem"
          color={data.fileName ? 'black' : 'gray.400'}
          css={{ textWrap: 'nowrap' }}
        >
          {data.fileName ? truncate(data.fileName, { length: 60 }) : 'Нет имени'}
        </Box>
        <HStack fontSize="0.8rem">
          <Box color="gray.600">
            {documentLabel[data.documentType as EDocumentType] ?? 'Другое'}
          </Box>
          <Box color="gray.500">{moment(data.createdAt).format('DD.MM.YYYY HH:mm')}</Box>
        </HStack>
      </Stack>
      <Center height="100%">
        <IconButton
          isRound
          aria-label="Download"
          size="sm"
          width="28px"
          minW="28px"
          height="28px"
          variant="ghost"
          icon={<Icon as={LuDownload}/>}
          onClick={handleDownload}
          _hover={{
            backgroundColor: 'purple.500',
            color: 'white'
          }}
          _active={{
            backgroundColor: 'purple.600',
            color: 'white'
          }}
        />
      </Center>
    </Grid>
  )
}
