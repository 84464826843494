import { useNavigate, useParams } from 'react-router-dom'
import React from 'react'
import { UserEntity } from 'shared/entities/UserEntity'

export const Item = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  return (
    <UserEntity id={id} onBack={() => navigate('/control/users')} />
  )
}
