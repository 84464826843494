import { Box, Button, HStack, Stack } from '@chakra-ui/react'
import { Field, Formik, FormikValues } from 'formik'
import { InputGroupForm } from 'uikit/InputGroup'
import React from 'react'
import * as Yup from 'yup'

interface CompanyFormProps {
  initialValues: FormikValues
  isLoading: boolean
  onSubmit: (values: FormikValues) => Promise<void>
}

export const CompanyForm: React.FC<CompanyFormProps> = ({ isLoading, initialValues, onSubmit }) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={formSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <Stack spacing="28px">
            <Stack spacing="18px">
              <Field
                name="name"
                label="Название компании"
                placeholder={`ООО "Новая мечта"`}
                component={InputGroupForm}
                isDisabled={isLoading}
              />
              <HStack spacing="14px" alignItems="flex-start">
                <Field
                  name="inn"
                  label="ИНН"
                  component={InputGroupForm}
                  isDisabled={isLoading}
                />
                <Field
                  name="kpp"
                  label="КПП"
                  component={InputGroupForm}
                  isDisabled={isLoading}
                />
              </HStack>
              <Field
                name="legalAddress"
                label="Юридичесий адрес компании"
                component={InputGroupForm}
                isDisabled={isLoading}
              />
              <Field
                name="actualAddress"
                label="Фактический адрес компании"
                component={InputGroupForm}
                isDisabled={isLoading}
              />
              <Field
                name="ceo"
                label="Генеральный директор (или подписант по доверенности)"
                component={InputGroupForm}
                isDisabled={isLoading}
              />
              <Field
                name="bic"
                label="БИК банка"
                component={InputGroupForm}
                isDisabled={isLoading}
              />
              <Field
                name="bank"
                label="Название банка"
                component={InputGroupForm}
                isDisabled={isLoading}
              />
              <HStack spacing="14px" alignItems="flex-start">
                <Field
                  name="checkingAccount"
                  label="Р\с"
                  component={InputGroupForm}
                  isDisabled={isLoading}
                />
                <Field
                  name="correspondentAccount"
                  label="Корр. счет"
                  component={InputGroupForm}
                  isDisabled={isLoading}
                />
              </HStack>
            </Stack>
            <Box>
              <Button isLoading={isLoading} type="submit" variant="primary">
                Сохранить
              </Button>
            </Box>
          </Stack>
        </form>
      )}
    </Formik>
  )
}

const formSchema = Yup.object().shape({
  name: Yup.string().required('Введите имя'),
  inn: Yup.string().matches(/^\d{10}$/, 'ИНН должен содержать 10 цифр').required('Введите ИНН'),
  kpp: Yup.string().matches(/^\d{9}$/, 'КПП должен содержать 9 цифр').required('Введите КПП'),
  legalAddress: Yup.string().required('Введите юридический адрес'),
  actualAddress: Yup.string().required('Введите фактический адрес'),
  bic: Yup.string().matches(/^\d{9}$/, 'БИК должен содержать 9 цифр').required('Введите БИК'),
  bank: Yup.string().required('Введите название банка'),
  checkingAccount: Yup.string().matches(/^\d{20}$/, 'Р/с должен содержать 20 цифр').required('Введите расчетный счет'),
  correspondentAccount: Yup.string().matches(/^\d{20}$/, 'Корр. счет должен содержать 20 цифр').required('Введите корр. счет')
})
