import { Icon } from '@chakra-ui/icons'
import { Center, CenterProps, IconProps } from '@chakra-ui/react'
import React from 'react'
import { AnimDot } from 'uikit/AnimDot'

interface Props extends CenterProps {
  icon: any
  iconProps?: IconProps
  isDot?: boolean
}

export const ItemIcon: React.FC<Props> = ({ icon, iconProps, isDot, ...props }) => {
  return (
    <Center boxSize="18px" position="relative" {...props}>
      <Icon as={icon} width="16px" {...iconProps} />
      {isDot && (
        <AnimDot
          position="absolute"
          top="1px"
          right="1px"
        />
      )}
    </Center>
  )
}
