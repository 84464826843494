import { Navigate, Route, Routes } from 'react-router-dom'
import { Companies } from './routes/Companies'
import { Orders } from './routes/Orders'
import { Control } from './routes/Control'
import { Landing } from './routes/Landing'
import { useCurrentUser } from '../../state/useCurrentUser'
import { Layout } from '../../shared/layouts/Layouts'
import { Center, Spinner } from '@chakra-ui/react'
import { useEffect } from 'react'

export const Main = () => {
  const { isControl } = useCurrentUser()

  return (
    <Routes>
      <Route path="/">
        <Route index element={<Landing/>} />
        <Route path="enter" element={<Enter />} />
        {!isControl && (
          <>
            <Route path="orders/*" element={<Orders />} />
            <Route path="companies/*" element={<Companies />} />
          </>
        )}
        <Route path="control/*" element={<Control />} />
      </Route>
    </Routes>
  )
}

const Enter = () => {
  const { isAuthenticated, isControl, isCalled, isLoading, getCurrentUser } = useCurrentUser()

  useEffect(() => {
    getCurrentUser(true)
  }, [])

  if (!isCalled || isLoading) {
    return (
      <Layout>
        <Center flex={1}>
          <Spinner boxSize="18px"/>
        </Center>
      </Layout>
    )
  }

  if (isAuthenticated) {
    return isControl ? <Navigate replace to="/control/orders" /> : <Navigate replace to="/orders" />
  }

  return (
    <Layout>
      <Center flex={1}>
        Переадресация...
      </Center>
    </Layout>
  )
}
