import { Box, Button, Divider, Stack } from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import {
  EDocumentType,
  useManagerRequestDocumentsMutation,
} from 'api/graphql'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { TextArea } from 'uikit/TextArea'
import { isEmptyString } from 'utils/isEmptyString'
import { StepWrapper } from '../../../elements/StepWrapper'
import { FormLabel } from 'uikit/FormLabel'
import { UploadButton } from '../../Upload/UploadButton'
import { isEmpty } from 'lodash'
import { FileItem } from '../../../elements/FileItem'

export const DocumentsRequestedStep = () => {
  const { orderId, documentsData } = useOrderData()
  const [managerRequestDocumentsMutation, { loading: isLoading }] =
    useManagerRequestDocumentsMutation()
  const [value, setValue] = useState('')
  const [isDraftSelect, setIsDraftSelect] = useState(false)
  const { refetchOrder } = useRefetchOrder()

  const draftDocuments = useMemo(
    () =>
      documentsData?.filter(
        (document) => document.documentType === EDocumentType.Draft
      ),
    [documentsData]
  )

  const handleClick = async () => {
    if (!orderId) return
    await managerRequestDocumentsMutation({
      variables: {
        id: orderId,
        comment: value,
      },
    })
    await refetchOrder()
  }

  return (
    <StepWrapper description="Напишите, какие документы необходимо подготовить пользователю">
      <TextArea
        name="comment"
        placeholder="Введите список документов"
        minHeight="240px"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Button
        width="fit-content"
        isLoading={isLoading}
        isDisabled={isEmptyString(value)}
        onClick={handleClick}
        variant="primary"
      >
        Отправить
      </Button>
    </StepWrapper>
  )

 /* return (
    <StepWrapper description="Напишите, какие документы необходимо подготовить пользователю">
      <TextArea
        name="comment"
        placeholder="Введите список документов"
        minHeight="240px"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {!isEmpty(draftDocuments) ? (
        <Box>
          <FormLabel>
            Загруженные драфты
          </FormLabel>
          <Stack spacing="10px">
            {draftDocuments?.map((document) => (
              <FileItem
                key={document.id}
                id={document.id}
                name={document?.fileName}
                orderId={orderId}
                cursor="pointer"
                backgroundColor="white"
                _hover={{
                  backgroundColor: 'gray.200',
                }}
                _active={{
                  backgroundColor: 'gray.300',
                }}
              />
            ))}
          </Stack>
        </Box>
      ) : (
        <Box>
          <FormLabel>
            Драфт для заполнения
          </FormLabel>
          <UploadButton
            documentType="Draft"
            selectLabelButton="Выбрать драфт"
            uploadLabelButton="Загрузить"
            onSelected={(files) => setIsDraftSelect(!isEmpty(files))}
            onFinish={() => setIsDraftSelect(false)}
          />
        </Box>
      )}
      {!isDraftSelect && (
        <>
          <Divider borderColor="gray.300" />
          <Button
            width="fit-content"
            isLoading={isLoading}
            isDisabled={isEmptyString(value)}
            onClick={handleClick}
            variant="primary"
          >
            Отправить
          </Button>
        </>
      )}
    </StepWrapper>
  )*/
}
