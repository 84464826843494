import { Alert, Box, Stack } from '@chakra-ui/react'
import { CloudContainer } from './CloudContainer'
import React, { ReactNode } from 'react'
import { isEmptyString } from 'utils/isEmptyString'

interface StepProps {
  description?: string | null | ReactNode
  userComment?: string | null
  children?: ReactNode
}

export const StepWrapper: React.FC<StepProps> = ({
  description,
  userComment,
  children,
}) => {
  return (
    <Stack spacing="14px">
      {(description || children) && (
        <CloudContainer>
          <Stack spacing="18px">
            {description && (
              <>
                {typeof description === 'string' ? (
                  <Box whiteSpace="pre-line">{description}</Box>
                ) : (
                  <Box>{description}</Box>
                )}
              </>
            )}
            {children && (
              <Stack spacing="18px">{children}</Stack>
            )}
          </Stack>
        </CloudContainer>
      )}
      {!isEmptyString(userComment) && (
        <CloudContainer title="Комментарий пользователя">
          <Alert
            backgroundColor="white"
            borderRadius="5px"
            status="warning"
            variant="left-accent"
          >
            {userComment}
          </Alert>
        </CloudContainer>
      )}
    </Stack>
  )
}
