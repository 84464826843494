import { useAtom } from 'jotai'
import { currentUserAtom } from './atoms'
import { useCallback } from 'react'
import { EUserRole, useCurrentUserLazyQuery, useLogoutMutation } from '../api/graphql'
import { useNavigate } from 'react-router-dom'

export const useCurrentUser = () => {
  const navigate = useNavigate()
  const [currentUserLazyQuery, { called, loading }] = useCurrentUserLazyQuery()
  const [logoutMutation] = useLogoutMutation()
  const [currentUser, setCurrentUser] = useAtom(currentUserAtom)

  const isAuthenticated = !!currentUser
  const isAdmin = currentUser?.role === EUserRole.Admin
  const isManager = currentUser?.role === EUserRole.Manager
  const isControl = isAdmin || isManager

  const getCurrentUser = useCallback(async (redirect?: boolean) => {
    await currentUserLazyQuery({
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setCurrentUser(data.currentUser)
      },
      onError: (e) => {
        redirect && navigate('/login', { replace: true })
      },
    })
  }, [])

  const logout = useCallback(async () => {
    await logoutMutation({
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setCurrentUser(null)
      },
    })
  }, [])

  return {
    isAuthenticated,
    isCalled: called,
    isLoading: loading,
    isAdmin,
    isManager,
    isControl,
    currentUser,
    logout,
    getCurrentUser,
  }
}
