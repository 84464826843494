import { Box, Flex, FlexProps, Stack } from '@chakra-ui/react'
import React, { ReactNode } from 'react'

interface ActionContainerProps extends FlexProps {
  title?: string
  children: ReactNode
}

export const CloudContainer: React.FC<ActionContainerProps> = ({ title, children, ...props }) => {
  return (
    <Flex
      flexDirection="column"
      borderRadius="14px"
      padding="28px"
      backgroundColor="gray.100"
      {...props}
    >
      <Stack spacing="18px">
        {title && <Box fontWeight={700} fontSize="0.9rem">{title}</Box>}
        <Box>{children}</Box>
      </Stack>
    </Flex>
  )
}
