import React, { ForwardedRef } from 'react'
import { Box, Center, Text } from '@chakra-ui/react'
import { SkeletonRow } from '../../shared/rows/SkeletonRow'

type StaticListComponent = <D extends ListItem>(
  props: StaticListProps<D>,
  ref: ForwardedRef<D>
) => JSX.Element | null

export const StaticList: StaticListComponent = ({
  data,
  children,
  noDataText,
  noDataRender,
  wrapper,
  isLoading,
  ...props
}) => {
  if (isLoading && !data?.length) {
    return (
      <Box key="loader">
        <SkeletonRow opacity={0.4} />
        <SkeletonRow opacity={0.4} />
        <SkeletonRow opacity={0.3} />
        <SkeletonRow opacity={0.2} />
        <SkeletonRow opacity={0.1} />
      </Box>
    )
  }

  if (data && data.length) {
    const item = data.map((item, index) => children(item, index, data))

    return (
      <Box>
        {wrapper ? wrapper(item) : item}
      </Box>
    )
  }

  if (data && !data.length) {
    return (
      <Center minH="200px" mb="40px" flex={1}>
        {noDataRender || <Text color="gray.300">{noDataText || 'Нет данных'}</Text>}
      </Center>
    )
  }

  return null
}

export type ListItem = {
  id: string
  [k: string]: any
}

export interface StaticListProps<D extends ListItem>{
  data?: D[] | null
  children: (item: D, index: number, arr: D[]) => React.ReactNode
  noDataText?: string
  noDataRender?: React.ReactNode
  isLoading?: boolean
  wrapper?: (items: React.ReactNode) => React.ReactNode
}
