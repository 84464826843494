import { EOrderStatus, ItemOrderFragment, OrderFragment } from 'api/graphql'
import { useCurrentUser } from 'state/useCurrentUser'
import { useMemo } from 'react'

export const useOrderNotification = (orderData: OrderFragment | ItemOrderFragment) => {
  const { isControl } = useCurrentUser()
  const controlStatuses = [
    //EOrderStatus.Declined,
    //EOrderStatus.NeedContractCorrection,
    //EOrderStatus.PaymentToSupplierFailed,
    EOrderStatus.AcceptedForProcessing,
    EOrderStatus.ContractPreparation,
    EOrderStatus.PayerApproving,
    EOrderStatus.EdiContractWaiting,
    EOrderStatus.ContractSigned,
    EOrderStatus.DocumentsVerification,
    EOrderStatus.NeedDealCorrection,
    EOrderStatus.DealApproved,
    EOrderStatus.RateApproving,
    EOrderStatus.RateApproved,
    EOrderStatus.PaymentVerification,
    EOrderStatus.PaymentToSupplier,
    EOrderStatus.ActVerification,
  ]

  const userStatuses = [
    EOrderStatus.Created,
    EOrderStatus.ProposalApproval,
    EOrderStatus.ContractCoordination,
    EOrderStatus.PayerCoordination,
    EOrderStatus.ContractSigning,
    EOrderStatus.DocumentsRequested,
    EOrderStatus.DealCoordination,
    EOrderStatus.InvoicePayment,
    EOrderStatus.RateCoordination,
    EOrderStatus.PaymentToSupplier,
    EOrderStatus.PaymentVerification,
  ]

  const isShowDot = useMemo(() => {
    if (isControl) {
      return controlStatuses.includes(orderData.status)
    }
    return userStatuses.includes(orderData.status)
  }, [orderData, isControl, controlStatuses, userStatuses])

  return {
    isShowDot
  }
}
