import { Box, Button, Divider, HStack, Stack } from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import {
  EDocumentType,
  useUserApprovePayerMutation,
  useUserDeclinePayerMutation,
} from 'api/graphql'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { TextArea } from 'uikit/TextArea'
import { isEmptyString } from 'utils/isEmptyString'
import { StepWrapper } from '../../../elements/StepWrapper'
import { isEmpty } from 'lodash'
import { FormLabel } from '../../../../../../uikit/FormLabel'
import { FileItem } from '../../../elements/FileItem'

export const PayerCoordinationStep = () => {
  const { orderId, orderData, documentsData } = useOrderData()
  const [userApprovePayerMutation, { loading: isAcceptLoading }] = useUserApprovePayerMutation()
  const [userDeclinePayerMutation, { loading: isDeclineLoading }] = useUserDeclinePayerMutation()
  const { refetchOrder } = useRefetchOrder()

  const [value, setValue] = useState('')
  const [isDecline, setIsDecline] = useState(false)

  const documents = useMemo(
    () =>
      documentsData?.filter(
        (document) => document.documentType === EDocumentType.PayerDetails
      ),
    [documentsData]
  )

  const handleAccept = async () => {
    if (!orderId) return
    await userApprovePayerMutation({
      variables: {
        id: orderId,
      }
    })
    await refetchOrder()
  }

  const handleDecline = async () => {
    if (!value || !orderId) return
    await userDeclinePayerMutation({
      variables: {
        id: orderId,
        comment: value
      }
    })
    await refetchOrder()
  }

  return (
    <StepWrapper
      description="Согласуйте плательщика с поставщиком"
    >
      {!isEmptyString(orderData?.comment) && (
        <Box
          padding="8px 16px"
          backgroundColor="white"
          borderRadius="0.5rem"
          whiteSpace="pre-line"
          minH="100px"
        >
          {orderData?.comment}
        </Box>
      )}
      {!isEmpty(documents) && (
        <>
          <Box>
            <FormLabel>
              Документы
            </FormLabel>
            <Stack spacing="10px">
              {documents?.map((document) => (
                <FileItem
                  key={document.id}
                  id={document.id}
                  name={document?.fileName}
                  orderId={orderId}
                  cursor="pointer"
                  backgroundColor="white"
                  _hover={{
                    backgroundColor: 'gray.200',
                  }}
                  _active={{
                    backgroundColor: 'gray.300',
                  }}
                />
              ))}
            </Stack>
          </Box>
        </>
      )}
      {!isDecline ? (
        <HStack spacing="16px">
          <Button isLoading={isAcceptLoading} onClick={handleAccept} variant="primary">
            Согласовать
          </Button>
          <Button isDisabled={isAcceptLoading} onClick={() => setIsDecline(true)} variant="secondary">
            Нужен другой плательщик
          </Button>
        </HStack>
      ) : (
        <>
          <TextArea
            autoFocus
            name="comment"
            value={value}
            placeholder="Напишите, что не подошло"
            minHeight="100px"
            onChange={(e) => setValue(e.target.value)}
          />
          <HStack spacing="16px">
            <Button isDisabled={isEmptyString(value)} isLoading={isDeclineLoading} onClick={handleDecline} variant="attention">
              Отправить
            </Button>
            <Button isDisabled={isDeclineLoading} onClick={() => setIsDecline(false)} variant="secondary">
              Отменить
            </Button>
          </HStack>
        </>
      )}
    </StepWrapper>
  )
}
