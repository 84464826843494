import { EOrderStatus } from 'api/graphql'
import React from 'react'
import { useOrderData } from 'state/useOrder'
import { WaitingStep } from '../StepUser/steps/WaitingStep'
import { ChoiceManagerStep } from './steps/ChoiceManagerStep'
import { ProposalStep } from './steps/ProposalStep'
import { ContactStep } from './steps/ContactStep'
import { EdiContactWaitingStep } from './steps/EdiContactWaitingStep'
import { DocumentsRequestedStep } from './steps/DocumentsRequestedStep'
import { DocumentsVerificationStep } from './steps/DocumentsVerificationStep'
import { NeedDealCorrectionStep } from './steps/NeedDealCorrectionStep'
import { InvoicePaymentStep } from './steps/InvoicePaymentStep'
import { DeclinedStep } from './steps/DeclinedStep'
import { NeedContractCorrectionStep } from './steps/NeedContractCorrectionStep'
import { PaymentVerificationStep } from './steps/PaymentVerificationStep'
import { PaymentToSupplerStep } from './steps/PaymentToSupplerStep'
import { AktVerificationStep } from './steps/AktVerificationStep'
import { FinishedStep } from './steps/FinishedStep'
import { PaymentToSupplierFailedStep } from './steps/PaymentToSupplierFailedStep'
import { PayerApprovingStep } from './steps/PayerApprovingStep'
import { RateApprovingStep } from './steps/RateApprovingStep'

export const StepControl = () => {
  const { orderData, documentsData } = useOrderData()

  const status = orderData?.status

  if (status === EOrderStatus.Declined) {
    return <DeclinedStep />
  }

  if (status === EOrderStatus.NeedContractCorrection) {
    return <NeedContractCorrectionStep />
  }

  if (status === EOrderStatus.PaymentToSupplierFailed) {
    return <PaymentToSupplierFailedStep />
  }

  if (status === EOrderStatus.AcceptedForProcessing) {
    if (orderData?.managerUserId) {
      return <ProposalStep />
    }
    return <ChoiceManagerStep />
  }
  if (status === EOrderStatus.ContractPreparation) {
    return <ContactStep />
  }
  if (status === EOrderStatus.EdiContractWaiting) {
    return <EdiContactWaitingStep />
  }
  if (status === EOrderStatus.PayerApproving) {
    return <PayerApprovingStep />
  }
  if (status === EOrderStatus.ContractSigned) {
    return <DocumentsRequestedStep />
  }
  if (status === EOrderStatus.DocumentsVerification) {
    return <DocumentsVerificationStep />
  }
  if (status === EOrderStatus.NeedDealCorrection) {
    return <NeedDealCorrectionStep />
  }
  if (status === EOrderStatus.RateApproving) {
    return <RateApprovingStep />
  }
  if (status === EOrderStatus.RateApproved) {
    return <InvoicePaymentStep />
  }
  if (status === EOrderStatus.PaymentVerification) {
    return <PaymentVerificationStep />
  }
  if (status === EOrderStatus.PaymentToSupplier) {
    return <PaymentToSupplerStep />
  }
  if (status === EOrderStatus.ActVerification) {
    return <AktVerificationStep />
  }
  if (status === EOrderStatus.Finished) {
    return <FinishedStep />
  }

  return <WaitingStep />
}
