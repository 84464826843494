import { EOrderStatus } from 'api/graphql'

export const orderStatusLabel = {
  [EOrderStatus.Created]: 'Создано',
  [EOrderStatus.AcceptedForProcessing]: 'Принято в обработку',
  [EOrderStatus.ProposalApproval]: 'Утверждение предложения',
  [EOrderStatus.Declined]: 'Отклонено',
  [EOrderStatus.ContractPreparation]: 'Подготовка договора',
  [EOrderStatus.ContractCoordination]: 'Согласование Договора-оферты',
  [EOrderStatus.NeedContractCorrection]: 'Необходима корректировка договора',
  [EOrderStatus.EdiContractWaiting]: 'Ожидание подписания ЭДО договора',
  [EOrderStatus.ContractSigned]: 'Договор-оферта подписан',
  [EOrderStatus.ContractSigning]: 'Договор-оферта на подписании',
  [EOrderStatus.DocumentsRequested]: 'Запрошены документы',
  [EOrderStatus.DocumentsVerification]: 'Проверка документов',
  [EOrderStatus.DealCoordination]: 'Согласование сделки',
  [EOrderStatus.NeedDealCorrection]: 'Необходима корректировка сделки',
  [EOrderStatus.PayerApproving]: 'Необходимо согласование плательщика',
  [EOrderStatus.PayerCoordination]: 'Согласование плательщика',
  [EOrderStatus.DealApproved]: 'Сделка согласована',
  [EOrderStatus.InvoicePayment]: 'Оплата счета',
  [EOrderStatus.PaymentVerification]: 'Проверка оплаты',
  [EOrderStatus.PaymentToSupplier]: 'Оплата инвойса. Ожидайте SWIFT',
  [EOrderStatus.PaymentToSupplierConfirmed]: 'SWIFT оплачен. Согласование Акта выполненных услуг',
  [EOrderStatus.PaymentToSupplierFailed]: 'SWIFT получен, проверка дополнительной информации, поступившей от Клиента',
  [EOrderStatus.RateApproved]: 'Курс согласован',
  [EOrderStatus.RateApproving]: 'Согласование курса',
  [EOrderStatus.RateCoordination]: 'Согласование курса',
  [EOrderStatus.ActVerification]: 'Проверка актов',
  [EOrderStatus.Finished]: 'Завершено',
}

export const orderStatusColor = {
  [EOrderStatus.Created]: 'gray',
  [EOrderStatus.AcceptedForProcessing]: 'blue',
  [EOrderStatus.ProposalApproval]: 'green',
  [EOrderStatus.Declined]: 'red',
  [EOrderStatus.ContractPreparation]: 'purple',
  [EOrderStatus.ContractCoordination]: 'purple',
  [EOrderStatus.NeedContractCorrection]: 'red',
  [EOrderStatus.EdiContractWaiting]: 'green',
  [EOrderStatus.ContractSigned]: 'purple',
  [EOrderStatus.ContractSigning]: 'green',
  [EOrderStatus.DocumentsRequested]: 'green',
  [EOrderStatus.DocumentsVerification]: 'green',
  [EOrderStatus.DealCoordination]: 'green',
  [EOrderStatus.NeedDealCorrection]: 'green',
  [EOrderStatus.DealApproved]: 'green',
  [EOrderStatus.PayerApproving]: 'blue',
  [EOrderStatus.PayerCoordination]: 'purple',
  [EOrderStatus.InvoicePayment]: 'green',
  [EOrderStatus.PaymentVerification]: 'green',
  [EOrderStatus.PaymentToSupplier]: 'green',
  [EOrderStatus.PaymentToSupplierConfirmed]: 'green',
  [EOrderStatus.PaymentToSupplierFailed]: 'red',
  [EOrderStatus.RateApproved]: 'green',
  [EOrderStatus.RateApproving]: 'blue',
  [EOrderStatus.RateCoordination]: 'purple',
  [EOrderStatus.ActVerification]: 'green',
  [EOrderStatus.Finished]: 'green',
}

export const orderStatusProgress = {
  [EOrderStatus.Created]: 10,
  [EOrderStatus.AcceptedForProcessing]: 20,
  [EOrderStatus.ProposalApproval]: 30,
  [EOrderStatus.Declined]: 30,
  [EOrderStatus.ContractPreparation]: 40,
  [EOrderStatus.ContractCoordination]: 50,
  [EOrderStatus.NeedContractCorrection]: 50,
  [EOrderStatus.EdiContractWaiting]: 60,
  [EOrderStatus.ContractSigned]: 70,
  [EOrderStatus.ContractSigning]: 80,
  [EOrderStatus.DocumentsRequested]: 90,
  [EOrderStatus.DocumentsVerification]: 100,
  [EOrderStatus.DealCoordination]: 110,
  [EOrderStatus.NeedDealCorrection]: 110,
  [EOrderStatus.DealApproved]: 120,
  [EOrderStatus.PayerApproving]: 120,
  [EOrderStatus.PayerCoordination]: 120,
  [EOrderStatus.InvoicePayment]: 130,
  [EOrderStatus.PaymentVerification]: 140,
  [EOrderStatus.PaymentToSupplier]: 150,
  [EOrderStatus.PaymentToSupplierConfirmed]: 160,
  [EOrderStatus.PaymentToSupplierFailed]: 150,
  [EOrderStatus.RateApproved]: 150,
  [EOrderStatus.RateApproving]: 150,
  [EOrderStatus.RateCoordination]: 150,
  [EOrderStatus.ActVerification]: 170,
  [EOrderStatus.Finished]: 200,
}
