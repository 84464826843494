import { Grid, Skeleton } from '@chakra-ui/react'
import React from 'react'

interface SkeletonRowProps {
  opacity: number
}

export const SkeletonRow: React.FC<SkeletonRowProps> = ({ opacity }) => {
  return (
    <Grid
      alignItems="center"
      px="20px"
      height="54px"
      gap="16px"
      templateColumns="20px 1fr"
      width="100%"
      borderBottomWidth={1}
      borderColor="gray.50"
    >
      <Skeleton height="20px" width="20px" borderRadius="4px" opacity={opacity} />
      <Skeleton
        height="20px"
        width="100%"
        borderRadius="4px"
        opacity={opacity}
      />
    </Grid>
  )
}
