import React, { ReactNode } from 'react'
import { Box, Flex, IconButton, Stack } from '@chakra-ui/react'
import { Icon } from '@chakra-ui/icons'
import { ChevronLeftIcon } from '@chakra-ui/icons'

interface EntityFormProps {
  header?: ReactNode
  description?: ReactNode
  onBack?: () => void
  children: ReactNode
}

export const Entity: React.FC<EntityFormProps> = ({
  header,
  description,
  children,
  onBack,
}) => {
  return (
    <Flex flexDirection="column">
      {onBack && (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          height="54px"
          px="20px"
          position="sticky"
          top={0}
          //borderBottomWidth={1}
          //borderColor="gray.100"
        >
          <IconButton
            aria-label="Button"
            size="sm"
            variant="secondary"
            width="30px"
            height="30px"
            minW="30px"
            icon={<Icon as={ChevronLeftIcon} boxSize="18px" />}
            onClick={onBack}
          />
        </Flex>
      )}
      <Flex justifyContent="center" flex={1} padding={onBack ? '0 20px 20px 20px' : '20px'}>
        <Stack flexDirection="column" maxW="640px" width="100%" pb="30px" spacing="24px">
          {(header || description) && (
            <Stack>
              {header && (
                <Box fontSize="1.6rem" fontWeight="700">
                  {header}
                </Box>
              )}
              {description && (
                <Box lineHeight="1.4" color="gray.600">
                  {description}
                </Box>
              )}
            </Stack>
          )}
          <Flex flexDirection="column">{children}</Flex>
        </Stack>
      </Flex>
    </Flex>
  )
}
