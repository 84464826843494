import { Grid, GridProps } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

interface EntityListRowProps extends GridProps {
  to: string
}

export const EntityListRow: React.FC<EntityListRowProps> = ({ to, children, ...props }) => {
  return (
    <Grid
      as={Link}
      to={to}
      gap="16px"
      px="20px"
      height="54px"
      alignItems="center"
      transition="0.2s"
      cursor="pointer"
      borderBottomWidth={1}
      borderColor="gray.100"
      _hover={{
        backgroundColor: 'gray.50',
      }}
      _active={{
        backgroundColor: 'gray.100'
      }}
      {...props}
    >
      {children}
    </Grid>
  )
}
