import { Button, Flex } from '@chakra-ui/react'
import { EntityHeader } from 'components/Entity/EntityHeader'
import { useNavigate } from 'react-router-dom'
import { Icon } from '@chakra-ui/icons'
import { LuPlus } from 'react-icons/lu'
import { useUsers } from 'api/user/user.services'
import { InfiniteList } from 'uikit/InfiniteList'
import { UserRow } from 'shared/rows/UserRow'
import { useCurrentUser } from 'state/useCurrentUser'

export const List = () => {
  const navigate = useNavigate()
  const { isAdmin } = useCurrentUser()
  const { data, next, isLoading, hasNextPage } = useUsers()

  return (
    <Flex flexDirection="column" flex={1}>
      <EntityHeader
        {...isAdmin && (
          { rightElement: (
            <Button leftIcon={<Icon as={LuPlus}/>} variant="secondary" onClick={() => navigate('create')} size="sm">
              Новый пользователь
            </Button>
          )}
        )}
      >
        Пользователи
      </EntityHeader>
      <InfiniteList
        data={data}
        next={next}
        isLoading={isLoading}
        hasMore={hasNextPage}
      >
        {(item) => <UserRow key={item.id} data={item} />}
      </InfiniteList>
    </Flex>
  )
}
