import { Button } from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import { useManagersQuery, useOrderSetManagerMutation } from 'api/graphql'
import { Select } from 'uikit/Select'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { StepWrapper } from '../../../elements/StepWrapper'

export const ChoiceManagerStep = () => {
  const { data: usersData, loading: isManagersLoading } = useManagersQuery({
    fetchPolicy: 'network-only'
  })
  const [orderSetManagerMutation, { loading }] = useOrderSetManagerMutation()
  const { orderId, orderData } = useOrderData()
  const [value, setValue] = useState<string | null>(null)
  const { refetchOrder } = useRefetchOrder()

  const options = useMemo(() => {
    return usersData?.users?.edges?.map(({ node }) => ({
      value: node.id,
      label: node.name,
    }))
  }, [usersData])

  const valueOption = options?.find((option) => option.value === value)

  const handleClick = async () => {
    if (!value || !orderId) return
    await orderSetManagerMutation({
      variables: {
        id: orderId,
        managerUserId: value
      }
    })
    await refetchOrder()
  }

  return (
    <StepWrapper
      description="Выберите менеджера, который будет отвечать за выполнение заявки."
      userComment={orderData?.comment}
    >
      <Select
        isLoading={isManagersLoading}
        options={options}
        placeholder="Менеджер"
        onChange={(e) => setValue(e?.value)}
        value={valueOption}
      />
      <Button
        isDisabled={!value}
        width="fit-content"
        isLoading={loading}
        onClick={handleClick}
        variant="primary"
      >
        Назначить
      </Button>
    </StepWrapper>
  )
}
