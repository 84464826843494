import { Box, Button, Divider, HStack, Stack } from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import {
  EDocumentType,
  useManagerNeedDocumentsCorrectionMutation,
  useManagerSetDealDateMutation,
} from 'api/graphql'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { isEmptyString } from 'utils/isEmptyString'
import { StepWrapper } from '../../../elements/StepWrapper'
import { FileItem } from '../../../elements/FileItem'
import { TextArea } from 'uikit/TextArea'
import { InputMaskForm } from 'uikit/InputMask'
import { Field, Formik, FormikValues } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'
import { FormLabel } from 'uikit/FormLabel'
import { isEmpty } from 'lodash'

export const DocumentsVerificationStep = () => {
  const { orderId, documentsData } = useOrderData()
  const [isNeedCorrection, setNeedCorrection] = useState(false)
  const [isAccept, setIsAccept] = useState(false)
  const { refetchOrder } = useRefetchOrder()

  const [
    managerNeedDocumentsCorrectionMutation,
    { loading: isNeedCorrectionLoading },
  ] = useManagerNeedDocumentsCorrectionMutation()
  const [managerSetDealDateMutation, { loading: isSetDateLoading }] =
    useManagerSetDealDateMutation()
  const [correctionValue, setCorrectionValue] = useState('')

  const documents = useMemo(
    () =>
      documentsData?.filter(
        (document) => document.documentType === EDocumentType.DealDocuments
      ),
    [documentsData]
  )

  const handleNeedCorrection = async () => {
    if (!orderId) return
    await managerNeedDocumentsCorrectionMutation({
      variables: {
        id: orderId,
        comment: correctionValue,
      },
    })
    await refetchOrder()
  }

  const handleForm = async (values: FormikValues) => {
    if (!orderId) return
    await managerSetDealDateMutation({
      variables: {
        id: orderId,
        comment: '',
        dealDate: moment(
          `${values.date} ${values.time}`,
          'DD.MM.YYYY HH:mm'
        ).utcOffset('+03:00'),
      },
    })
    await refetchOrder()
  }

  return (
    <StepWrapper description="Пользователь загрузил документы, примите или напишите что нужно сделать">
      {!isEmpty(documents) && (
        <Box>
          <FormLabel>Документы по заявке</FormLabel>
          <Stack spacing="10px">
            {documents?.map((document) => (
              <FileItem
                key={document.id}
                id={document.id}
                name={document?.fileName}
                orderId={orderId}
                cursor="pointer"
                backgroundColor="white"
                _hover={{
                  backgroundColor: 'gray.200',
                }}
                _active={{
                  backgroundColor: 'gray.300',
                }}
              />
            ))}
          </Stack>
        </Box>
      )}
      {!isAccept && !isNeedCorrection && (
        <HStack spacing="16px">
          <Button
            width="fit-content"
            onClick={() => setIsAccept(true)}
            variant="primary"
          >
            Принять
          </Button>
          <Button
            width="fit-content"
            onClick={() => setNeedCorrection(true)}
            variant="secondary"
          >
            Требуется коррекция
          </Button>
        </HStack>
      )}
      {isNeedCorrection && (
        <>
          <Divider borderColor="gray.300" />
          <TextArea
            autoFocus
            name="comment"
            value={correctionValue}
            placeholder="Напишите что необходимо скорректировать в документах"
            minHeight="100px"
            onChange={(e) => setCorrectionValue(e.target.value)}
          />
          <HStack spacing="16px">
            <Button
              isDisabled={
                isEmptyString(correctionValue) || isNeedCorrectionLoading
              }
              isLoading={isNeedCorrectionLoading}
              onClick={handleNeedCorrection}
              variant="attention"
            >
              Отправить
            </Button>
            <Button
              isDisabled={isNeedCorrectionLoading}
              onClick={() => setNeedCorrection(false)}
              variant="secondary"
            >
              Отменить
            </Button>
          </HStack>
        </>
      )}
      {isAccept && (
        <>
          <Divider borderColor="gray.300" />
          <Box>Введите дату и время сделки</Box>
          <Formik
            validationSchema={formSchema}
            initialValues={{
              date: moment().format('DD.MM.YYYY'),
              time: moment().format('HH:mm'),
            }}
            onSubmit={handleForm}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Stack spacing="24px">
                  <HStack spacing="14px" alignItems="flex-start">
                    <Field
                      name="date"
                      label="Дата"
                      mask={[
                        /\d/,
                        /\d/,
                        '.',
                        /\d/,
                        /\d/,
                        '.',
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      component={InputMaskForm}
                      isDisabled={isSetDateLoading}
                    />
                    <Field
                      name="time"
                      label="Время"
                      mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                      component={InputMaskForm}
                      isDisabled={isSetDateLoading}
                    />
                  </HStack>
                  <HStack spacing="16px">
                    <Button
                      isDisabled={!props.dirty}
                      isLoading={isSetDateLoading}
                      type="submit"
                      variant="primary"
                    >
                      Отправить
                    </Button>
                    <Button
                      isDisabled={isSetDateLoading}
                      onClick={() => setIsAccept(false)}
                      variant="secondary"
                    >
                      Отменить
                    </Button>
                  </HStack>
                </Stack>
              </form>
            )}
          </Formik>
        </>
      )}
    </StepWrapper>
  )
}

const formSchema = Yup.object().shape({
  date: Yup.string()
    .required('Заполните дату')
    .test('isValidDate', 'Некорректная дата', (value) =>
      moment(value, 'DD.MM.YYYY', true).isValid()
    ),
  time: Yup.string()
    .required('Заполните время')
    .test('isValidTime', 'Некорректное время', (value) =>
      moment(value, 'HH:mm', true).isValid()
    ),
})
