import { Modal } from 'uikit/Modal'
import React, { ReactNode } from 'react'
import { Box } from '@chakra-ui/react'

export interface DocumentModalProps {
  isOpen: boolean
  children: ReactNode
  onClose: () => void
}

export const DocumentModal: React.FC<DocumentModalProps> = ({ isOpen, children, onClose }) => {
  return (
    <Modal trapFocus={false} isOpen={isOpen} onClose={onClose}>
      <Box>
        {children}
      </Box>
    </Modal>
  )
}
