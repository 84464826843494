import { Button } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useManagerSendOrderContractMutation } from 'api/graphql'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { TextArea } from 'uikit/TextArea'
import { UploadButton } from '../../Upload/UploadButton'
import { StepWrapper } from '../../../elements/StepWrapper'

export const ContactStep = () => {
  const { orderId } = useOrderData()
  const [managerSendOrderContractMutation, { loading }] =
    useManagerSendOrderContractMutation()
  const [value, setValue] = useState('')
  const [isComment, setIsComment] = useState(false)
  const { refetchOrder } = useRefetchOrder()

  const handleFinish = async () => {
    if (!orderId) return
    await managerSendOrderContractMutation({
      variables: {
        id: orderId,
        comment: value,
      },
    })
    await refetchOrder()
  }

  return (
    <StepWrapper description="Сформируйте договор и отправьте его клиенту">
      {isComment ? (
        <TextArea
          name="comment"
          placeholder="Напишите комментарий по желанию"
          minHeight="100px"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      ) : (
        <Button
          size="sm"
          width="fit-content"
          variant="linkBlue"
          onClick={() => setIsComment(true)}
        >
          Добавить комментарий
        </Button>
      )}
      <UploadButton
        documentType="Contract"
        selectLabelButton="Выбрать документ"
        uploadLabelButton="Загрузить и отправить"
        onFinish={handleFinish}
      />
    </StepWrapper>
  )
}
