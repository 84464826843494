import * as Chakra from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { FormLabelProps } from '@chakra-ui/react'

export interface FormLabelPropsProps extends FormLabelProps {
  name?: string
  isDisabled?: boolean
  children: ReactNode
}

export const FormLabel: React.FC<FormLabelPropsProps> = ({ name, isDisabled, children, ...props }) => {
  return (
    <Chakra.FormLabel
      htmlFor={name}
      fontSize="0.9rem"
      width="100%"
      mb="6px"
      pl="3px"
      fontWeight="normal"
      color="black"
      opacity={isDisabled ? 0.3 : 1}
      {...props}
    >
      {children}
    </Chakra.FormLabel>
  )
}
