import React from 'react'
import { DocumentModal } from 'components/DocumentModal'

interface DocOfferModalProps {
  isOpen: boolean
  onClose: () => void
}

export const DocOfferModal: React.FC<DocOfferModalProps> = ({
                                                              isOpen,
                                                              onClose,
                                                            }) => {
  return (
    <DocumentModal isOpen={isOpen} onClose={onClose}>
      <p><strong>ОФЕРТА</strong></p><p><strong>ИСПОЛЬЗУЯ САЙТ И ЗАКЛЮЧАЯ СДЕЛКИ ПОСРЕДСТВОМ ИСПОЛЬЗОВАНИЯ СЕРВИСА
      ALFRED.MARKET, Я ПОДТВЕРЖДАЮ, ЧТО ОЗНАКОМЛЕН, СОГЛАСЕН И ПРИНИМАЮ УСЛОВИЯ НАСТОЯЩЕГО СОГЛАШЕНИЯ (ОФЕРТЫ),
      ПРИЛОЖЕНИЙ К НЕЙ, РАЗМЕЩЕННЫХ ПО АДРЕСУ </strong><a href='https://alfred.market/'>https://alfred.market/</a>legal,<strong> И
      ИНЫХ ДОКУМЕНТОВ, ЯВЛЯЮЩИХСЯ НЕОТЪЕМЛЕМОЙ ЧАСТЬЮ ОФЕРТЫ.</strong></p>
      <ol>
        <li><strong>ПРАВООТНОШЕНИЯ, РЕГУЛИРУЕМЫЕ ОФЕРТОЙ</strong>
          <ol>
            <li>Настоящее соглашение является офертой на заключение договора присоединения (далее –
              «<strong>Оферта</strong>»), регулирующего следующие правоотношения между Пользователем и Правообладателем,
              а также между Пользователем и Поверенным (термины определены в Приложении №1 к Оферте, размещенном на
              вэб-сайте по адресу <a href='https://alfred.market/legal'>https://alfred.market/legal</a>):
              <ol>
                <li>предоставление Правообладателем Пользователю на условиях Приложения №2 к Оферте, изложенных по
                  адресу <a href='https://alfred.market/'>https://alfred.market/</a>legal неисключительной лицензии на
                  использование сайта <a href='https://alfred.market/'>https://alfred.market/</a> (далее –
                  «<strong>Сайт</strong>»), являющегося цифровой площадкой, с помощью которой Пользователь может
                  заключать Сделки с Поверенным;
                </li>
                <li>возмездное оказание Пользователю информационно-технологических услуг по сопровождению
                  Правообладателем заключаемых Пользователем Сделок с Поверенным, от имени и за счет которого в рамках
                  настоящей Оферты выступает Правообладатель;
                </li>
                <li>заключение между Пользователем и Поверенным Сделок на условиях настоящей Оферты для целей получения
                  Пользователем агентских услуг по исполнению Поверенным (или иным лицом по указанию Поверенного)
                  денежных и (или) иных обязательств Пользователя перед третьими лицами по указанию и в интересах
                  Пользователя;
                </li>
                <li>иные правоотношения, возникающие между Пользователем, Поверенным и Правообладателем, урегулированные
                  в рамках настоящей Оферты.
                </li>
              </ol>
            </li>
            <li>Все права и обязанности в рамках Сделок Пользователя с Поверенным возникают исключительно между
              Пользователем и Поверенным, агентом которого для целей заключения Сделок выступает Правообладатель на
              основании поручения Поверенного, выданного Поверенным Правообладателю.
            </li>
            <li>Правообладатель, являясь представителем Поверенного НА ОСНОВАНИИ ЗАКЛЮЧЕННОГО ПРАВООБЛАДАТЕЛЕМ С
              ПОВЕРЕННЫМ АГЕНТСКОГО ДОГОВОРА и ПОРУЧЕНИЯ К НЕМУ, предоставляет ПользователЯМ исключительно ЛИЦЕНЗИЮ НА
              ИСПОЛЬЗОВАНИЕ САЙТА И ОКАЗЫВАЕТ информационнО-ТЕХНОЛОГИЧЕСКИЕ услуги, НЕ СТАНОВЯСЬ ПРИ ЭТОМ СТОРОНОЙ
              СДЕЛКИ ПОЛЬЗОВАТЕЛЯ С ПОВЕРЕННЫМ И НЕ ОТВЕЧАЯ ЗА ИСПОЛНЕНИЕ Обязательств ПОВЕРЕННЫМ.
            </li>
            <li>Полным и безоговорочным принятием условий настоящей Оферты является совершение Пользователем Сайта
              действий направленных на использование Сайта, в том числе, действий по регистрации Пользователем Личного
              кабинета на Сайте или оформление Заказов на получение от Поверенного агентских услуг в рамках заключенных
              Пользователем с Поверенным посредством Сайта Сделок, направленных на исполнение обязательств Пользователя
              перед третьими лицами (далее – «<strong>Услуги</strong>»), а также совершение иных действий,
              предусмотренных функционалом Сайта.
            </li>
            <li>Если ПОЛЬЗОВАТЕЛЬ не согласЕН с какими-либо положениями настоящей Оферты, ПОЛЬЗОВАТЕЛЬ ОБЯЗУЕТСЯ
              НЕЗАМЕДЛИТЕЛЬНО прекратитЬ использование Сайта И НЕ ОСТАВЛЯТЬ ЗАКАЗЫ НА ЗАКЛЮЧЕНИЕ СДЕЛОК С ПОВЕРЕННЫМ.
            </li>
            <li>В случае нарушения Пользователем условий Оферты или Требований Правообладатель оставляет за собой право
              заблокировать доступ к Личному кабинету Пользователя, если на момент блокировки нет неисполненных
              обязательств сторон по Сделкам.
            </li>
            <li>Правообладатель обязуется обеспечить Пользователям возможность использования Сайта в рамках прав и
              обязанностей, изложенных в настоящей Оферте, при условии соблюдения Пользователем положений настоящей
              Оферты.
            </li>
            <li>Настоящая Оферта и Приложения к ней, размещенные на сайте <a
              href='https://alfred.market/'>https://alfred.market/</a>legal, и являющиеся неотъемлемой частью Оферты,
              определяют порядок использования Сайта вне зависимости от целей использования Сайта, а также заключение
              Сделок посредством функционала Сайта Пользователями. Используя Сайт, Пользователь безусловно соглашается с
              условиями настоящей Оферты, а также со следующими документами, регулирующими использование Сайта и
              размещенными по адресу <a href='https://alfred.market/legal'>https://alfred.market/legal</a>:
            </li>
          </ol>
        </li>
      </ol>
      <p>- <strong>Приложение № 1 «Используемые в Оферте термины»</strong>;</p><p>- <strong>Приложение № 2 «Лицензионные
      условия использования сервиса Alfred.Market»</strong>;</p><p>- <strong>Приложение № 3 «Заверения и
      гарантии»</strong>;</p><p>- <strong>Приложение № 4 «Условия использования простой электронной подписи и доставки
      юридически значимых сообщений»</strong>;</p><p>- <strong>Приложение № 5 «Условия конфиденциальности и обработки
      персональных данных»</strong>;</p><p>- <strong>Приложение № 6 «Ответственность, претензии, форс-мажор»</strong>;
    </p><p>- <strong>Приложение № 7 «Форма поручения»</strong>;</p><p>- <strong>Приложение № 8 «Форма Акта-отчета об
      исполнении поручения»</strong>;</p><p>- <strong>Политика конфиденциальности</strong>;</p><p>- <strong>Политика
      использования файлов Сookies</strong>.</p>
      <ol>
        <li><strong>РЕГИСТРАЦИЯ В СЕРВИСЕ И ИСПОЛЬЗОВАНИЕ ЛИЧНОГО КАБИНЕТА</strong>
          <ol>
            <li>Заключить Сделку на Сайте могут только зарегистрированные Пользователи, при этом каждый Пользователь
              вправе зарегистрироваться на Сайте только 1 (один) раз, т.е. может иметь только в один Личный кабинет.
            </li>
            <li>Пользователь, для получения доступа к Личному кабинету, проходит индивидуальную идентификацию путем
              ввода кода доступа, направленного Правообладателем на указанный в Личном кабинете контактный номер
              телефона при помощи смс-сообщения.
            </li>
            <li>Индивидуальная идентификация Пользователя позволяет избежать несанкционированных действий третьих лиц от
              имени Пользователя и открывает доступ к дополнительным сервисам Сайта. Замена номера телефона,
              регистрационных данных в Личном кабинете может быть произведена только по согласованию с Правообладателем
              и при условии подтверждения нового номера телефона.
            </li>
            <li>Передача Пользователем кода доступа третьим лицам не допускается. Пользователь несёт ответственность за
              все возможные негативные последствия, в том числе материальные убытки, в случае передачи кода доступа,
              учетных данных Личного кабинета, утрату номера телефона, на который зарегистрирован Личный кабинет, а
              также предоставлении доступа в Личный кабинет третьим лицам.
            </li>
            <li>Пользователь обязан незамедлительно уведомить Правообладателя о любом случае несанкционированного
              (осуществленного без разрешения Пользователя) доступа к Сайту с использованием Личного кабинета и/или
              номера телефона Пользователя и/или о любом нарушении безопасности Личного кабинета Пользователя.
            </li>
            <li>Если Пользователем не доказано обратное, все действия, совершенные на Сайте с использованием Личного
              кабинета и номера телефона Пользователя, признаются действиями, совершенными Пользователем или с его
              согласия/поручения.
            </li>
            <li>Правообладатель не несет ответственности за точность и правильность информации, предоставляемой
              Пользователям при регистрации, а также за возможные негативные последствия, вызванные предоставлением
              Пользователям неточной или неправильной информации о себе.
            </li>
            <li>При некорректном (предоставление недостоверной или неточной информации) оформлении Сделки
              ответственность за Сделку и ее ненадлежащее исполнение Поверенным, в том числе за возможные финансовые или
              иные убытки Пользователя, лежит на Пользователе - владельце Личного кабинета.
            </li>
            <li>Для оформления Заказа по Сделке Пользователю необходимо зарегистрироваться на Сайте в качестве
              юридического лица или индивидуального предпринимателя, а также, при наличии технической возможности
              подключиться к системе электронного документооборота (далее – «<strong>ЭДО</strong>») из числа указанных в
              форме регистрации операторов ЭДО, и принять приглашение, направленное Правообладателем через систему ЭДО,
              а в случае отсутствия технической возможности подключиться к системе ЭДО из числа указанных в форме
              регистрации, Пользователь указывает почтовый адрес, номер телефона и фамилию, имя, отчество (при наличии)
              своего представителя, которые могут быть использованы для отправки корреспонденции в адрес Пользователя
              для целей обмена документами в бумажной форме в рамках заключаемых Сделок.
            </li>
          </ol>
        </li>
        <li><strong>УСЛОВИЯ ОКАЗАНИЯ ИНФОРМАЦИОННО-ТЕХНОЛОГИЧЕСКИХ УСЛУГ и ЗАКЛЮЧЕНИЯ СДЕЛОК</strong>
          <ol>
            <li>В рамках условий настоящего раздела Оферты термины «сторона» и «стороны» применяются только по отношению
              к Пользователю и Поверенному, являющимся сторонами Сделки, и не применяются к Правообладателю, не
              являющемуся стороной Сделки.
            </li>
            <li>Для целей совершения Сделок на Сайте Правообладатель выступает в качестве уполномоченного на основании
              соответствующего договора агента Поверенного по заключению от имени Поверенного Сделок с Пользователями и
              получению от Пользователей денежных средств в рамках исполнения Сделок, а также оказывает Пользователям
              услуги по информационно-технологическому, в том числе документарному, сопровождению Сделок.
            </li>
            <li>Настоящая Оферта не налагает на Пользователя обязательства выдавать Правообладателю Поручение на
              совершение юридических и иных действий Поверенным и не налагает обязательства на Поверенного совершать
              юридические и иные действия до перевода денежных средств Пользователем и согласования Поверенным
              полученного от Правообладателя соответствующего Поручения, определяющего существенные условия Сделки.
            </li>
            <li>Права и обязанности по Сделкам, заключенным Правообладателем от имени Поверенного, возникают
              непосредственно у Поверенного.
            </li>
            <li>Правообладатель подтверждает, что, являясь агентом Поверенного, имеет все полномочия, необходимые для
              заключения с Пользователем от имени и за счет Поверенного агентского договора (Сделки) на условиях
              настоящей Оферты, в рамках которого Пользователь становится стороной Сделки на условиях, согласуемых
              посредством Личного кабинета Пользователя.
            </li>
            <li>Размер Вознаграждения Правообладателя за оказание информационно-технологических услуг определяются
              Правообладателем и согласуется с Пользователем в Личном кабинете.
            </li>
            <li>Пользователь отдельно соглашается, что ни при каких условиях Правообладатель не является лицом,
              уполномоченным Поверенным на принятие и удовлетворение требований Пользователей в отношении Сделок.
            </li>
            <li>Пользователь отдельно соглашается, что ни Правообладатель, ни Поверенный не несут ответственности за
              доставку какого-либо товара или Пользователю или получение от третьих лиц услуг, прямо не указанных в
              условиях Сделки, если отдельным соглашением не предусмотрено иное.
            </li>
            <li>Указанные в Оферте права и обязанности Поверенного согласованы Правообладателем с Поверенным на
              основании заключенного между Поверенным и Правообладателем соглашения, в рамках которого Поверенный
              предоставил Правообладателю, действующему в качестве агента Поверенного, право заключать с Пользователем
              настоящую Оферту и вытекающие из нее Сделки от имени Поверенного.
            </li>
            <li>На условиях настоящей Оферты стороны заключают рамочный агентский договор, существенные условия которого
              определяются в рамках согласуемых сторонами Поручений, формируя отдельные Сделки, каждая из которых
              представляет собой отдельное правоотношение между сторонами и не зависит от исполнения другой Сделки на
              основании другого Поручения.
            </li>
            <li>В рамках заключенной на условиях настоящей Оферты, Приложений к Оферте, Поручений по Сделкам
              Пользователь поручает, а Поверенный обязуется за вознаграждение совершать за счет Пользователя необходимые
              юридические и (или) фактические действия, направленные на оплату и организацию взаимодействия между
              Пользователем и Поставщиком в рамках исполнения денежных обязательств Пользователя перед Поставщиком в том
              числе, при участии нанимаемого Поверенным Субагента, за действия которого Поверенной несет ответственность
              как за свои.
            </li>
            <li>Стоимость, характеристики и свойства Услуг могут быть изменены Правообладателем и устанавливаются в
              рамках конкретных Заказов, согласуемых через Личный кабинет, и конкретизируются в рамках Поручений,
              содержащих существенные условия Сделок, заключаемых на условиях настоящей Оферты.
            </li>
            <li>В рамках договора, заключенного на условиях настоящей Оферты, Пользователь вправе предоставить
              Поверенному неограниченное количество Поручений, направленных на исполнение денежных обязательств
              Покупателя товара перед Поставщиками.
            </li>
            <li>Каждое последующее Поручение оформляется отдельным документом и является отдельной Сделкой, совершенной
              на основании рамочного договора-оферты, существенные условия которой устанавливаются в Поручении,
              конкретизирующем установленные в настоящей Оферте общие рамочные условия Сделок, заключаемых Пользователем
              с Поверенным, от имени которого выступает Правообладатель.
            </li>
            <li>Оплата Заказа Пользователем, также как и подписание Поручения, является подтверждением со стороны
              Пользователя параметров Заказа (количество, наименование Услуги, стоимость Услуг и т.д.), а также является
              подтверждением того, что Пользователь не имеет претензий относительно условий Сделки, а Заказ оформлен
              Пользователем от своего имени и в своих интересах.
            </li>
            <li>Денежные средства в размере, согласованном с Правообладателем в Заказе, Пользователь обязуется
              перечислить на расчетный счет Правообладателя, указанный в Поручении.
            </li>
            <li>Права и обязанности по Поручению, исполняемому Поверенным и предоставляемому Пользователем Поверенному
              во исполнение Сделки, возникают у Пользователя и Поверенного.
            </li>
            <li>Пользователь безусловно соглашается, что каждое Поручение считается надлежащим образом исполненным
              Поверенным в момент отправки Поверенным (или Субагентом Поверенного) денежных средств по Сделке согласно
              Инвойсу в банк Поставщика, указанный в Инвойсе, что подтверждается банковским документом от банка
              Поверенного (или Субагента Поверенного), в том числе автоматизированной копией, направленной банком в виде
              электронного документа.
            </li>
            <li>Поверенный не несет ответственности перед Пользователем, за действия/бездействия Пользователя перед
              Поставщиком или иными третьими лицами, в отношении которых Поверенным осуществляются юридически значимые
              действия по осуществлению оплаты.
            </li>
            <li>Поверенный или Правообладатель не несут ответственность при возникновении неблагоприятных обстоятельств,
              возникших между Пользователем и третьими лицами в результате невозможности исполнить требования ФЗ №115 в
              рамках Сделок.
            </li>
            <li><strong>Поверенный обязуется:</strong>
              <ol>
                <li>Исполнить Поручение в срок не позднее 5 (Пяти) рабочих дней с даты, поступления всей суммы денежных
                  средств, указанной в Поручении, на банковский расчетный счет Правообладателя;
                </li>
                <li>Представить Правообладателю для согласования с Пользователем Акт-отчет об исполнении Поручения
                  (далее также – «<strong>Акт-отчет</strong>») не позднее 5 (пяти) рабочих дней со дня его надлежащего
                  исполнения Сделки с приложением документов, подтверждающих исполнение Поручения;
                </li>
                <li>Соблюдать конфиденциальность информации, полученной в связи с исполнением Сделки;</li>
                <li>Сообщать Правообладателю или Пользователю по их требованию все необходимые сведения о ходе
                  исполнения Поручения;
                </li>
                <li>В случае отказа Поверенного от исполнения Поручения, вернуть Пользователю полученные денежные
                  средства по Сделке (в том числе, сумму Вознаграждения Поверенного) в течение 5 (пяти) рабочих дней с
                  момента отказа от исполнения Поручения;
                </li>
                <li>В случае отказа от исполнения Сделки возвратить Пользователю Поручение в случае, если срок действия
                  Поручения не истек.
                </li>
              </ol>
            </li>
            <li><strong>Поверенный вправе:</strong>
              <ol>
                <li>В любое время до момента исполнения Сделки Поверенным отказаться от исполнения Поручения, уведомив
                  Пользователя об этом и вернув Пользователю денежные средства, полученные в рамках Сделки (в том числе
                  сумму Вознаграждения Поверенного);
                </li>
                <li>Без предварительного согласования с Пользователем для целей исполнения Поручения привлекать любых
                  третьих лиц по своему усмотрению (включая Субагента), при этом оставаясь ответственным перед
                  Пользователем за действия таких третьих лиц как за свои собственные;
                </li>
                <li>Отступить от данных ему в рамках Поручения указаний, если по обстоятельствам это необходимо в
                  интересах Пользователя и Поверенный не мог предварительно запросить согласие Пользователя или не
                  получил своевременно ответ на свой запрос;
                </li>
                <li>Запрашивать у Пользователя (в том числе через Правообладателя) необходимые документы, в порядке и в
                  случаях, установленных ФЗ №115, иным законодательством РФ и нормативными актами Банка России.
                </li>
              </ol>
            </li>
            <li><strong>Пользователь обязан:</strong>
              <ol>
                <li>Сообщать все необходимые данные, однозначно идентифицирующие его как Пользователя, и достаточные для
                  оказания Пользователю оплаченных им Услуг в рамках Сделки;
                </li>
                <li>Оплатить денежные средства по Сделке, сумму Вознаграждения Поверенного и сумму Вознаграждения
                  Правообладателя в соответствии с условиями, согласованными в Личном кабинете;
                </li>
                <li>При необходимости, для целей исполнения Поручения, выдать Поверенному доверенность, удостоверяющую
                  его полномочия, и (или) Поручение, и (или) иные необходимые документы;
                </li>
                <li>Принять от Поверенного все полученное при исполнении Поручения, в том числе, информацию и документы,
                  подтверждающие исполнение обязательств третьим лицом, привлеченным Поверенным для исполнения
                  обязательств по Сделке.
                </li>
              </ol>
            </li>
            <li><strong>Пользователь вправе:</strong>
              <ol>
                <li>Отменить Поручение до совершения Поверенным платежа, уведомив об этом Поверенного способами,
                  предусмотренными Офертой, в том числе посредством Личного кабинета. В случае получения Поверенным
                  уведомления об отмене Поручения у Поверенного не возникает обязанности возвращать сумму перечисленных
                  денежных средств, если Поверенный на дату получения указанного сообщения уже совершил платеж по
                  реквизитам Субагента или иного лица, в том числе указанного Пользователем в Поручении в качестве
                  получателя денежных средств. В указанном случае Поручение считается исполненным Поверенным надлежащим
                  образом;
                </li>
                <li>Изменить указанные при регистрации данные в Личном кабинете, или при необходимости иным способом,
                  указанным Правообладателем, если такие изменения не будут вводить в заблуждение Правообладателя или
                  иным образом нарушать правила Оферты или применимого законодательства.
                </li>
              </ol>
            </li>
            <li>Оформляя Заказ, Пользователь соглашается с тем, что Правообладатель или Поверенный могут поручить
              исполнение обязательств по оказанию Услуг Поверенного или услуг Правообладателя в рамках настоящей Оферты
              третьим лицам, оставаясь ответственным за исполнение таких услуг перед Пользователем.
            </li>
            <li>Пользователь подтверждает, что оказание Услуг в рамках настоящей Оферты не носит личный характер и может
              быть оказано любым компетентным лицом, которого выберет Правообладатель или Поверенный.
            </li>
            <li>Пользователь отдельно соглашается, что Правообладатель, не являясь стороной Сделки, не несет
              ответственности за ИСПОЛНЕНИЕ СДЕЛКИ ПОВЕРЕННЫМ, постпродажные И ИНЫЕ ПРАВООТНОШЕНИЯ Пользователя и
              Поверенного в рамках Сделки, не отвечает за правоотношения пользователя/покапателя товара и поставщика,
              отказ от исполнения обязательств из настоящей Оферты Поверенным, а также вправе не отвечать на иныЕ
              требования Пользователя в отношении Сделки.
            </li>
            <li><strong>Правообладатель вправе</strong>:
              <ol>
                <li>отказать Пользователю в заключении Сделки при отсутствии предоплаты денежных средств;</li>
                <li>отменить Заказ в любое время в случае непоступления от Пользователя оплаты по Сделке;</li>
                <li>до согласования условий Сделки устанавливать стоимость информационно-технологических услуг или
                  вводить ограничения по стоимости заказываемых Услуг по Сделке, или определять иные условия Сделок;
                </li>
                <li>отменить Заказ Пользователя или от имени Поверенного отменить Сделку в случае нарушения
                  Пользователем положений Оферты, а также нарушения любых иных согласованных условий Сделки;
                </li>
                <li>в случае нарушения пользователем условий Оферты расторгнуть договор, заключенный на основании
                  Оферты, при этом, расторжение настоящей Оферты может быть осуществлено Правообладателем в любое время,
                  но это не является основанием для отказа от исполнения обязательств Правообладателя и/или Поверенного
                  по уже заключённым на условиях Оферты Сделкам;
                </li>
                <li>заблокировать Личный кабинет Пользователя в случае совершения им мошеннических или иных
                  противоправных действий, а также в случае неоднократной отмены Заказа или в случае поступления
                  соответствующего требования от контролирующих, надзорных или правоохранительных органов.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li><strong>ВОЗНАГРАЖДЕНИЕ, ОПЛАТА, ОТЧЕТНОСТЬ В РАМКАХ СДЕЛКИ</strong>
          <ol>
            <li>Оплата Заказа означает безусловное согласие Пользователя со всеми условиями Сделки, в том числе,
              согласие с условиями Оферты, Приложений к Оферте, размещенных по адресу <a
                href='https://alfred.market/'>https://alfred.market/</a>legal, условиями Поручения, размером
              Вознаграждения Поверенного и размером Вознаграждения Правообладателя.
            </li>
            <li>Сумма оплаты по Сделке определяется в рамках согласованного с Пользователем Поручения.</li>
            <li>Сумма оплаты по Сделке может быть в любое время изменена Правообладателем в одностороннем порядке до
              момента заключения Сделки, определяемого днем списания денежных средств со счета Пользователя для оплаты
              Поверенному.
            </li>
            <li>Оплата Пользователем в рамках Сделки осуществляется на расчетный счет Правообладателя, действующего по
              поручению соответствующего Поверенного в части приема денежных средств с привлечением уполномоченного
              оператора по приему платежей или оператора электронных денежных средств.
            </li>
            <li>Вознаграждение Поверенного в рамках Сделок устанавливается в отношении каждого отдельного Поручения.
            </li>
            <li>Вознаграждение Поверенного и Вознаграждение Правообладателя выплачиваются Пользователем Правообладателю
              в день совершения платежа по Сделке или иную дату согласно условиям, согласованным в Личном кабинете
              Пользователя.
            </li>
            <li>Вознаграждение Поверенного включает любые расходы Поверенного, включая комиссии банка и иные
              обязательные платежи, понесенные Поверенным в связи с исполнением Сделки.
            </li>
            <li>Согласованный Пользователем размер Вознаграждения Поверенного и (или) Вознаграждения Правообладателя
              изменению не подлежат, если не произойдет непредвиденных обстоятельств, не зависящих от Правообладателя и
              (или) Поверенного, в том числе, но не ограничиваясь, обстоятельств непреодолимой силы.
            </li>
            <li>Сумма оплаты по Сделке включает в себя все применимые налоги, если Правообладатель прямо не укажет иное
              в рамках документации для оформления Заказа.
            </li>
            <li>Оплата Сделки на Сайте может производится корпоративной банковской картой по отдельному согласованию с
              Правообладателем возможности оплаты корпоративной банковской картой в Личном кабинете.
            </li>
            <li>Счет на оплату Сделки отображается в Личном кабинете Пользователя, а также, по согласованию с
              Правообладателем, может дополнительно направляться Пользователю на электронную почту, указанную в Личном
              кабинете.
            </li>
            <li>Порядок оплаты Заказа:
              <ol>
                <li>Если иное прямо не указано в счете на оплату Заказа, Пользователю предоставляется 24 часа на оплату
                  счета с момента его получения, не включая день получения счета, выходные и праздничные дни;
                </li>
                <li>При оплате Заказа корпоративной банковской картой Пользователю предоставляется 5 (пять) минут на
                  оплату Заказа;
                </li>
                <li>В случае если в указанный выше период времени по любой причине оплаты Заказа не произошло и деньги
                  не поступили на расчетный счет Правообладателя, Заказ может быть отменен Правообладателем в
                  одностороннем порядке, а Сделка считается не заключенной.
                </li>
              </ol>
            </li>
            <li>Оплата любым из указанных способов осуществляется на расчетный счет Правообладателя, который в части
              приема денежных средств за Услуги в рамках Сделки, действует по поручению Поверенного с привлечением
              уполномоченного оператора по приему платежей или оператора электронных денежных средств, и является
              получателем платежа в качестве агента Поверенного, действующего от имени Поверенного.
            </li>
            <li>Являясь уполномоченным представителем Поверенного, Правообладатель вправе подписывать Акт-отчет от имени
              Поверенного.
            </li>
            <li>После исполнения Поручения Правообладатель направляет в трех экземплярах Пользователю надлежащим образом
              подписанный Акт-отчет об исполнении Поручения Поверенным, Пользователь подписывает Акт-отчет и направляет
              Правообладателю два подписанных Пользователем экземпляра Акта-отчета не позднее 5 (Пяти) рабочих дней с
              даты получения, если иное не будет согласовано в Личном кабинете Пользователя.
            </li>
            <li>В случае наличия замечаний по объему, срокам или качеству Услуг, Пользователь в письменном виде
              направляет такие замечания не позднее 5 (Пяти) рабочих дней с даты получения Акта-отчета, а в случае
              отсутствия замечаний, если подписанный Акт-отчет Пользователь не направил Правообладателю, по истечении 5
              (пяти) рабочих дней Акт-отчет считается подписанным Пользователем, а обязательства Поверенного по Сделке
              исполненными надлежащим образом в полном объеме.
            </li>
          </ol>
        </li>
        <li><strong>ИНЫЕ УСЛОВИЯ</strong>
          <ol>
            <li>Правообладатель вправе в любое время изменить полностью или в части настоящую Оферту. Новая редакция
              Оферты вступает в силу по истечении 3 (трех) рабочих дней с даты уведомления Пользователей о новой
              редакции Оферты. Уведомление об изменении Оферты направляется Пользователям посредством Личного кабинета
              или публикуется на Сайте. Если Пользователь не согласен с условиями новой редакции Оферты, он обязуется
              уведомить об этом Правообладателя в течение 3 (трех) рабочих дней и перестать использовать Сайт и не
              оставлять Заявки на заключение Сделок. В указанном случае заключенные на момент направления указанного
              уведомления об изменении Оферты Сделки регулируются условиями Оферты, действовавшими на дату заключения
              Сделки. Все редакции Оферты хранятся на Сайте по адресу <a
                href='https://alfred.market/legal'>https://alfred.market/legal</a>.
            </li>
            <li>Во избежание сомнений, Пользователь, Поверенный и Правообладатель, признают юридическую силу электронной
              переписки и согласования условий в Личном кабинете Пользователя, электронных сообщений, копии текста,
              содержащегося в электронных документах (файлах), направляемых для целей согласования условий конкретной
              Сделки, при условии, что для целей отправки вышеуказанных сообщений и документов используется Личный
              кабинет и/или адрес электронной почты Пользователя, указанный при регистрации на Сайте.
            </li>
            <li>Правообладатель, Пользователь и Поверенный признают юридическую силу любых уведомлений, писем и прочей
              корреспонденции в связи с исполнением, изменением, прекращением обязательств из настоящей Оферты и Сделок,
              переданных в виде отсканированных копий (фотокопий) посредством Личного кабинета, электронной почты или
              иным способом, позволяющим достоверно установить отправителя.
            </li>
            <li>Пользователь соглашается, что явные и очевидные ошибки, включая опечатки, допущенные в описаниях на
              Сайте или в описании услуг в рамках Заказов, юридически обязывающими не являются.
            </li>
            <li>Если у Пользователя имеются какие-либо вопросы, жалобы или претензии в отношении Сайта или
              информационно-технологических услуг Правообладателя, Пользователь может связаться с Правообладателем по
              адресу: <a href='mailto:mail@alfred.market'>mail@alfred.market</a>.
            </li>
            <li>Если одно или несколько положений настоящей Оферты будут признаны недействительными в соответствии со
              вступившим в силу судебным решением, остальные положения настоящей Оферты остаются в силе, и
              Правообладатель, Пользователь, Поверенный продолжают исполнять свои обязательства способом, максимально
              соответствующим намерениям сторон в момент заключения и (или) исполнения настоящей Оферты.
            </li>
          </ol>
        </li>
      </ol>
      <p><strong>Редакция оферты от 24.10.2024</strong></p><p><strong>Приложение № 1</strong></p><p><strong>к Оферте
      Alfred.Market </strong></p><p><strong>«Используемые в Оферте термины»</strong></p><p><strong>ИСПОЛЬЗУЕМЫЕ В ОФЕРТЕ
      ТЕРМИНЫ</strong></p><p>В настоящей Оферте нижеприведенные термины имеют следующие значения:</p>
      <ol>
        <li>«<strong>Вознаграждение Правообладателя</strong>» — определяемая Правообладателем и предоставляемая
          Пользователю для согласования до Сделки стоимость (формула расчета стоимости) оказания
          информационно-технологических услуг Правообладателя.
        </li>
        <li>«<strong>Заказ</strong>» — осуществление Пользователем действий, направленных на заключение им Сделок с
          Поверенным посредством оформления на Сайте заявок на получение Услуг на условиях настоящей Оферты и Приложений
          к ней, в рамках которых Правообладатель берет на себя обязательство, на основании информации из такой заявки,
          согласовать с Поверенным условия Заказа на оказание Услуг, и, в случае получения согласия Поверенного на
          оказание Услуг на согласованных в рамках Личного кабинета условиях, оформить с Пользователем требующуюся для
          заключения Сделки документацию, в том числе заключить от имени Поверенного Сделку с Пользователем в сроки и в
          порядке, предусмотренные в Оферте, а также принять от Пользователя денежные средства для исполнения Сделки.
        </li>
        <li>«<strong>Инвойс</strong>» — документ, имеющий уникальный номер, составленный в бумажном или электронном
          виде, предоставляемый Поставщиком Пользователю и содержащий перечень товаров и услуг, их количество и цену, по
          которой они поставлены Пользователю, формальные особенности товара, обработку и условия поставки, налоговые
          сборы и сведения об отправителе и получателе.
        </li>
        <li>«<strong>Контент</strong>» - информационное содержание Сайта, в том числе, но не ограничиваясь, тексты,
          графическая и звуковая информация, дизайн, визуализация и т.д.
        </li>
        <li>«<strong>Контракт на поставку товара</strong>» — контракт на поставку товара между Поставщиком и Покупателем
          товара.
        </li>
        <li>«<strong>Личный кабинет</strong>» — размещенная на Сайте и защищенная посредством логина и пароля
          Пользователя персональная интернет-страница Пользователя, посредством которой оформляются Сделки
          Пользователей, а также предоставляется актуальная информация от Правообладателя и (или) Поверенного по
          коммерческим и иным условиям оказания Услуг на Сайте.
        </li>
        <li>«<strong>Правообладатель</strong>» — ООО «Альфред» (ОГРН 1247700663760), оказывающее через Сайт <a
          href='https://alfred.market/'>https://alfred.market/</a> следующие услуги:
          <ol>
            <li>Правообладатель предоставляет Пользователю неисключительную лицензию и доступ к функционалу Сайта для
              целей поиска Пользователем информации об Услугах на Сайте и дальнейшего заключения Пользователем Сделок с
              Поверенным;
            </li>
            <li>Правообладатель за вознаграждение оказывает Пользователю информационно-технологические услуги в рамках
              сопровождения Сделок Пользователя с Поверенным, агентом которого выступает Правообладатель;
            </li>
            <li>Являясь агентом Поверенного, Правообладатель за вознаграждение, от имени и за счет Поверенного,
              заключает с Пользователем на условиях настоящей Оферты Сделки связанные с реализацией Услуг Поверенного,
              предлагаемых Пользователям посредством Сайта.
            </li>
            <li>Являясь агентом Поверенного, для целей заключения и дальнейшего исполнения Сделок Поверенным,
              Правообладатель осуществляет прием от Пользователей денежных средств по Сделкам Пользователей с
              Поверенными;
            </li>
            <li>Осуществляет иные права и обязанности Правообладателя, предусмотренные настоящей офертой, а также
              получает вознаграждение за оказание информационно-технологических услуг Пользователю и оказание агентских
              услуг Поверенному в рамках Тарифов, согласованных Сторонами в отношении Сделок.
            </li>
          </ol>
        </li>
        <li>«<strong>Пользователь</strong>» — намеревающееся заключить Сделку с Поверенным юридическое лицо или
          индивидуальный предприниматель, использующие Сайт на условиях настоящей Оферты.
        </li>
        <li>«<strong>Поверенный</strong>» — индивидуальный предприниматель или юридическое лицо, зарегистрированные в
          установленном законом порядке и за соответствующее вознаграждение Поверенного (далее – «<strong>Вознаграждение
            Поверенного</strong>») осуществляющие деятельность по оказанию агентских Услуг Пользователям в рамках
          заключенных Правообладателем от имени Поверенного Сделок с Пользователями.
        </li>
        <li>«<strong>Поставщик</strong>» — иностранное юридическое лицо, товары которого оплачивает Пользователь
          посредством использования агентских Услуг Поверенного или его Субагента.
        </li>
        <li>«<strong>Покупатель товара</strong>» – лицо, указанное в качестве приобретателя в импортном контракте или
          Контракте на поставку товара. Таким лицом может быть Пользователь или третье лицо, указанное Пользователем.
        </li>
        <li>«<strong>Поручение</strong>» — являющаяся неотъемлемой частью Оферты и Сделки поручение, выдаваемое
          Пользователем, содержащее исчерпывающий перечень существенных условий конкретной Сделки Пользователя с
          Поверенным, заключаемой на условиях настоящей Оферты, выступающей в качестве рамочного договора. Каждое
          Поручение исполняется Поверенным один раз, после чего происходит приемка оказанных Услуг, оформляемая
          Актом-отчетом об исполнении Поручения. В Поручении указывается перечень конкретных юридических и иных
          действий, которые вправе совершать Поверенный для исполнения Сделки, а также сроки совершения таких действий,
          территория совершения действий и иные необходимые условия Сделки. Поручение подписывается Пользователем и
          направляется Правообладателю в форме, указанной Правообладателем.
        </li>
        <li>«<strong>Сделка</strong>» — заключаемый между Пользователем и Поверенным на условиях настоящей Оферты и
          Поручения договор агентских услуг по исполнению денежных обязательств Пользователя перед третьими лицами. От
          имени Поверенного для целей заключения договора агентских услуг на условиях настоящей Оферты, а также для
          целей приема денежных средств в интересах Поверенного, действует Правообладатель, являющийся надлежащим
          образом уполномоченным агентом Поверенного. Сделка считается заключенной в момент поступления Правообладателю
          от Пользователя денежных средств по оформленному Заказу в рамках Поручения. Дополнительные условия и сроки
          оказания Услуг в рамках Сделки определяются и согласуются в Личном кабинете. Правообладатель не несет
          ответственности за исполнение Сделки Поверенным, являясь агентом Поверенного, уполномоченным исключительно на
          заключение, информационное и документарное сопровождение Сделки от имени Поверенного, и прием денежных средств
          от Пользователя в целях дальнейшего перевода таких денежных средств Поверенному за вычетом комиссии
          Правообладателя.
        </li>
        <li>«<strong>Субагент</strong>» — привлекаемое Поверенным для целей реализации обязательств Поверенного по
          Сделке лицо, зарегистрированное за пределами РФ, за вознаграждение осуществляющее в интересах Пользователя
          деятельность, направленную на создание, изменение или прекращение прав и обязанностей Пользователя в сфере
          коммерческих, финансовых, логистических и иных посреднических операций, касающихся поставки товаров
          Поставщиком Пользователю.
        </li>
        <li>«<strong>Требования</strong>» — Определяемые Правообладателем и размещенные на Сайте или в Личном кабинете
          дополнительные требования к Пользователю и (или) условия, которые должны выполняться Пользователем для целей
          получения Услуг и (или) возможности регистрации в Личном кабинете, а также иные обязательные для оформления
          Заказов и заключения Сделок условия, являющиеся частью настоящей Оферты.
        </li>
        <li>«<strong>ФЗ № 115</strong>» — Федеральный закон от 07.08.2001 №115-ФЗ «О противодействии легализации
          (отмыванию) доходов, полученных преступным путем, и финансированию терроризма».
        </li>
      </ol>
      <p><strong>Приложение № 2</strong></p><p><strong>к Оферте Alfred.Market </strong></p><p><strong>«Лицензионные
      условия использования сервиса Alfred.Market»</strong></p><p><strong>ЛИЦЕНЗИОННЫЕ УСЛОВИЯ ИСПОЛЬЗОВАНИЯ CЕРВИСА
      ALFRED.MARKET</strong></p>
      <ol>
        <li>Правообладатель настоящим предоставляет Пользователю неисключительную пользовательскую лицензию на
          использование Сайта, в том числе Личного кабинета, а Пользователь обязуется использовать Сайт в соответствии с
          условиями настоящей Оферты и иных документов, регулирующих использование Сайта и оказание Услуг.
        </li>
        <li>Правообладатель предоставляет Пользователю неисключительную лицензию на использование Сайта в объеме
          необходимом для использования Сайта и Личного кабинета, для целей, предусмотренных в настоящей Оферте, в том
          числе: для регистрации и идентификации на Сайте, для взаимодействия на Сайте с Правообладателем, в том числе,
          но не ограничиваясь, с целью получения предусмотренных Офертой Услуг от Поверенного, а также получения
          информационно-технологических услуг от Правообладателя, запроса и получения информации, в порядке и на
          условиях, предусмотренных Офертой.
        </li>
        <li>Сайт предназначен для целей поиска информации и получения Пользователем предлагаемых на Сайте Услуг,
          оказываемых Поверенным, а также информационных услуг, оказываемых Правообладателем.
        </li>
        <li>Сайт предоставляет Пользователям возможность поиска информации об Услугах, Заказа Услуг, оплаты Услуг, а
          также иные функциональные возможности в соответствии с Контентом Сайта.
        </li>
        <li>Сайт предоставляется Правообладателем «как есть». Правообладатель не гарантирует соответствие Сайта целям и
          ожиданиям Пользователя, бесперебойную и безошибочную работу Сайта в целом и отдельных его компонентов и/или
          функций.
        </li>
        <li>Правообладатель не несет ответственности в случае невозможности получения Пользователем доступа к Сайту по
          причинам, не зависящим от Правообладателя.
        </li>
        <li>Перерывы в предоставлении Сайта допускаются и могут быть обусловлены, в том числе, но не ограничиваясь:
          <ol>
            <li>действием или бездействием третьих лиц и/или неработоспособностью оборудования и программного
              обеспечения;
            </li>
            <li>необходимым ремонтом и/или заменой оборудования и программного обеспечения, в том числе по аварийным
              обстоятельствам;
            </li>
            <li>иными техническими сбоями, которые находятся вне зоны ответственности Правообладателя.</li>
          </ol>
        </li>
        <li>Поиск и просмотр предложений на Сайте доступен всем Пользователям независимо от регистрации и авторизации.
        </li>
        <li>Получение доступа к основным функциональным возможностям Сайта требует регистрации Личного кабинета
          Пользователя.
        </li>
        <li>Если Правообладатель отдельно не согласует иное, наличие двух Личных кабинетов с одинаковыми
          регистрационными данными не допускается.
        </li>
        <li>Доступ к информации, находящейся на защищенных разделах Сайта, разрешен только зарегистрированным в Личном
          кабинете Пользователям, осуществившим вход при помощи телефона.
        </li>
        <li>Регистрацией на Сайте Пользователь подтверждает предоставление Правообладателю согласия на обработку
          персональных данных, согласие с условиями Оферты и Приложений к ней, согласие с Требованиями, Политикой
          конфиденциальности, Политикой использования файлов Cookies, а также указывает следующие данные:
          <ol>
            <li>Фамилию, имя, отчество контактного лица;</li>
            <li>Наименование ИП/ЮЛ и (или) ИНН;</li>
            <li>Контактный номер телефона;</li>
            <li>Адрес электронной почты (если применимо);</li>
            <li>Пароль.</li>
          </ol>
        </li>
        <li>Регистрируя Личный кабинет на Сайте, а равно вводя идентификационный код доступа, Пользователь подтверждает
          свое согласие с тем, что:
          <ol>
            <li>регистрационные данные (в том числе персональные данные) указаны им добровольно. В случае использования
              данных третьих лиц — указанные данные указываются с информированного и предварительного и подтвержденного
              согласия этих лиц;
            </li>
            <li>регистрационные данные (в том числе персональные данные) передаются в электронной форме по открытым
              каналам связи сети «Интернет», используются (обрабатываются, собираются, систематизируются, хранятся и
              обрабатываются другими способами) Правообладателем для реализации целей, указанных в настоящей Оферте и в
              этих же целях могут быть переданы третьим лицам;
            </li>
            <li>регистрационные данные (в том числе персональные данные) могут обрабатываться Правообладателем в целях
              продвижения Услуг путем осуществления прямых контактов с Пользователем с помощью каналов связи в
              соответствии с Офертой, Политикой конфиденциальности и Федеральным законом «О персональных данных» от
              27.07.2006 N 152-ФЗ;
            </li>
            <li>регистрационные данные (в том числе персональные данные) могут обрабатываться Правообладателем в целях
              направления Пользователю рекламы и информации по сетям электросвязи, в том числе через Интернет, в
              соответствии со ст.18 Федерального закона от 13.06.2006 № 38-ФЗ «О рекламе»;
            </li>
            <li>регистрационные данные (в том числе персональные данные), указанные Пользователем, в целях
              дополнительной защиты от мошеннических действий могут быть переданы кредитной организации, осуществляющей
              транзакции по оплате оформленных на Сайте Сделок, а также иным третьим лицам, обеспечивающим безопасность
              Правообладателя в целях осуществления переводов денежных средств по Сделке;
            </li>
            <li>данное Пользователем согласие на обработку его регистрационных данных (в том числе персональных данных)
              является бессрочным и может быть отозвано Пользователем или его законным представителем, путем направления
              письменного заявления по адресу электронной почты Правообладателя: <a
                href='mailto:mail@alfred.market'>mail@alfred.market</a>.
            </li>
          </ol>
        </li>
        <li>Пользователь вправе, по своему смотрению, предоставить Правообладателю дополнительные данные (в том числе,
          персональные данные), предоставляя которые Пользователь дает свое согласие Правообладателю на их использование
          в рамках Сделок.
        </li>
        <li>Сайт, представляющий собой в совокупности программу для ЭВМ, а также графические и аудиовизуальные
          отображения, пользовательский интерфейс в целом, элементы дизайна, текст, графические изображения и иная
          информация, размещенные на Сайте, является охраняемым результатом интеллектуальной деятельности,
          исключительное право на которые принадлежит Правообладателю.
        </li>
        <li>Правообладатель вправе без согласования с Пользователем передавать свои права и обязанности по исполнению
          обязательств перед Пользователем третьим лицам, оставаясь ответственным за действия таких третьих лиц как за
          свои собственные.
        </li>
        <li>Правообладатель вправе изменять условия любых промо-акций (если применимо) путём размещения таких изменений
          на Сайте со дня опубликования указанных новых условий.
        </li>
        <li>Правообладатель оставляет за собой право в любой момент потребовать от Пользователя подтверждения данных,
          указанных при регистрации, и запросить, в связи с этим, подтверждающие документы (в том числе документы,
          удостоверяющие личность, учредительные документы юридического лица), непредоставление которых, по усмотрению
          Правообладателя, может быть приравнено к предоставлению Пользователем недостоверной информации.
        </li>
        <li>Пользователь обязуется не использовать Сайт и/или его отдельные элементы способами и/или с целями, не
          предусмотренными настоящей Офертой, а также не осуществлять любые действия, связанные с модификацией и/или
          копированием исходного кода Сайта.
        </li>
        <li>Пользователь несет ответственность за вред, причинённый Сайту и/или любым третьим лицам, если вред причинён
          вследствие действий Пользователя.
        </li>
        <li>Регистрируясь в Личном кабинете и/или используя Сайт, Пользователь полностью и безоговорочно соглашается не
          нарушать прав на интеллектуальную собственность Правообладателя включая товарные знаки и объекты авторских и
          иных прав.
        </li>
        <li>Пользователь, а также любые привлеченные Пользователем третьи лица, не вправе:
          <ol>
            <li>«кадрировать», копировать или иным образом использовать Сайт, Контент или любую его часть на любых
              коммерческих или некоммерческих вебсайтах, мобильных приложениях и/или иных источниках информации в любых
              целях;
            </li>
            <li>предоставлять доступ, просматривать или копировать любую часть Сайта или контента при помощи роботов,
              пауков, скраперов или других автоматизированных программ, или ручным способом с любой целью без
              письменного разрешения Правообладателя;
            </li>
            <li>удалять (или разрешать удалять кому-либо) любые водяные знаки, лейблы и другие юридические или
              какие-либо указания Правообладателя, размещенные Правообладателем на Сайте;
            </li>
            <li>изменять или пытаться изменить (или разрешать кому-либо) Сайт, в том числе вносить какие-либо изменения
              с целью маскировки или изменения информации о владельце или Сайте;
            </li>
            <li>использовать Сайт или Контент как часть услуги обмена, аренды или дарения, использовать Сайт несколькими
              лицами, в иных целях, за исключением целей, оговоренных в Оферте;
            </li>
            <li>пытаться помогать, разрешать или привлекать других лиц, чтобы обойти, отключить или убрать какую-либо
              функцию или средство защиты, как, например, технические средства защиты авторских прав или
              криптографическую защиту, обеспечивающую безопасность Сайта и содержащегося на нем Контента;
            </li>
            <li>копировать, воспроизводить, изменять, раздавать, передавать, продавать, публиковать, исполнять,
              предоставлять право или распространять в любой форме какую-либо часть Сайта и/или контента;
            </li>
            <li>создавать производные работы, основанные полностью или частично на базе Сайта или контента, или
              декомпилировать, демонтажировать или реверсивно воспроизводить, а также иначе использовать любую часть
              Сайта или контента;
            </li>
            <li>использовать доступ к Сайту таким образом, что права (в том числе, но не ограничиваясь, право на объекты
              интеллектуальной собственности) какой-либо третьей стороны будут нарушены;
            </li>
            <li>загружать или передавать через Сайт или Личный кабинет любую информацию, изображения, текст, данные,
              материалы или другое содержание, которое является оскорбительным, ненавистным, непристойным,
              дискредитирующим или нарушает нормы применимого законодательства в каждом конкретном случае по усмотрению
              Правообладателя.
            </li>
          </ol>
        </li>
        <li>Любые материалы, файлы и сервисы, содержащиеся на Сайте, не могут быть воспроизведены в какой‐либо форме,
          каким‐либо способом, полностью или частично без предварительного письменного разрешения Правообладателя, в том
          числе в целях некоммерческого использования.
        </li>
        <li>Любое использование материалов Сайта из защищенных разделов и подразделов Сайта посредством воспроизведения,
          распространения, импорта оригинала и т.д. в какой‐либо форме, каким‐либо способом запрещено.
        </li>
        <li>Права на изображения, товарные знаки, а также текстовая информация и графические изображения принадлежат их
          законным правообладателям.
        </li>
        <li>В случае нарушения Пользователем условий использования Сайта и/или иной интеллектуальной собственности
          Правообладателя, включая, но не ограничиваясь, средств индивидуализации Правообладателя, Правообладатель
          вправе требовать уплаты Пользователем возмещения убытков в полном объеме. Пользователь также обязуется
          возместить убытки Правообладателя, возникшие у Правообладателя в связи с нарушением Пользователем
          исключительных прав третьих лиц на объекты интеллектуальной собственности.
        </li>
      </ol>
      <p><strong>Приложение № 3</strong></p><p><strong>к Оферте Alfred.Market </strong></p><p><strong>«Заверения и
      гарантии»</strong></p><p><strong>ЗАВЕРЕНИЯ И ГАРАНТИИ</strong></p>
      <ul>
        <li>
          <ol>
            <li>В настоящем Приложении №3 к Оферте под «<strong>Стороной</strong>» понимаются Пользователь и Поверенный,
              а положения касаются Стороны, если применимо в соответствии с правовым статусом Стороны и статусом Стороны
              в рамках Сделки.
            </li>
            <li>Сторона является индивидуальным предпринимателем или юридическим лицом (в зависимости от
              юридическо-правовой формы Стороны), надлежащим образом созданным и осуществляющим свою деятельность в
              соответствии с применимым правом, и обладает правоспособностью на заключение Сделки посредством Сайта.
            </li>
            <li>Исполнительный орган Стороны находится и осуществляет функции управления по месту регистрации
              юридического лица/индивидуального предпринимателя или по месту зарегистрированного обособленного
              подразделения Стороны и в нем отсутствуют дисквалифицированные лица.
            </li>
            <li>Лицо, заключающее Сделки от имени Стороны на Сайте, надлежащим образом уполномочено на заключение
              Сделок.
            </li>
            <li>Стороной получены все разрешения, согласия, корпоративные одобрения на заключение и исполнение Сделки
              (если применимо), которые необходимы в соответствии с применимым правом и/или учредительными документами
              Стороны.
            </li>
            <li>Сторона располагает персоналом, имуществом и материальными ресурсами, необходимыми для выполнения своих
              обязательств по Сделке, и нет никаких обстоятельств, которые могут повлиять на наличие таких возможностей
              в течение всего срока действия Сделки, заключаемой посредством Сайта.
            </li>
            <li>Сторона не выступает гарантом, поручителем или залогодателем, принявшим на себя обязательства по
              обеспечению в существенном размере исполнения обязательств третьих лиц, имеющих признаки банкротства. Для
              целей настоящего пункта «существенным размером обязательств» является такой размер обеспеченных
              обязательств, при предъявлении которого к исполнению (по условию соответствующих гарантий, поручительств,
              договоров залога) Сторона перестанет располагать достаточными возможностями для своевременного и полного
              (надлежащего) исполнения своих обязательств по Сделке, заключаемой посредством Сайта.
            </li>
            <li>Сторона в период не менее 3 (трех) лет до заключения договора на условиях Оферты или Сделки на основании
              Оферты не совершала с лицами, имеющими признаки банкротства, сделок и финансовых операций, нарушающих
              права кредиторов таких лиц, в результате совершения которых Сторона может перестать располагать
              достаточными возможностями для своевременного и полного (надлежащего) исполнения своих обязательств по
              Сделке, заключаемой посредством Сайта.
            </li>
            <li>Сторона не является участником (стороной) исполнительного, гражданского, уголовного, налогового или
              иного производства (дела), которое бы повлияло на способность Стороны исполнить свои обязательства как по
              Сделке, так и по иным обязательствам перед третьими лицами. Сторона, ее бенефициарные владельцы и лицо,
              осуществляющее функции единоличного исполнительного органа Пользователя, не обладают статусом
              подозреваемых или обвиняемых в каком-либо уголовном деле.
            </li>
            <li>Сторона не находится в процессе реорганизации или ликвидации.</li>
            <li>Сторона имеет законное право осуществлять вид экономической деятельности, предусмотренный Сделкой (имеет
              надлежащий ОКВЭД).
            </li>
            <li>Сторона, посредством исполнения Сделки, не финансирует какую-либо запрещенную законом деятельность и не
              приобретает запрещенные законом товары или услуги.
            </li>
            <li>Сторона располагает лицензиями (разрешениями), необходимыми для осуществления деятельности и исполнения
              обязательств по Сделкам (если осуществляемая по Оферте деятельность является лицензируемой), и отсутствуют
              обстоятельства, которые могут повлиять на действительность лицензии (разрешения) в течение всего срока
              действия Сделки.
            </li>
            <li>Сторона соблюдает требования законодательства в части ведения налогового и бухгалтерского учета,
              полноты, точности и достоверности отражения операций в учёте, исполнения налоговых обязательств по
              начислению и уплате налогов и сборов.
            </li>
            <li>Сторона не осуществляет и не будет осуществлять в налоговых периодах, в течение которых совершаются
              операции по Сделке, уменьшение налоговой базы и (или) суммы подлежащего уплате налога, в результате
              искажения сведений о фактах хозяйственной жизни и объектах налогообложения.
            </li>
            <li>Отсутствуют факты неуплаты/неполной уплаты налогов и/или таможенных платежей по всей цепочке
              взаимозависимых со Стороной лиц (для целей исполнения настоящего пункта взаимозависимыми лицами являются
              лица, способные оказывать влияние на условия и (или) результаты сделок, совершаемых Стороной и/или
              экономические результаты деятельности указанных лиц).
            </li>
            <li>Отсутствуют факты незаконного вывода денежных средств Стороной (или от его имени) за границу.</li>
            <li>Все операции Стороны по покупке товаров, работ и (или) услуг у своих контрагентов полностью отражены в
              первичной документации Стороны, а также в бухгалтерской, налоговой, статистической и любой иной
              отчетности, обязанность по ведению которой возлагается на Сторону.
            </li>
            <li>Стороной уплачиваются все налоги и сборы в соответствии с действующим законодательством Российской
              Федерации, а также им ведется и своевременно подается в налоговые и иные государственные органы налоговая,
              статистическая и иная государственная отчетность в соответствии с действующим законодательством Российской
              Федерации
            </li>
            <li>На момент заключения Сделки на Сайте Сторона не отвечает признакам неплатежеспособности и не признан
              несостоятельным (банкротом), также в отношении него не введена процедура банкротства/реструктуризации и
              ему не известно о поданных в отношении него заявлений третьих лиц о признании его банкротом.
            </li>
            <li>При выявлении признаков несостоятельности (банкротства), Сторона обязуется в течение 10 (десяти)
              календарных дней с момента выявления указанных признаков, уведомить Правообладателя в течение 3 (трех)
              месяцев до выявления признаков несостоятельности.
            </li>
            <li>Сторона обязуется уведомить Правообладателя о начавшейся в отношении него судебной процедуры
              банкротства/реструктуризации в течение 10 (десяти) календарных дней с даты принятия арбитражным судом
              заявления о признании Стороны банкротом.
            </li>
            <li>В случае получения Стороной решений (требований) налоговых органов вследствие нарушения Стороной
              требований законодательства, Сторона обязуется обеспечить устранение выявленных нарушений (расхождений)
              или возместить Правообладателю и/или другой Стороне убытки в размере сумм, уплаченных Правообладателем или
              другой Стороной на основании указанных решений (требований) налоговых органов. Документальным
              подтверждением возникновения указанных убытков является заверенное Правообладателем или другой Стороной
              решение (требование) налоговых органов.
            </li>
            <li>Сторона гарантирует, что является держателем корпоративных банковских карт, владельцем банковского
              счета, реквизиты которых самостоятельно предоставляет в Личном кабинете и, что у нее имеется законное
              право на распоряжение ими.
            </li>
            <li>В случае если указанные в настоящем разделе Оферты заверения об обстоятельствах окажутся недостоверными,
              Правообладатель или другая Сторона вправе требовать от Стороны возмещения понесенных ими убытков.
            </li>
          </ol>
        </li>
      </ul>
      <p><strong>Приложение № 4</strong></p><p><strong>к Оферте Alfred.Market </strong></p><p><strong>«Условия
      использования простой электронной подписи </strong></p><p><strong>и доставки юридически значимых
      сообщений»</strong></p><p><strong>УСЛОВИЯ ИСПОЛЬЗОВАНИя ПРОСТОЙ ЭЛЕКТРОННОЙ ПОДПИСИ и ДОСТАВКИ ЮРИДИЧЕСКИ ЗНАЧИМЫХ
      СООБЩЕНИЙ</strong></p>
      <ul>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <ol>
                    <li>В настоящем Приложении №4 к Оферте под «стороной» понимаются Правообладатель, Пользователь и
                      Поверенный, а положения касаются стороны, если применимо в соответствии с правовым статусом
                      стороны и статусом стороны в рамках Сделки.
                    </li>
                  </ol>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ol>
        <li>После принятия Пользователем положений Оферты на указанных в Оферте условиях, Оферта признается равнозначной
          документу на бумажном носителе, подписанному собственноручной подписью Пользователя.
        </li>
        <li>Стороны соглашаются, что заключение, изменение, расторжение Сделки, заключенной на основании настоящей
          Оферты, подписание актов, отчетов, претензий и иных документов, связанных с исполнением данной Оферты,
          производится по согласованию с Правообладателем посредством: <ol>
            <li>собственноручного подписания экземпляров Оферты на бумажном носителе;</li>
            <li>посредством системы ЭДО;</li>
            <li>в электронном виде посредством подписания документа простой электронной подписью (далее –
              «<strong>ПЭП</strong>») выражающейся в доступе к:
              <ol>
                <li>согласованной соответствующими сторонами электронной почте, посредством которой может направляться
                  подписанный скан документа;
                </li>
                <li>Личному кабинету Пользователя, в рамках которого Пользователь получает информацию о Заказах,
                  Сделках, а также иную информацию, предоставляемую через Личный кабинет;
                </li>
                <li>посредством подтверждения через СМС-сообщение, направляемого представителю Пользователя.</li>
              </ol>
            </li>
          </ol></li>
        <li>Руководствуясь положениями ч. 2 ст. 160 ГК РФ и ч. 2 ст. 6 Закона «Об электронной подписи», Пользователь и
          Правообладатель договорились о том, что все документы, соответствующие требованиям п. 3 настоящего Приложения,
          считаются подписанными собственноручно Пользователем (его уполномоченным лицом).
        </li>
        <li>Также, стороны признают, что документ подписан ПЭП Пользователем или действие на Сайте совершено
          уполномоченным представителем Пользователя в следующих случаях:
          <ol>
            <li>Пользователь зарегистрирован на Сайте с помощью номера телефона, пароля и, если применимо, электронной
              почты.
            </li>
            <li>Пользователь ввел полученный от Правообладателя СМС-код, отправленный на зарегистрированный на Сайте
              номер телефона в момент создания Личного кабинета.
            </li>
          </ol>
        </li>
        <li>Юридически значимое действие Пользователя на Сайте является совершенным уполномоченным представителем
          Пользователя, если его осуществление подтверждено одноразовым SMS-ключом (СМС-код), направленным на
          зарегистрированный на Сайте номер телефона Пользователя, для чего Пользователь должен ввести для регистрации
          на Сайте полученный СМС-код, о чем на Сайте сохраняется информация. ПЭП считается проверенной, если сверка
          сгенерированного Сайтом СМС-кода и направленного на зарегистрированный на Сайте номер телефона, сообщенного
          Пользователем, прошла успешно. СМС-код предоставляется Пользователю путем направления СМС-сообщения,
          содержащего соответствующий ключ, на зарегистрированный на Сайте номер телефона. Таким образом, СМС-код
          считается предоставленным Пользователю/полномочному на совершение соответствующих действий представителю
          Пользователя с сохранением его конфиденциальности.
        </li>
        <li>Дальнейшая защита Личного кабинета пользователя осуществляется посредством пароля Пользователя. При
          подписании документов простой электронной подписью идентификация Пользователя производится через номер
          телефона Пользователя и пароль к Личному кабинету, устанавливаемому Пользователем. Открытой частью ключа ПЭП
          является номер телефона Пользователя, а закрытой частью — пароль к нему.
        </li>
        <li>При согласовании сторон Пользователь вправе составить документ на бумажном носителе и подписать его
          надлежащим образом после чего отсканировать документ. Отсканированный документ направляется посредством
          Личного кабинета или с электронного почтового ящика, согласованного сторонами в Личном кабинете, на
          согласованный электронный почтовый ящик другой стороны.
        </li>
        <li> Стороны соглашаются и подтверждают, что электронные документы, подписанные сторонами (в т. ч. с помощью
          ПЭП), будут иметь силу документов, подписанных собственноручной подписью, в том числе в судах, в дальнейших
          взаимоотношениях сторон, при предоставлении в налоговые и иные органы, третьим лицам в предусмотренным
          действующим законодательством и Офертой случаях, а также в любых иных случаях.
        </li>
        <li>Для юридически значимых действий стороны используют только электронные почтовые ящики, согласованные в
          Личном кабинете.
        </li>
        <li>Стороны заверяют и гарантируют друг другу, что: <ol>
          <li>адреса электронных почтовых ящиков, указанные в реквизитах, принадлежат или используются только
            соответствующей стороной или лицом, уполномоченным принимать решения по заключению, изменению или
            расторжению условий настоящей Оферты и дополнений к ней;
          </li>
          <li>третьим лицам не известны пароли от Личного кабинета и электронных почтовых ящиков сторон;</li>
          <li>каждая из сторон исключила возможность подписания документов (в том числе ПЭП) неуполномоченными лицами.
          </li>
        </ol></li>
        <li>Стороны не вправе ссылаться на недействительность Оферты и дополнений к ней (включая Акты-отчеты и иные
          документы связанные с исполнением Оферты и Сделок), заключенных путем подписания ПЭП в форме электронного
          документа.
        </li>
        <li>Создавая Личный кабинет и подписывая документы в форме электронного документа ПЭП стороны берут на себя все
          правовые последствия такого способа волеизъявления.
        </li>
        <li>Если иное не предусмотрено законом, все юридически значимые сообщения влекут для получающей их Стороны
          наступление гражданско-правовых последствий с момента доставки соответствующего сообщения ей или её
          представителю.
        </li>
        <li>Сообщение считается доставленным и в тех случаях, если оно поступило лицу, которому оно направлено
          (адресату), но по обстоятельствам, зависящим от него, не было ему вручено или адресат не ознакомился с ним.
        </li>
        <li>Правообладатель уполномочен Поверенным формировать и подписывать от имени за Поверенного, в том числе,
          следующие документы в рамках Сделок и Заказов Пользователей на Сайте (если применимо): <ol>
            <li>Оферту и дополнения к ней;</li>
            <li>Счет;</li>
            <li>Счет-фактура;</li>
            <li>Акт о расхождениях;</li>
            <li>Акт-отчет;</li>
            <li>Ведомость валютного контроля;</li>
            <li>Валютный контракт;</li>
            <li>Иные документы в рамках заключения и исполнения Сделок.</li>
          </ol></li>
        <li>Пользователь обязуется оформлять и, в случае запроса Правообладателя, предоставлять Правообладателю
          документы отдельно по каждой Сделке в зависимости от системы налогообложения и условий соответствующей Сделки.
        </li>
        <li>В случае использования ЭДО при оказании Услуг любые документы оформляются в электронном виде и, по запросу
          Правообладателя или Поверенного, предоставляются Пользователем также на бумажном носителе.
        </li>
        <li>Пользователь обязуется надлежащим образом подписать и направить Правообладателю полученную документацию не
          позднее 3 (трех) рабочих дней, следующих за днем получения такой документации.
        </li>
      </ol>
      <p><strong>Приложение № 5</strong></p><p><strong>к Оферте Alfred.Market </strong></p><p><strong>«Условия
      конфиденциальности </strong></p><p><strong>и обработки персональных данных»</strong></p><p><strong>УСЛОВИЯ
      КОНФИДЕНЦИАЛЬНОСТИ И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong></p>
      <ul>
        <li>
          <ul>
            <li>
              <ul>
                <li>
                  <ol>
                    <li>В настоящем Приложении №5 к Оферте под «стороной» понимаются Правообладатель, Пользователь и
                      Поверенный, а положения касаются стороны, если применимо в соответствии с правовым статусом
                      стороны и статусом стороны в рамках Сделки.
                    </li>
                    <li>Используя Сайт, Пользователь обязуется ознакомиться, и подтверждает свое согласие с Политикой
                      конфиденциальности, размещенной на Сайте в разделе <a
                        href='https://alfred.market/legal'>https://alfred.market/legal</a> (далее –
                      «<strong>Политика</strong>»), соглашаясь соблюдать все положения указанной Политики.
                    </li>
                    <li>Любая персональная информация обрабатывается на условиях и для целей, определённых в Политике.
                    </li>
                    <li>Стороны Сделки признают, что относящиеся к Сделке документы, в том числе, дополнительные
                      соглашения, Требования, Приложения к Оферте, документы, содержащие сведения о сторонах Сделки,
                      иные сведения согласованные в рамках Личного кабинета Пользователя, в том числе содержащиеся в
                      Поручении, отчеты, любая документация, а также иная письменная информация, предоставленная в
                      рамках заключения или исполнения Сделки, являются конфиденциальной информацией, и ни одна из
                      сторон не может устно или письменно разглашать такую конфиденциальную информацию какому-либо
                      третьему лицу без предварительного письменного разрешения другой стороны в течение всего срока
                      действия Оферты, а также в течение в течение 5 (Пяти) лет после его прекращения (если больший срок
                      не предусмотрен законодательством применимым к стороне).
                    </li>
                    <li>Стороны после получения любой конфиденциальной информации не вправе раскрывать и распространять
                      ее, и обязуются обрабатывать такую информацию с той степенью заботливости и осмотрительности,
                      которая применяется стороной относительно собственной информации того же уровня важности.
                    </li>
                    <li>Стороны вправе предоставить конфиденциальную информацию, за исключением информации, составляющей
                      охраняемую законом тайну, третьему лицу только при совокупном соблюдении следующих условий:
                    </li>
                  </ol>
                </li>
              </ul>
            </li>
          </ul>
          <ol>
            <li>наличие письменного согласия другой стороны;</li>
            <li>заключение между стороной и лицом, которому предоставляется конфиденциальная информация, соглашения об
              обеспечении конфиденциальности такой информации на условиях, аналогичных изложенным в настоящем разделе
              Оферты.
              <ul>
                <li>
                  <ol>
                    <li>Положения настоящего раздела Оферты не распространяются на следующие случаи раскрытия
                      информации:
                    </li>
                  </ol>
                </li>
              </ul>
            </li>
            <li>согласно требованиям действующего законодательства, применимого к стороне;</li>
            <li>государственным органам по их запросам в объёме, предусмотренном законодательством применимым к стороне.
              <ul>
                <li>
                  <ol>
                    <li>Стороны несут ответственность за виновное раскрытие (разглашение) конфиденциальной информации в
                      нарушение условий Сделки.
                    </li>
                    <li>В случае установления вины стороны в разглашении конфиденциальной информации, в том числе
                      информации о другой стороне, условиях Сделки, иной конфиденциальной информации либо ее
                      несанкционированном использовании, другая сторона имеет право на возмещение документально
                      подтвержденных убытков, возникших в результате нарушения стороной условий конфиденциальности.
                    </li>
                    <li>Информация, полученная стороной, не рассматривается как конфиденциальная и, соответственно, у
                      стороны не возникает обязательств по сохранению конфиденциальности в отношении такой информации,
                      если она удовлетворяет одной из следующих характеристик:
                    </li>
                  </ol>
                </li>
              </ul>
            </li>
            <li>информация во время ее раскрытия является публично известной;</li>
            <li>информация представлена стороне с письменным указанием на то, что она не является конфиденциальной;</li>
            <li>информация получена от любого третьего лица на законных основаниях.
              <ul>
                <li>
                  <ol>
                    <li>Обработка персональных данных Пользователей осуществляется в течение всего срока использования
                      Пользователем Сайта, а персональные данные могут храниться в течение 5 (пяти) лет с даты
                      последнего использования Сайта Пользователем, если Пользователь не направит Правообладателю
                      требования удалить данные Пользователя в предусмотренном Политикой порядке.
                    </li>
                    <li>Уведомление об отзыве согласия на обработку персональных данных направляются в письменном виде
                      на почтовый адрес <a href='mailto:mail@alfred.market'>mail@alfred.market</a> или через Личный
                      кабинет.
                    </li>
                    <li>Уведомление должно содержать перечень персональных данных, согласие на обработку которых
                      отзывается, номер телефона, на который зарегистрирован Личный кабинет (для отзыва банковских
                      данных — подтверждение принадлежности указанных данных автору уведомления), а также
                      собственноручную подпись. Уведомления, направленные иным способом (например, в чате службы
                      поддержки, по электронной почте и так далее), копии уведомлений, а также уведомления, не
                      содержащие все необходимые сведения, не принимаются к рассмотрению.
                    </li>
                    <li>Пользователь информирован и согласен с тем, что Правообладатель осуществляет сбор, запись,
                      систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, анализ и
                      использование, передачу (распространение, предоставление, доступ), поручение обработки третьим
                      лицам, получение от третьих лиц, обезличивание, блокирование, удаление, уничтожение
                      регистрационных данных Пользователя (в том числе, персональных данных).
                    </li>
                    <li>Приобретая Услуги на Сайте, Пользователь предоставляет Правообладателю согласие на списание
                      денежных средств по реквизитам корпоративных банковских карт, предоставленных Пользователем при
                      оформлении Заказа на Услугу или реквизиты которых указаны Пользователем в Личном кабинете,
                      согласие на обработку которых не отозвано Пользователем.
                    </li>
                  </ol>
                </li>
              </ul>
            </li>
          </ol>
        </li>
      </ul>
      <ol>
        <li>Пользователь гарантирует наличие согласия работников, привлекаемых третьих лиц (персональные данные которых
          предоставлены в ходе исполнения обязательств в рамках Оферты) на предоставление их персональных данных
          Пользователю, а также гарантирует, что указанные лица уведомлены об осуществлении обработки их персональных
          данных сторонами.
        </li>
        <li>Пользователь информируется и соглашается, что, если применимо, сокрытие корпоративной банковской карты в
          Личном кабинете не означает отзыв согласия на обработку этой части персональных данных.
        </li>
        <li>Пользователь, оплачивая Услуги и давая согласие на списание денежных средств, в т.ч. образовавшейся перед
          Правообладателем задолженности, понимает и соглашается, что обработка скрытых им персональных данных (данных о
          платежных средствах) Правообладателем осуществляется в целях, отраженных в настоящей Оферте, и предусмотренных
          действующим законодательством о защите персональных данных.
        </li>
        <li>Пользователь осуществляет оплату в рамках счетов, полученных в Личном кабинете.</li>
        <li>Изменение Пользователем банковских реквизитов и/или иных регистрационных данных производится с уведомлением
          Правообладателя.
        </li>
        <li>При выборе способа оплаты с использованием счета или корпоративной банковской карты, или иных доступных
          сервисов, позволяющих производить оплату заказов, оформленных на Сайте, Пользователь предоставляет согласие на
          обработку платежных персональных данных.
        </li>
        <li>Согласие на обработку персональных данных корпоративной банковской карты может быть отозвано Пользователем
          посредством уведомления, направленного по адресу электронной почты: <a
            href='mailto:mail@alfred.market'>mail@alfred.market</a>.
        </li>
        <li>Пользователь соглашается, что Правообладатель также праве использовать технологию «cookies» в рамках правил,
          установленных политикой использования правил cookies, размещенной на Сайте.
        </li>
      </ol>
      <p><strong>Приложение № 6</strong></p><p><strong>к Оферте Alfred.Market</strong></p><p><strong>«Ответственность,
      претензии, форс-мажор»</strong></p><p><strong>ОТВЕТСТВЕННОСТЬ, ПРЕТЕНЗИИ, ФОРС-МАЖОР</strong></p>
      <ul>
        <li>
          <ol>
            <li>В настоящем Приложении №6 к Оферте под «стороной» понимаются Правообладатель, Пользователь и Поверенный,
              а положения касаются стороны, если применимо в соответствии с правовым статусом стороны и статусом стороны
              в рамках Сделки.
            </li>
            <li>Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение обязательств по
              Сделке при возникновении обстоятельств непреодолимой силы, то есть чрезвычайных и непредотвратимых при
              данных условиях обстоятельств, под которыми понимаются: запретные действия властей, гражданские волнения,
              эпидемии, блокада, эмбарго, землетрясения, наводнения, пожары или другие стихийные бедствия, военные
              действия.
            </li>
            <li>В случае наступления указанных выше обстоятельств сторона, которая не может исполнить надлежащим образом
              обязательство, обязана в течение 5 (Пяти) рабочих дней уведомить об этом другую сторону.
            </li>
            <li>Поверенный в течение 5 (Пяти) рабочих дней со дня наступления обстоятельств непреодолимой силы, либо со
              дня получения уведомления о наступлении обстоятельств непреодолимой силы, обязан вернуть Пользователю
              полученные, но не переведенные Поставщику или Субагенту денежные средства по Сделке.
            </li>
            <li>Обязательства сторон из Сделки действуют до момента исполнения сторонами Сделки всех обязательств по
              Сделке.
            </li>
            <li>В случае нарушения сроков исполнения Поручения по Сделке Пользователь вправе потребовать от Поверенного
              уплаты штрафа (пени) в размере 0,3% от суммы Вознаграждения Поверенного за каждый день просрочки.
            </li>
            <li>В случае несвоевременного согласования актов, необходимых для уплаты Вознаграждения Поверенного,
              несвоевременной уплаты Вознаграждения Поверенного или Правообладателя (если применимо) или
              несвоевременного возмещения заранее согласованных расходов Поверенного Правообладатель или Поверенный
              вправе потребовать от Пользователя уплаты штрафа (пени) в размере 0,3% от не уплаченной в срок суммы за
              каждый день просрочки уплаты или предоставления соответствующих документов.
            </li>
            <li>Уплата штрафов (пени) не освобождает стороны от исполнения своих обязательств по Сделке.</li>
            <li>Претензии о качестве, количестве и иных условиях Сделки предъявляются непосредственно Поверенному.</li>
            <li>Правообладатель не является представителем или агентом Поверенного по приему и рассмотрению претензий о
              качестве, количестве и иным УСЛОВИЯМ СДЕЛКИ, не обеспечивает передачу полученных от Пользователей
              претензий Поверенному, не рассматривает поступившие претензии и не направляет по ним ответ.
            </li>
            <li>Ответственность за рассмотрение претензии, направление ответа, а также за возврат денежных средств по
              Сделкам, равно как за выплату неустоек, штрафов и иных убытков по Сделкам лежит на Поверенном, и
              соответствующие требования в отношении Сделки должны быть предъявлены непосредственно Поверенному.
            </li>
            <li>Правообладатель не несет ответственности за сбои услуг связи, что повлекло утерю какой-либо информации,
              а также иные неблагоприятные для Пользователей последствия.
            </li>
            <li>Правообладатель не несёт ответственности за любые нарушения или неисполнение Оферты, вызванные
              причинами, находящимися вне разумного контроля Правообладателя, включая, помимо прочего, стихийные
              бедствия, войны, терроризм, беспорядки, эмбарго, действия гражданских или военных властей, иные
              ограничения (в том числе являющиеся следствием карантина), наложенные органами государственной власти,
              органами государственной власти субъектов и местного самоуправления, пожары, наводнения, несчастные
              случаи, забастовки.
            </li>
            <li>Правообладатель не несет ответственности за какие-либо косвенные/непрямые убытки и/или упущенную выгоду
              Пользователя и/или третьих лиц вне зависимости от того, мог ли Правообладатель предвидеть возможность
              причинения таких убытков в конкретной ситуации или нет.
            </li>
            <li>Совокупный размер ответственности Правообладателя в рамках обязательств Правообладателя из Оферты,
              включая размер штрафных санкций (пеней, неустоек) и (или) возмещаемых убытков, по любому иску или
              претензии в отношении Правообладателя, в любом случае не может превышать размер 100.000 (сто тысяч) рублей
              РФ.
            </li>
            <li>Стороны соглашаются по возможности разрешать любые споры путем переговоров.</li>
            <li>При невозможности достижения согласия все возможные споры, вытекающие из настоящей Оферты или Сделок,
              подлежат разрешению в соответствии с действующим законодательством Российской Федерации в Арбитражном суде
              города Москвы, если императивными требованиями законодательства или соглашением сторон не предусмотрено
              иное.
            </li>
            <li>Настоящая Оферта и Сделки, заключаемые на основании Оферты, Приложений к ней, Требований, согласованных
              в рамках Личного кабинета, и Поручений, а также исполнение условий Оферты и Сделок регулируются в
              соответствии с законодательством Российской Федерации.
            </li>
          </ol>
        </li>
      </ul>
    </DocumentModal>
  )
}
