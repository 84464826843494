import { onError } from '@apollo/client/link/error'
import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import { useMemo } from 'react'
import { relayStylePagination } from '@apollo/client/utilities'

export const fetchPolicy = {
  typePolicies: {
    Query: {
      fields: {
        users: {
          ...relayStylePagination(),
        },
        orders: {
          ...relayStylePagination(),
        },
      },
    },
  },
}

export const createApolloClient = () => {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      });
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  });

  const httpLink = new HttpLink({
    uri: '/api/graphql'
  });

  return new ApolloClient({
    link: from([errorLink, httpLink]),
    //uri: '/api/graphql',
    connectToDevTools: true,
    cache: new InMemoryCache(fetchPolicy),
    defaultOptions: {
      mutate: {
        //fetchPolicy: 'no-cache',
      },
    }
  })
}

export const useApollo = () => {
  return useMemo(() => createApolloClient(), [])
}
