import React from 'react'
import * as Chakra from '@chakra-ui/react'
import { FormControl, Text, TextareaProps } from '@chakra-ui/react'
import { FormLabel } from '../FormLabel'
import _ from 'lodash'
import { FieldProps } from 'formik'

interface TextAreaProps extends TextareaProps {
  label?: string
  isInvalid?: boolean
  isRequired?: boolean
  name: string
  error?: string
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(function TextArea(
  { label, isInvalid, name, error, isRequired, ...props },
  ref
) {
  return (
    <FormControl isInvalid={isInvalid}>
      {label && (
        <FormLabel name={name}>
          {label}
          {isRequired && (
            <Text as="span" fontSize="15px" color="red.500">
              {' '}
              *
            </Text>
          )}
        </FormLabel>
      )}
      <Chakra.Textarea
        ref={ref}
        borderWidth={1}
        borderColor="blackAlpha.200"
        bg="white"
        id={name}
        minHeight={['140px', '160px']}
        resize="none"
        errorBorderColor="red.300"
        fontSize="1rem"
        _focusVisible={{
          zIndex: 1,
          borderColor: 'gray.400',
          boxShadow: '0 0 0 1px var(--chakra-colors-gray-400)',
        }}
        {...props}
      />
      {error && isInvalid && (
        <Chakra.Box fontSize="0.7rem" color="red.500" mt="4px">
          {error}
        </Chakra.Box>
      )}
    </FormControl>
  )
})

export interface TextAreaFormProps extends Omit<TextAreaProps, 'form'>, FieldProps {}

export const TextAreaForm = React.forwardRef<HTMLTextAreaElement, TextAreaFormProps>(
  function TextAreaForm({ field, form, ...props }, ref) {
    const error = _.get(form.errors, field.name)

    return (
      <TextArea
        ref={ref}
        isInvalid={_.has(form.errors, field.name) && _.has(form.touched, field.name)}
        error={typeof error === 'string' ? error : 'неизвестный формат ошибки'}
        {...field}
        {...props}
      />
    )
  }
)
