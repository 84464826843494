import { Section } from '../../components/Section'
import { Container } from '../../components/Container'
import { Box, Center, Flex, Grid, HStack, Image, Stack } from '@chakra-ui/react'
import { HeadingPromo } from '../../components/HeadingPromo'
import React, { ReactNode } from 'react'
import Icon from './images/icon.png'
import Bg from './images/bg.svg'
import { ButtonPromo } from '../../components/ButtonPromo'
import { useNavigate } from 'react-router-dom'

export const HowDoesItWork = () => {
  const navigate = useNavigate()

  return (
    <Section
      position="relative"
      backgroundColor="#F8F9FF"
    >
      <Container>
        <Stack spacing="60px">
          <Flex justifyContent="center" textAlign="center" position="relative" zIndex={1}>
            <HeadingPromo>Как это работает?</HeadingPromo>
          </Flex>
          <Grid templateColumns={['1fr', '1fr', '1fr 1fr']}>
            <Box display={['none', 'none', 'flex']} alignItems="center" position="relative" zIndex={1}>
              <Image maxW={['380px', '380px', '300px', '380px']} src={Icon} alt="Icon" />
            </Box>
            <Flex left={['0', '0', '-30px']} height="fit-content" position="relative">
              <Stack spacing="40px" position="relative" zIndex={1}>
                <Item count="1">
                  Заполните данные по заявке и приложите инвойс
                </Item>
                <Item count="2">
                  Согласуйте условия сделки и подпишите оферту
                </Item>
                <Item count="3">
                  В день сделки мы согласуем с Вами курс для перевода и направим Вам счет на оплату в рублях РФ
                </Item>
                <Item count="4">
                  После оплаты счета мы переведем денежные средства поставщику
                </Item>
                <Item count="5">
                  После успешного перевода направим Вам SWIFT и закрывающие документы по сделке
                </Item>
              </Stack>
              <Box
                position="absolute"
                left="29px"
                top={0}
                bottom={0}
                width="1px"
                height="100%"
                backgroundColor="#dbd7ec"
              />
            </Flex>
          </Grid>
          <Center position="relative" zIndex={1}>
            <ButtonPromo
              width="fit-content"
              onClick={() => navigate('/enter')}
            >
              Оставить заявку
            </ButtonPromo>
          </Center>
        </Stack>
      </Container>
      <Box
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
        width="100%"
        height="100%"
        backgroundImage={`url(${Bg})`}
        backgroundPosition="top left"
        backgroundRepeat="no-repeat"
        backgroundSize="contain"
        opacity={[0.2, 0.2, 0.8]}
      />
    </Section>
  )
}

interface ItemProps {
  count: string
  children: ReactNode
}

const Item: React.FC<ItemProps> = ({ count, children }) => {
  return (
    <HStack spacing="24px" alignItems="center">
      <Center
        flex="none"
        boxSize="58px"
        color="white"
        fontWeight={600}
        borderRadius="full"
        fontSize="1.3rem"
        borderWidth="6px"
        borderColor="#F8F9FF"
        background="linear-gradient(137deg, #B4B3F0 9%, #6A47B7 103.5%)"
      >
        {count}
      </Center>
      <Box>
        {children}
      </Box>
    </HStack>
  )
}
