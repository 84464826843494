import { Center, Spinner } from '@chakra-ui/react'
import { useCurrentUser } from '../../state/useCurrentUser'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from '../../shared/layouts/Layouts'

export const Logout = () => {
  const { logout } = useCurrentUser()
  const navigate = useNavigate()

  useEffect(() => {
    logout().then(() => navigate('/'))
  }, [])

  return (
    <Layout>
      <Center flex={1}>
        <Spinner boxSize="18px"/>
      </Center>
    </Layout>
  )
}
