import { Box, Button, Flex, Image, Stack } from '@chakra-ui/react'
import { MenuItem } from './components/MenuItem'
import { LuBuilding2, LuFile, LuLogOut, LuRocket } from 'react-icons/lu'
import { Profile } from './components/Profile'
import { useCurrentUser } from 'state/useCurrentUser'
import { useNavigate } from 'react-router-dom'
import Logo from '../../../images/logo.svg'
import React from 'react'

export const Menu = () => {
  const { isControl } = useCurrentUser()
  const navigate = useNavigate()

  return (
    <Flex flexDirection="column" width="260px" justifyContent="space-between">
      <Stack spacing="18px" padding="24px 12px 24px 20px" width="100%">
        <Flex justifyContent="center" pt="10px" pb="20px" borderBottomWidth={1}>
          <Image maxW="105px" src={Logo} alt="Logo" />
        </Flex>
        <Profile />
        {!isControl && (
          <Button
            onClick={() => navigate('/orders/create')}
            variant="primary"
            size="sm"
            height="34px"
          >
            Новая заявка
          </Button>
        )}
        <Stack spacing="4px">
          {isControl ? (
            <>
              <MenuItem icon={LuFile} href="/control/orders">
                Заявки
              </MenuItem>
              <MenuItem icon={LuRocket} href="/control/users">
                Пользователи
              </MenuItem>
            </>
          ) : (
            <>
              <MenuItem icon={LuFile} href="/orders">
                Заявки
              </MenuItem>
              <MenuItem icon={LuBuilding2} href="/companies">
                Мои компании
              </MenuItem>
            </>
          )}
        </Stack>
      </Stack>
      <Box padding="24px 12px 24px 20px">
        <MenuItem isExact icon={LuLogOut} href="/logout">
          Выйти
        </MenuItem>
      </Box>
    </Flex>
  )
}
