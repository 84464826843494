import React from 'react'
import { DocumentModal } from 'components/DocumentModal'

interface DocCookiesModalProps {
  isOpen: boolean
  onClose: () => void
}

export const DocCookiesModal: React.FC<DocCookiesModalProps> = ({ isOpen, onClose }) => {
  return (
    <DocumentModal isOpen={isOpen} onClose={onClose}>
      <p><strong>Политика использования файлов cookie</strong></p>
      <ol>
        <li><strong>ОСНОВНЫЕ ПОЛОЖЕНИЯ</strong>
          <ol>
            <li>Политика использования на сайте <a href="https://alfred.market/">https://alfred.market/</a> (далее –
              «<strong>Сайт</strong>») компании ООО «Альфред» (далее – «<strong>Компания</strong>») файлов cookie (далее
              — «<strong>Политика</strong>») определяет правила, по которым Компания собирает и обрабатывает информацию
              о пользователях сайта.
            </li>
            <li>Настоящая Политика является частью Политики конфиденциальности Компании.</li>
            <li>Политика описывает типы файлов cookie, цели их использования и способы, с помощью которых можно от них
              отказаться.
            </li>
            <li> Файлы cookie — это небольшие фрагменты данных с информацией о посещении Сайта, которые сервер
              отправляет на Ваше устройство. Файлы cookie запоминают информацию о ваших предпочтениях, позволяют в более
              удобном для Вас режиме просматривать посещенные сайты в течение определенного времени. Например, благодаря
              файлам cookie Сайт предлагает Вам просматривать информацию на том языке, которым Вы обычно пользуетесь.
            </li>
            <li>Компания может использовать файлы cookie для того, чтобы:</li>
          </ol>
        </li>
      </ol>
      <ul>
        <li>помогать вам оставаться авторизованным на Сайте;</li>
        <li>улучшать качество вашей работы с Сайтом;</li>
        <li>показывать приоритетную для Вас информацию;</li>
        <li>сохранять ваши настройки предпочтений;</li>
        <li>отображать рекламу, которая может вас заинтересовать;</li>
        <li>анализировать статистику использования Сайта.
          <ol>
            <li>На основании данных, полученных с помощью файлов cookie, Компания разрабатывает наиболее полезный
              функционал для Сайта, которым вы пользуетесь, проводит статистические и маркетинговые исследования,
              исправляет ошибки на Сайте и тестирует новые функции для повышения производительности Сайта,
              персонализирует и показывает наиболее релевантную для Вас информацию.
            </li>
            <li>Компания не допускает применения рекомендательных технологий, которые нарушают права и законные интересы
              граждан и организаций, а также не допускает применение рекомендательных технологий в целях предоставления
              информации с нарушением законодательства Российской Федерации. Под рекомендательными технологиями здесь и
              далее понимаются информационные технологии предоставления информации на основе сбора, систематизации и
              анализа сведений, относящихся к предпочтениям пользователей сети «Интернет», находящихся на территории
              Российской Федерации».
            </li>
          </ol>
        </li>
      </ul>
      <ol>
        <li><strong>КАКИЕ ФАЙЛЫ СOOKIE ИСПОЛЬЗУЮТСЯ</strong>
          <ol>
            <li>Компания использует различные типы файлов cookie. Основная задача в их использовании - это обеспечить
              удобство вашей работы с Сайтом, поддерживать высокий уровень безопасности в их работе, вести подсчет
              аудитории, помогать в проведении оплат, переходах по страницам и прочее. Все файлы cookie Компании
              относятся к одному из следующих типов:
              <ol>
                <li>Технические файлы cookie, которые необходимы для правильной работы и предоставления полного
                  функционала Сайта.
                </li>
                <li>Аналитические и маркетинговые файлы cookie, которые позволяют:</li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
      <ul>
        <li>распознавать пользователей Сайта, подсчитывать их количество и собирать информацию, например, о
          произведенных операциях, посещенных страницах и просмотрах на Сайте;
        </li>
        <li>идентифицировать ваше аппаратное или программное обеспечение, например, тип браузера или устройство;</li>
        <li>собирать информацию о том, как вы взаимодействуете с Сайтом, например, приобретены ли услуга или продукт;
        </li>
        <li>обеспечивать релевантность рекламы, повышать эффективность рекламных кампаний.
          <ul>
            <li>
              <ol>
                <li>Прочие файлы cookie выполняют различные служебные функции и делают удобнее работу с Сайтом и его
                  функционалом. Эти файлы помогают запомнить состояние страницы, выбрать регион, сохранить персональные
                  предпочтения и прочее.
                </li>
              </ol>
            </li>
          </ul>
          <ol>
            <li>Компания может применять веб-маяки (пиксельные теги) для доступа к файлам cookie, ранее размещенным на
              вашем устройстве. Их использование необходимо для анализа ваших действий при работе с Сайтом путем доступа
              и использования файлов cookie, хранящихся на Вашем устройстве, для сбора статистики о работе Сайта.
            </li>
            <li>Компания может использовать на Сайте инструмент веб-аналитики Яндекс.Метрика, который позволяет собирать
              обезличенную информацию об источниках трафика, посещаемости Сайта и оценивать эффективность рекламы.
            </li>
            <li>Для учета посетителей с помощью сервиса Яндекс.Метрика используются анонимные идентификаторы браузеров,
              которые сохраняются в файлах cookie. Подробнее о файлах cookie Яндекс.Метрики можно прочитать на
              странице <a href="https://yandex.ru/support/metrica/general/cookie-usage.html"
                          target="_blank">https://yandex.ru/support/metrica/general/cookie-usage.html</a>.
            </li>
            <li>Компания также может использовать Инструмент веб-аналитики Google Analytics, который позволяет собирать
              обезличенную информацию об источниках трафика, посещаемости сайта и оценивать эффективность рекламы. Для
              учета посетителей Google Analytics использует анонимные идентификаторы браузеров, которые сохраняются в
              файлах cookie. Подробнее о файлах cookie Google Analytics можно прочитать на странице <a
                href="https://policies.google.com/technologies/partner-sites?hl=en-US"
                target="_blank">https://policies.google.com/technologies/partner-sites?hl=en-US</a>.
            </li>
            <li>Отключение некоторых указанных типов файлов cookie может привести к тому, что использование отдельных
              разделов или функций Сайта станет невозможным.
            </li>
            <li>Доступ к разделам Сайта, для которых нужна авторизация, обеспечивается техническими файлами cookie и без
              их использования Сайт может работать некорректно.
            </li>
          </ol>
        </li>
      </ul>
      <ol>
        <li><strong>РЕКОМЕНДАТЕЛЬНЫЕ ТЕХНОЛОГИИ</strong>
          <ol>
            <li>Для алгоритмических вычислений Компания может использовать данные, полученные от пользователей Сайта, а
              также техническую информацию, собираемую автоматически, в том числе информацию о том, как пользователь
              пользуется Сайтом и информацию об устройстве пользователя, в том числе (если применимо):
            </li>
          </ol>
        </li>
      </ol>
      <ul>
        <li>данные о действиях пользователя на Сайте;</li>
        <li>данные о запросах пользователя на Сайте;</li>
        <li>IP адрес;</li>
        <li>файлы cookies;</li>
        <li>длительность пользовательской сессии;</li>
        <li>точки входа (сторонние сайты, с которых пользователь по ссылке переходит на сайт);</li>
        <li>точки выхода (ссылки на сайте, по которым пользователь переходит на сторонние сайты);</li>
        <li>страна пользователя;</li>
        <li>геопозиция;</li>
        <li>регион пользователя;</li>
        <li>часовой пояс, установленный на устройстве пользователя;</li>
        <li>провайдер пользователя;</li>
        <li>браузер пользователя;</li>
        <li>ОС пользователя;</li>
        <li>информация об устройстве пользователя;</li>
        <li>параметры экрана (разрешение, глубина цветности, параметры размещения страницы на экране);</li>
        <li>дата и время посещения сайта;</li>
        <li>источник перехода (UTM метка);</li>
        <li>данные, содержащиеся в Личном кабинете пользователя, зарегистрированного на Сайте;</li>
        <li>данные сетевого трафика.
          <ol>
            <li>Компания использует рекомендательные технологии для формирования персональных и неперсональных подборок
              на Сайте, обработки запросов пользователей Сайта при поиске услуг в целях быстрого предварительного отбора
              услуг, из которых пользователь Сайта может выбрать наиболее подходящие.
            </li>
            <li>Алгоритмы Сайта применяются с целью подобрать для пользователя Сайта наиболее релевантные услуги на
              основании его действий на Сайте.
            </li>
            <li>Процесс работы рекомендательных технологий Компании заключается в следующем:</li>
          </ol>
        </li>
        <li>формируется набор данных о последней активности пользователя;</li>
        <li>на основании этого набора делается предсказание его последующих действий набором алгоритмов;</li>
        <li>результаты работы нескольких алгоритмов объединяются и могут формировать рекомендации, подходящие
          пользователю;
        </li>
        <li>на основании схожести характеристик услуг и информации о пользователе отобранные рекомендации могут
          досортировываются и сохраняться для выдачи пользователю;
        </li>
        <li>на этапе выдачи услуг заранее рассчитанные рекомендации перемешиваются в соответствии с бизнес-правилами.
        </li>
      </ul>
      <ol>
        <li><strong>ХРАНЕНИЕ ФАЙЛОВ COOKIE НА ВАШЕМ УСТРОЙСТВЕ</strong>
          <ol>
            <li>Компания использует сессионные файлы cookie, чтобы Вам было удобнее работать с Сайтом. Например, могут
              запоминаться данные Вашей авторизации на Сайте и при переходе в другую доменную зону уже не нужно заново
              проходить процесс авторизации. Срок действия сессионных файлов cookie истекает в конце сессии (когда вы
              закрываете страницу или окно браузера).
            </li>
            <li>Компания также может использовать файлы cookie, которые сохраняются в течение более длительного периода,
              например, чтобы запомнить ваши предпочтения на Сайте, такие как язык или местоположение.
            </li>
            <li>Срок хранения данных зависит от типа файлов cookie.</li>
            <li>Файлы cookie будут автоматически удалены после того, как выполнят свою задачу.</li>
            <li>Компания использует информацию, содержащуюся в файлах cookie в течение сроков и на условиях, указанных в
              настоящей Политике, а также в Политике конфиденциальности.
            </li>
          </ol>
        </li>
        <li><strong>УПРАВЛЕНИЕ COOKIE ФАЙЛАМИ</strong>
          <ol>
            <li>При первом посещении Сайта во всплывающем окне (либо с помощью другого технического решения) Вам может
              быть предложено принять типы файлов cookie, которые будут записаны на вашем устройстве.
            </li>
            <li>При этом, технические файлы cookie устанавливаются автоматически при загрузке страницы, если иное не
              указано в настройках браузера.
            </li>
            <li>Если Вы одобрили использование файлов cookie, но потом захотели изменить свое решение, то сделать это
              можно самостоятельно, удалив сохраненные файлы в Вашем браузере.
            </li>
            <li>Компания не требует обязательного согласия на установку файлов cookie на ваше устройство при
              использовании Сайта.
            </li>
            <li>Если вы не хотите, чтобы файлы cookie сохранялись на вашем устройстве, отключите эту опцию в настройках
              браузера.
            </li>
            <li>Сохраненные файлы cookie также можно удалить в любое время в системных настройках браузера.</li>
            <li>Вы можете изменить настройки браузера, чтобы принимать или отклонять по умолчанию все файлы cookie либо
              файлы cookie с определенных сайтов, включая Сайт.
            </li>
            <li>Если Вы одобрили использование файлов cookie на одной из страниц Сайта, это будет означать, что такое
              согласие дано вами для использования файлов cookie на всех страницах Сайта включая Личный кабинет.
            </li>
          </ol>
        </li>
        <li><strong>ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</strong>
          <ol>
            <li>Информация, которая собрана с помощью файлов cookie, размещенных на Вашем устройстве, хранится в
              соответствии с применимым законодательством согласно условиям настоящей Политики и Политики
              конфиденциальности.
            </li>
            <li>Информация, собранная с помощью файлов cookie, размещенных на Вашем устройстве, может быть передана и
              доступна Компании и/или партнерам на условиях Политики конфиденциальности.
            </li>
            <li>Использование вне Сайта собранной информации может быть предметом отдельных пользовательских соглашений
              доступных на Сайте.
            </li>
            <li>Компания может также предоставить вам возможность отказаться от персонализации рекламы, в соответствии с
              законодательством и применимым правилами.
            </li>
          </ol>
        </li>
      </ol>
      <p><strong>Дата публикации редакции настоящей Политики: 10.10.2024 г.</strong></p>
    </DocumentModal>
  )
}
