import { Section } from '../../components/Section'
import { Container } from '../../components/Container'
import { Center, Grid, Image, Stack, Text } from '@chakra-ui/react'
import { HeadingPromo } from '../../components/HeadingPromo'
import Case from './images/case.png'

export const Services = () => {
  return (
    <Section>
      <Container pt="40px">
        <Grid
          background="linear-gradient(269deg, #D5D9FF 0.95%, #E6E5F9 42.88%, #F5F6F1 84.8%, #F2F3E6 126.72%)"
          templateColumns={['1fr', '1fr', '1fr 0.5fr', '1fr 0.5fr']}
          padding={['24px', '30px', '40px', '24px 60px']}
          borderRadius="24px"
          gap={['20px', '20px', '40px']}
        >
          <Stack spacing="20px" justifyContent="center" order={[2, 2, 1]}>
            <HeadingPromo>
              Услуги для участников ВЭД
            </HeadingPromo>
            <Stack spacing="14px" fontSize="xl" fontWeight={300}>
              <Text>
                Наш сервис предлагает быстрые и безопасные переводы денежных средств в Китай для юридических лиц и индивидуальных предпринимателей.
              </Text>
              <Text>
                Мы понимаем важность надежных финансовых операций и предоставляем решения, которые соответствуют международным стандартам.
              </Text>
            </Stack>
          </Stack>
          <Center order={[1, 1, 2]}>
            <Image maxW={['120px', '200px', '320px', '400px']} src={Case} alt="Case" />
          </Center>
        </Grid>
      </Container>
    </Section>
  )
}
