import React, { ReactNode } from 'react'
import * as Chakra from '@chakra-ui/react'
import { FormLabel } from '../FormLabel'
import {
  Input,
  InputProps,
  InputRightElementProps,
  Spinner,
  Text,
} from '@chakra-ui/react'
import _ from 'lodash'
import { FieldProps } from 'formik'

export interface InputGroupProps extends InputProps {
  label?: string
  isInvalid?: boolean
  name?: string
  error?: string | null
  isLoading?: boolean
  isRequired?: boolean
  rightAddon?: ReactNode
  addonProps?: any
  rightElement?: ReactNode
  size?: string
  rightElementProps?: InputRightElementProps
  leftElement?: ReactNode
  isDisabled?: boolean
  description?: string
  leftAddon?: ReactNode
}

export const InputGroup = React.forwardRef<HTMLInputElement, InputGroupProps>(
  function InputGroup(
    {
      label,
      isInvalid,
      name,
      error,
      rightAddon,
      isLoading,
      isRequired,
      addonProps,
      size = 'md',
      rightElement,
      rightElementProps,
      isDisabled,
      description,
      leftAddon,
      leftElement,
      ...props
    },
    ref
  ) {
    return (
      <Chakra.FormControl isInvalid={isInvalid}>
        {label && (
          <FormLabel name={name} isDisabled={isDisabled}>
            {label}
            {isRequired && (
              <Text as="span" fontSize="15px" color="red.500">
                {' '}
                *
              </Text>
            )}
          </FormLabel>
        )}
        <Chakra.InputGroup size={size}>
          {leftAddon && (
            <Chakra.InputLeftAddon borderWidth={0}>{leftAddon}</Chakra.InputLeftAddon>
          )}
          {leftElement && (
            <Chakra.InputLeftElement width="fit-content" pl="0.8rem" fontSize="1rem">
              {leftElement}
            </Chakra.InputLeftElement>
          )}
          <Input
            ref={ref}
            name={name}
            isDisabled={isDisabled}
            errorBorderColor="red.400"
            backgroundColor="white"
            _focusVisible={{
              zIndex: 1,
              borderColor: 'gray.400',
              boxShadow: '0 0 0 1px var(--chakra-colors-gray-400)',
            }}
            {...props}
            {...(leftAddon && {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            })}
            {...(isInvalid && {
              _focusVisible: {
                zIndex: 1,
                borderWidth: 1,
                borderColor: 'red.300',
                boxShadow: '0 0 0 1px var(--chakra-colors-red-300)',
              },
            })}
          />
          {rightAddon && (
            <Chakra.InputRightAddon backgroundColor="gray.100" px="0" {...addonProps}>
              {rightAddon}
            </Chakra.InputRightAddon>
          )}
          {isLoading ? (
            <Chakra.InputRightElement
              width="fit-content"
              pr="0.8rem"
              {...rightElementProps}
            >
              <Spinner boxSize="14px" color="gray.500" />
            </Chakra.InputRightElement>
          ) : (
            rightElement && (
              <Chakra.InputRightElement
                width="fit-content"
                pr="0.8rem"
                {...rightElementProps}
              >
                {rightElement}
              </Chakra.InputRightElement>
            )
          )}
        </Chakra.InputGroup>
        {error && isInvalid && (
          <Chakra.Box fontSize="0.7rem" color="red.500" mt="4px">
            {error}
          </Chakra.Box>
        )}
        {description && (
          <Chakra.Box pt="2px" fontSize="12px" lineHeight="19px" color="gray.500">
            {description}
          </Chakra.Box>
        )}
      </Chakra.FormControl>
    )
  }
)

const hasValue = (value: any) => {
  return typeof value !== 'undefined'
}

export interface InputGroupFormProps extends Omit<InputGroupProps, 'form'>, FieldProps {}

export const InputGroupForm = React.forwardRef<HTMLInputElement, InputGroupFormProps>(
  ({ field, form, ...props }, ref) => {
    const error = _.get(form.errors, field.name)
    const isError = hasValue(_.get(form.errors, field.name))
    const isTouched = hasValue(_.get(form.touched, field.name))

    const isInvalid = isError && isTouched

    return (
      <InputGroup
        ref={ref}
        isInvalid={isInvalid}
        error={typeof error === 'string' ? error : 'неизвестный формат ошибки'}
        {...field}
        {...props}
      />
    )
  }
)

InputGroupForm.displayName = 'InputGroupForm'
