import { Intro } from './sections/Intro'
import { Services } from './sections/Services'
import { Flex } from '@chakra-ui/react'
import { WhatWeDo } from './sections/WhatWeDo'
import { DealTerms } from './sections/DealTerms'
import { HowDoesItWork } from './sections/HowDoesItWork'
import { Faq } from './sections/Faq'
import { Footer } from './sections/Footer'
import { useEffect } from 'react'
import { useCurrentUser } from '../../../../state/useCurrentUser'

export const Landing = () => {
  const {getCurrentUser} = useCurrentUser()

  useEffect(() => {
    getCurrentUser()
  }, [])

  return (
    <Flex flexDirection="column" backgroundColor="white">
      <Intro/>
      <Services/>
      <WhatWeDo/>
      <DealTerms/>
      <HowDoesItWork/>
      <Faq/>
      <Footer/>
    </Flex>
  )
}
