import { Outlet } from 'react-router-dom'
import { Center, Flex, Grid, Spinner } from '@chakra-ui/react'
import { Menu } from './Menu'
import { useCurrentUser } from 'state/useCurrentUser'
import { useEffect } from 'react'
import { Layout } from '../Layouts'

export const MainLayout = () => {
  const { getCurrentUser, isAuthenticated, isLoading, isCalled } = useCurrentUser()

  useEffect(() => {
    getCurrentUser(true)
  }, [])

  if (isAuthenticated) {
    return (
      <Layout>
        <Grid flex={1} templateColumns="260px 1fr">
          <Menu/>
          <Flex padding="8px">
            <Flex
              flex={1}
              borderRadius="8px"
              borderWidth={1}
              backgroundColor="white"
              overflowY="scroll"
              overflowX="hidden"
              height="calc(100vh - 16px)"
            >
              <Flex flexDirection="column" flex={1}>
                <Outlet/>
              </Flex>
            </Flex>
          </Flex>
        </Grid>
      </Layout>
    )
  }

  if (!isCalled || isLoading) {
    return (
      <Layout>
        <Center flex={1}>
          <Spinner boxSize="18px"/>
        </Center>
      </Layout>
    )
  }

  return (
    <Layout>
      <Center flex={1}>
        Нет доступа
      </Center>
    </Layout>
  )
}
