import React, { ForwardedRef } from 'react'
import InfiniteScroll, { Props as InfiniteProps } from 'react-infinite-scroll-component'

import { Box, Center, Grid, Skeleton, Spinner, Stack, Text } from '@chakra-ui/react'
import { SkeletonRow } from '../../shared/rows/SkeletonRow'

type InfiniteListComponent = <D extends ListItem>(
  props: InfiniteListProps<D>,
  ref: ForwardedRef<D>
) => JSX.Element | null

export const InfiniteList: InfiniteListComponent = ({
  data,
  children,
  noDataText,
  noDataRender,
  wrapper,
  isLoading,
  ...props
}) => {

  if (isLoading && !data?.length) {
    return (
      <Box key="loader">
        <SkeletonRow opacity={0.4}/>
        <SkeletonRow opacity={0.4}/>
        <SkeletonRow opacity={0.3}/>
        <SkeletonRow opacity={0.2}/>
        <SkeletonRow opacity={0.1}/>
      </Box>
    )
  }

  if (data && data.length) {
    const item = data.map((item, index) => children(item, index, data))

    return (
      <InfiniteScroll
        scrollableTarget="scrollableDiv"
        dataLength={data.length}
        loader={
          <Center key="loader" padding="30px">
            <Spinner size="md" color="black" />
          </Center>
        }
        style={{
          overflow: 'inherit',
        }}
        {...props}
      >
        {wrapper ? wrapper(item) : item}
      </InfiniteScroll>
    )
  }

  if (data && !data.length) {
    return (
      <Center minH="200px" mb="40px">
        {noDataRender || <Text color="gray.300">{noDataText || 'No data'}</Text>}
      </Center>
    )
  }

  return null
}

export type ListItem = {
  id: string
  [k: string]: any
}

export interface InfiniteListProps<D extends ListItem>
  extends Omit<InfiniteProps, 'children' | 'dataLength' | 'loader'> {
  data?: D[] | null
  children: (item: D, index: number, arr: D[]) => React.ReactNode
  noDataText?: string
  noDataRender?: React.ReactNode
  isLoading?: boolean
  wrapper?: (items: React.ReactNode) => React.ReactNode
}
