import {
  Switch as ChakraSwitch,
  FormControl,
  SwitchProps as ChakraSwitchProps,
  FormControlProps,
} from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { FormikValues } from 'formik'
import { FormLabel, FormLabelPropsProps } from '../FormLabel'

interface SwitchProps extends ChakraSwitchProps {
  name: string
  label: ReactNode
  labelProps?: Omit<FormLabelPropsProps, 'children'>
  controlProps?: FormControlProps
}

export const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(
  function Switch({ name, label, labelProps, controlProps, ...props }, ref) {
    return (
      <FormControl
        display="flex"
        alignItems="center"
        py="4px"
        {...controlProps}
      >
        <ChakraSwitch ref={ref} id={name} colorScheme="purple" {...props} />
        <FormLabel
          fontSize="1rem"
          {...labelProps}
          cursor="pointer"
          htmlFor={name}
          mb="0"
          lineHeight="1.2"
          pl="12px"
          userSelect="none"
          mr={0}
        >
          {label}
        </FormLabel>
      </FormControl>
    )
  }
)

interface SwitchFormProps extends FormikValues {
  label: ReactNode
}

export const SwitchForm = React.forwardRef<HTMLInputElement, SwitchFormProps>(
  ({ field, form, label,  ...props }, ref) => {
    const handleChange = (e: any) =>
      form.setFieldValue(field.name, e.target.checked)

    return (
      <Switch
        ref={ref}
        name={field.name}
        isChecked={field.value || false}
        onChange={handleChange}
        label={label}
        {...props}
      />
    )
  }
)
