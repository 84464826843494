import { Box, Button, HStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import {
  useUserAcceptRateMutation, useUserDeclineRateMutation,
} from 'api/graphql'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { TextArea } from 'uikit/TextArea'
import { isEmptyString } from 'utils/isEmptyString'
import { StepWrapper } from '../../../elements/StepWrapper'

export const RateCoordinationStep = () => {
  const { orderId, orderData } = useOrderData()
  const [userAcceptRateMutation, { loading: isAcceptLoading }] = useUserAcceptRateMutation()
  const [userDeclineRateMutation, { loading: isDeclineLoading }] = useUserDeclineRateMutation()
  const { refetchOrder } = useRefetchOrder()

  const [value, setValue] = useState('')
  const [isDecline, setIsDecline] = useState(false)

  const handleAccept = async () => {
    if (!orderId) return
    await userAcceptRateMutation({
      variables: {
        id: orderId,
      }
    })
    await refetchOrder()
  }

  const handleDecline = async () => {
    if (!value || !orderId) return
    await userDeclineRateMutation({
      variables: {
        id: orderId,
        comment: value
      }
    })
    await refetchOrder()
  }

  return (
    <StepWrapper
      description="Предлагается следующий курс"
    >
      <Box
        padding="8px 16px"
        backgroundColor="white"
        borderRadius="0.5rem"
        whiteSpace="pre-line"
        minH="100px"
      >
        {orderData?.comment}
      </Box>
      {!isDecline ? (
        <HStack spacing="16px">
          <Button isLoading={isAcceptLoading} onClick={handleAccept} variant="primary">
            Согласовать
          </Button>
          <Button isDisabled={isAcceptLoading} onClick={() => setIsDecline(true)} variant="secondary">
            Перенести сделку
          </Button>
        </HStack>
      ) : (
        <>
          <TextArea
            autoFocus
            name="comment"
            value={value}
            placeholder="Желаемое время сделки"
            minHeight="100px"
            onChange={(e) => setValue(e.target.value)}
          />
          <HStack spacing="16px">
            <Button isDisabled={isEmptyString(value)} isLoading={isDeclineLoading} onClick={handleDecline} variant="attention">
              Отправить
            </Button>
            <Button isDisabled={isDeclineLoading} onClick={() => setIsDecline(false)} variant="secondary">
              Отменить
            </Button>
          </HStack>
        </>
      )}
    </StepWrapper>
  )
}
