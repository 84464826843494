import React from 'react'
import { CompanyEntity } from 'shared/entities/CompanyEntity'
import { useNavigate } from 'react-router-dom'

export const Create = () => {
  const navigate = useNavigate()

  const handleChange = (id: string) => {
    navigate(`/companies`)
  }

  return (
    <CompanyEntity onChange={handleChange} onBack={() => navigate('/companies')}/>
  )
}
