import { Section } from '../../components/Section'
import { Container } from '../../components/Container'
import { Box, Center, Flex, FlexProps, Grid, Image, Stack, Text, TextProps } from '@chakra-ui/react'
import { HeadingPromo } from '../../components/HeadingPromo'
import React, { ReactNode } from 'react'
import Icon1 from './images/icon-1.png'
import Icon2 from './images/icon-2.png'
import Icon3 from './images/icon-3.png'
import Bg1 from './images/bg-1.svg'
import Bg2 from './images/bg-2.svg'

export const DealTerms = () => {
  return (
    <Section>
      <Container>
        <Stack spacing="40px">
          <Flex justifyContent="center" textAlign="center">
            <HeadingPromo>Условия сопровождения сделки</HeadingPromo>
          </Flex>
          <Grid
            templateColumns={['1fr', '1fr', '1fr 1fr', 'repeat(3, 1fr) 1.3fr']}
            templateRows={['auto', 'auto', 'repeat(2, auto)']}
            gap="24px"
          >
            <Item
              gridColumn={['auto', 'auto', 'span 2']}
              padding={['20px', '20px' ,'14px 32px']}
              background="linear-gradient(269deg, #D5D9FF 0.95%, #E6E5F9 42.88%, #F5F6F1 84.8%, #F2F3E6 126.72%)"
            >
              <Flex flex={1} alignItems="center">
                <Stack flex={1} spacing="2px">
                  <Label>
                    Срок перевода
                  </Label>
                  <Value fontSize={['1.3rem', '1.4rem', '1.6rem']}>
                    от 5 рабочих дней
                  </Value>
                </Stack>
                <Center flex="none">
                  <Image maxW={['100px', '120px', '140px']} src={Icon1} alt="Icon" />
                </Center>
              </Flex>
            </Item>
            <Item
              gridRow="span 2"
            >
              <Flex position="relative" zIndex={1} flex={1} alignItems="center" flexDirection="column">
                <Stack flex={1} alignItems="center" textAlign="center">
                  <Label>
                    Минимальная сумма перевода
                  </Label>
                  <Value>
                    10 000$
                  </Value>
                </Stack>
                <Center flex="none">
                  <Image maxW="140px" src={Icon2} alt="Icon" />
                </Center>
              </Flex>
              <Box
                position="absolute"
                left={0}
                right={0}
                top={0}
                bottom={0}
                width="100%"
                height="100%"
                backgroundImage={`url(${Bg1})`}
                backgroundPosition="bottom"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                opacity={[0, 0, 0.7]}
              />
            </Item>
            <Item gridRow="span 2">
              <Flex position="relative" zIndex={1} flex={1} alignItems="center" flexDirection="column">
                <Stack flex={1} alignItems="center" textAlign="center">
                  <Label>
                    Комиссия <br/> за оплату
                  </Label>
                  <Value>
                    от 1,5%
                  </Value>
                </Stack>
                <Center flex="none">
                  <Image maxW="140px" src={Icon3} alt="Icon" />
                </Center>
              </Flex>
              <Box
                position="absolute"
                left={0}
                right={0}
                top={0}
                bottom={0}
                width="100%"
                height="100%"
                backgroundImage={`url(${Bg2})`}
                backgroundPosition="bottom"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                opacity={0.7}
              />
            </Item>
            <Item>
              <Stack>
                <Label>
                  Подготовка документов
                </Label>
                <Value>
                  0$
                </Value>
              </Stack>
            </Item>
            <Item>
              <Stack>
                <Label>
                  Юридическое сопровождение
                </Label>
                <Value>
                  0$
                </Value>
              </Stack>
            </Item>
          </Grid>
        </Stack>
      </Container>
    </Section>
  )
}

interface ItemProps extends FlexProps {
  children: ReactNode
}

const Item: React.FC<ItemProps> = ({ children, ...props }) => {
  return (
    <Flex
      padding={['20px', '20px', '32px']}
      borderRadius="20px"
      position="relative"
      overflow="hidden"
      background="linear-gradient(180deg, #DDDFF6 0%, #F5F5FF 100%), linear-gradient(180deg, #E9E9E9 0%, #F4F4F4 100%)"
      {...props}
    >
      {children}
    </Flex>
  )
}

interface LabelProps extends TextProps {
  children: ReactNode
}

const Label: React.FC<LabelProps> = ({ children, ...props }) => {
  return (
    <Text fontSize={['1rem', '1.1rem', '1.2rem']} lineHeight="1.3" fontWeight={300} {...props}>
      {children}
    </Text>
  )
}

const Value: React.FC<LabelProps> = ({ children, ...props }) => {
  return (
    <Text fontSize={['1.3rem', '1.5rem', '1.8rem']} fontWeight={700} {...props}>
      {children}
    </Text>
  )
}
