import React from 'react'
import { DocumentModal } from 'components/DocumentModal'

interface DocPersonalDataModalProps {
  isOpen: boolean
  onClose: () => void
}

export const DocPersonalDataModal: React.FC<DocPersonalDataModalProps> = ({ isOpen, onClose }) => {
  return (
    <DocumentModal isOpen={isOpen} onClose={onClose}>
      <p><strong>СОГЛАСИЕ СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ</strong></p><p><strong>НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
    </p><p>Я, в соответствии с Конституцией Российской Федерации, Федеральным законом от 27.07.2006 № 152-ФЗ «О
      персональных данных», в целях использования Сайта: <a
        href="https://alfred.market/">https://alfred.market/</a> (далее – «<strong>Сайт</strong>»), а также в целях:</p>
      <ul>
        <li>
          <ul>
            <li>получения мною Услуг посредством Сайта;</li>
            <li>получения консультационных, информационно-технологических и посреднических услуг на условиях Оферты,
              расположенной по ссылке: <a href="https://alfred.market/">https://alfred.market/</a>, включая заключение
              договоров с агентами в целях оказания Услуг;
            </li>
            <li>получения информации об Услугах на Сайте;</li>
            <li>получения предложений по условиям оказания Услуг, оказания информационно-технологических услуг;</li>
            <li>получения статистических данных;</li>
            <li>получения сведений уведомительного или маркетингового характера, в том числе, о новых Услугах,
              проводимых акциях, мероприятиях;
            </li>
            <li>обработки запросов и (или) жалоб;</li>
            <li>регистрации и использования Личного кабинета для совершения заказов на Сайте, а также для получения
              информационных и рекламных рассылок;
            </li>
            <li>использования Личного кабинета на Сайте, путем заполнения информации о себе;</li>
            <li>оформления Заказов (в том числе оплаты и доставки заказов), отслеживания статуса Заказа, написания
              отзывов об Услугах, составления обращений по вопросам работы Сайта через специальную форму в Личном
              кабинете;
            </li>
            <li>в целях исполнения требований законодательства Российской Федерации.</li>
          </ul>
        </li>
      </ul>
      <p>даю свое согласие Обществу с ограниченной ответственностью «Альфред» (ОГРН 1247700663760) (далее также –
        «<strong>Правообладатель</strong>», «<strong>Оператор</strong>») на передачу и обработку следующих моих
        персональных данных (если такие данные были мной фактически переданы) через уполномоченного представителя
        Оператора:</p>
      <ul>
        <li>
          <ul>
            <li>
              <ul>
                <li>Фамилия, имя, отчество Пользователя (для целей получения Услуг, заключения Сделок в соответствии с
                  условиями Оферты);
                </li>
                <li>Гражданство;</li>
                <li>Данные документа, удостоверяющего личность (в том числе, паспортные данные, если применимо);</li>
                <li>Дата рождения, место рождения (если применимо, с целью определения возраста Пользователя);</li>
                <li>Адрес электронной почты (для привязки Личного кабинета Пользователя и входа в Личный кабинет);</li>
                <li>Адрес Пользователя (для целей доставки документов);</li>
                <li>Мобильный телефон Пользователя (для целей подписания документов простой электронной подписью, связи
                  с Пользователем по вопросам оказания Услуг, восстановления доступа Пользователя в Личный кабинет);
                </li>
                <li>Запись электронной переписки на Сайте, включая Личный кабинет Пользователя (если применимо, для
                  целей подтверждения договоренностей и иной информации, касательно Услуг);
                </li>
                <li>Реквизиты электронных средств платежа (номер и реквизиты счета или номер карты, срок действия, код
                  CVV/CVC) (для целей оплаты Услуг) (если применимо);
                </li>
                <li>Данные свидетельства о постановке на учет в налоговом органе физического лица по месту жительства на
                  территории Российской Федерации (ИНН) (если применимо);
                </li>
                <li>Иная разумно необходимая информация, необходимая для целей оформления и оказания Услуг.</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p>Настоящее согласие предоставляется на осуществление любых действий в отношении моих персональных данных,
        которые необходимы или желаемы для достижения указанных выше целей, включая, но не ограничиваясь, сбор,
        систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, распространение (в том
        числе передачу третьим лицам при необходимости), обезличивание, блокирование, уничтожение, а также осуществление
        любых иных действий с моими персональными данными, предусмотренных действующим законодательством Российской
        Федерации.</p><p>Обработка персональных данных Правообладателем может осуществляться как с использованием
      средств автоматизации, так и без их использования (на бумажных носителях).</p><p>Обработка персональных данных
      прекращается по достижении целей обработки.</p><p>Подтверждаю, что ознакомлен(а) и согласен(на) с условиями
      Политикой конфиденциальности и обработки персональных данных, размещенной по адресу <a id="Text3"></a> .</p>
      <p>Подтверждаю, что с порядком отзыва согласия на обработку персональных данных в соответствии с п. 5 ст. 21
        Федерального закона от 27.07.2006 «О персональных данных» № 152-ФЗ ознакомлен(а).</p><p>Я подтверждаю, что,
      давая настоящее Согласие, я действую своей волей и в своих интересах.</p>
    </DocumentModal>
  )
}
