import React, { ReactNode } from 'react'
import { Box, HStack, Stack } from '@chakra-ui/react'

interface RowInfoHeaderProps {
  header?: string
  rightElement?: ReactNode
  children: ReactNode
}

export const RowInfoGroup: React.FC<RowInfoHeaderProps> = ({ header, rightElement, children }) => {
  return (
    <Stack spacing="12px">
      <HStack spacing="20px" alignItems="center" justifyContent="space-between">
        {header && (
          <Box fontWeight={700}>{header}</Box>
        )}
        {rightElement && (
          <>{rightElement}</>
        )}
      </HStack>
      <Stack spacing={0} backgroundColor="gray.100" borderRadius="14px" padding="8px 16px">
        {children}
      </Stack>
    </Stack>
  )
}
