import { AnimatePresence, motion } from 'framer-motion'
import { Flex } from '@chakra-ui/react'
import React, { ReactNode } from 'react'

interface IMotionBox {
  inVisible: boolean
  children: ReactNode
}

export const MotionBox: React.FC<IMotionBox> = ({
  inVisible,
  children,
  ...props
}) => {
  return (
    <AnimatePresence>
      {inVisible && (
        <motion.div
          initial={{
            opacity: 0,
            y: -20,
          }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -2, opacity: 0 }}
          transition={{ ease: 'easeOut', duration: 0.2 }}
          style={{ width: '100%' }}
        >
          <Flex flexDirection="column" {...props}>
            {children}
          </Flex>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
