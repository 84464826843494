import { CenterProps, Flex, IconProps, Stack, StackProps } from '@chakra-ui/react'
import React, { forwardRef, ReactNode, useMemo } from 'react'
import { IconType } from 'react-icons'
import { ItemLabel } from '../elements/ItemLabel'
import { Item } from '../elements/Item'
import { ItemIcon } from '../elements/ItemIcon'
import { truncate } from 'lodash'
import { Link } from 'react-router-dom'

interface ItemProps extends StackProps {
  href?: string
  icon?: IconType
  children: ReactNode
  isExternal?: boolean
  isExact?: boolean
  isDot?: boolean
  iconProps?: IconProps
  containerIconProps?: CenterProps
  containerProps?: StackProps
  subTitle?: string | null | undefined
}

export const MenuItem = forwardRef<HTMLDivElement, ItemProps>(({
  href,
  icon,
  isExternal,
  isDot,
  iconProps,
  isExact,
  containerProps,
  containerIconProps,
  children,
  subTitle,
  ...props
}, ref) => {
  const isActive = useMemo(() => {
    const pathname = window.location.pathname
    if (isExact) {
      return pathname === href
    }
    const _pathname = pathname.substring(0, href?.length)
    return _pathname === href
  }, [href, isExact, window?.location.pathname])

  return (
    <Item
      ref={ref}
      containerProps={containerProps}
      {...(icon && {
        leftAddon: <ItemIcon icon={icon} iconProps={iconProps} isDot={isDot} {...containerIconProps} />,
      })}
      {...props}
      {...(href && {
        as: Link,
        to: href,
        ...(isExternal && {
          target: '_blank',
        }),
      })}
      {...(isActive && {
        backgroundColor: 'gray.200',
      })}
    >
      <Stack spacing={0}>
        <ItemLabel>{children}</ItemLabel>
        {subTitle && (
          <Flex
            fontSize="0.75rem"
            opacity={0.5}
          >{truncate(subTitle, { length: 26 })}</Flex>
        )}
      </Stack>
    </Item>
  )
})
