import React, { ReactNode } from 'react'
import { Flex } from '@chakra-ui/react'

interface LayoutProps {
  children: ReactNode
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Flex
      flexDirection="column"
      flex={1}
      height="100vh"
      overflow="hidden"
      //minW="1000px"
    >
      {children}
    </Flex>
  )
}
