import { Route, Routes } from 'react-router-dom'
import { List } from './routes/List'
import { Create } from './routes/Create'
import { Item } from './routes/Item'
import { MainLayout } from 'shared/layouts/MainLayout'

export const Companies = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<List />} />
        <Route path=":id" element={<Item />} />
        <Route path="create" element={<Create />} />
      </Route>
    </Routes>
  )
}
